import React from "react";
import Grid from "@mui/material/Grid";
import "./label-inputs.scss";

const PROVIDERS = ["napster", "revelator", "soundcloud", "amazon"];

const LabelInputs = ({
  provider,
  dsp,
  entityId,
  entityType,
  tableDate,
  handleProviderChange,
  handleDspChange,
  handleEntityChange,
  handleTableDateChange,
}) => {
  return (
    <Grid className={"label-inputs-wrapper"} container>
      <Grid item xs={12}>
        <label className={"label-input-label"} htmlFor={"provider"}>
          {"Provider: "}
        </label>
        <select
          name={"provider"}
          id={"provider"}
          onChange={(e) => handleProviderChange(e.target.value)}
          value={provider}
        >
          {PROVIDERS.map((providerOption) => (
            <option value={providerOption} key={providerOption}>
              {providerOption}
            </option>
          ))}
        </select>
      </Grid>
      <Grid item xs={12}>
        <label className={"label-input-label"} htmlFor={"entityId"}>{`${
          entityType.charAt(0).toUpperCase() + entityType.slice(1)
        } ID: `}</label>
        <input
          id={"entityId"}
          type={"text"}
          onChange={(e) => handleEntityChange(e.target.value)}
          value={entityId}
        />
      </Grid>
      <Grid item xs={12}>
        <label className={"label-input-label"} htmlFor={"dsp"}>{`DSP: `}</label>
        <input
          id={"dsp"}
          type={"text"}
          onChange={(e) => handleDspChange(e.target.value)}
          value={dsp}
        />
      </Grid>
      <Grid item xs={12}>
        <label className={"label-input-label"} htmlFor={"tableDate"}>
          {"Table Date: "}
        </label>
        <input
          id={"tableDate"}
          type={"text"}
          onChange={(e) => handleTableDateChange(e.target.value)}
          value={tableDate}
        />
      </Grid>
    </Grid>
  );
};

export default LabelInputs;
