import axios from "../config/axios";

class Auth {
  /**
   * Returns whether or not the current user is authenticated.
   */
  static checkUserAuthenticated = async () => {
    try {
      const response = await axios.get("/authenticate", {
        headers: { Authorization: `Bearer ${localStorage.getItem("tk")}` },
      });
      return response.status === 200;
    } catch (error) {
      return false;
    }
  };
}

export default Auth;
