export const USER_COMP_TABLE_METRICS = [
  {
    title: "Total Plays",
    label: "total_plays",
  },
  {
    title: "Monetized Plays",
    label: "monetized_plays",
  },
  {
    title: "Number of Unique Songs",
    label: "unique_song_count",
  },
  {
    title: "Median Stream Duration (s)",
    label: "stream_duration_median",
  },
  {
    title: "Number of Events / Number of Plays",
    label: "events_per_play_rate",
  },
  {
    title: "Distributor Risk Score",
    label: "distributor_risk",
  },
  {
    title: "Similar Distributor Score",
    label: "similar_distributor_score",
  },
  {
    title: "Similar Artist Score",
    label: "similar_artist_score",
  },
];

export const USER_RADAR_COMP_METRICS = [
  { title: "total_plays_score", label: "Number of plays" },
  { title: "unique_song_count_score", label: "Number of unique songs" },
  {
    title: "events_per_play_rate_score",
    label: "Number of events/\nNumber of plays",
  },
  { title: "distributor_risk_score", label: "Distributor Risk Score" },
  {
    title: "stream_duration_median_score",
    label: "Median stream duration (s)",
  },
  { title: "similar_artist_score", label: "Similar artist score" },
  { title: "similar_distributor_score", label: "Similar distributor score" },
];
