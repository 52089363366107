import React from "react";
import InfoTip from "../InfoTip/InfoTip";
import "./selection-radio-buttons.scss";

const SelectionRadioButtons = ({
  options = [], // Array of strings for options to select from
  selectedOption, // String of the selected option
  setSelectedOption = () => {}, // Callback function to update the selected option
  tooltipWidth, // Pixel width of the tooltip, if any are rendered
}) => {
  const generateRadioButtons = (optionList) => {
    return optionList.map(({ labelText, tooltip }, index) => {
      return (
        <div
          className={"selection-radio-button-wrapper"}
          key={`${labelText}-${index}`}
        >
          <div
            className={`selection-radio-button${
              selectedOption === index ? " selected" : ""
            }`}
            onClick={() => setSelectedOption(index)}
          />
          <label>{labelText}</label>
          {!!tooltip && (
            <InfoTip
              description={tooltip}
              arrow={false}
              tooltipWidth={tooltipWidth}
            />
          )}
        </div>
      );
    });
  };

  return (
    <div className={"selection-radio-buttons-container"}>
      {generateRadioButtons(options)}
    </div>
  );
};

export default SelectionRadioButtons;
