import React from "react";
import DrawSvgs from "../../services/draw_svgs";
import "./increment-controller.scss";

const DECREMENT_SVG_PATH = "M 12.452 6.396 L 1.766 6.396";
const INCREMENT_SVG_PATH =
  "M 7.053 11.209 L 7.099 6.396 M 7.099 6.389 L 7.145 1.559 M 7.099 6.396 L 12.469 6.396 M 7.099 6.396 L 1.766 6.396";
const RESET_SVG_PATH =
  "M 16.728 11.556 C 16.491 11.483 16.233 11.505 16.013 11.621 C 15.793 11.735 15.627 11.933 15.55 12.169 C 15.106 13.531 14.237 14.714 13.073 15.548 C 11.908 16.382 10.508 16.823 9.075 16.807 C 7.303 16.825 5.598 16.142 4.329 14.905 C 3.061 13.669 2.333 11.979 2.309 10.208 C 2.333 8.437 3.061 6.748 4.329 5.512 C 5.598 4.275 7.303 3.591 9.075 3.611 C 10.677 3.608 12.226 4.164 13.459 5.186 L 11.413 4.845 C 11.291 4.826 11.165 4.829 11.045 4.859 C 10.924 4.888 10.81 4.939 10.71 5.012 C 10.608 5.084 10.522 5.176 10.458 5.28 C 10.392 5.389 10.349 5.505 10.33 5.628 C 10.309 5.75 10.313 5.876 10.341 5.998 C 10.37 6.118 10.423 6.232 10.496 6.332 C 10.568 6.433 10.66 6.52 10.765 6.583 C 10.871 6.649 10.989 6.692 11.113 6.712 L 15.107 7.372 L 15.268 7.372 C 15.376 7.372 15.486 7.352 15.589 7.314 C 15.623 7.302 15.654 7.284 15.683 7.259 C 15.75 7.234 15.814 7.198 15.872 7.154 L 15.957 7.05 C 15.957 7.005 16.042 6.966 16.079 6.91 C 16.116 6.853 16.079 6.816 16.126 6.778 C 16.152 6.722 16.174 6.666 16.191 6.608 L 16.899 2.838 C 16.922 2.714 16.92 2.587 16.895 2.464 C 16.869 2.341 16.82 2.224 16.749 2.119 C 16.678 2.015 16.587 1.926 16.482 1.857 C 16.377 1.788 16.259 1.739 16.135 1.716 C 16.012 1.693 15.884 1.695 15.761 1.72 C 15.637 1.746 15.521 1.796 15.416 1.864 C 15.206 2.01 15.061 2.231 15.013 2.48 L 14.76 3.847 C 13.181 2.48 11.164 1.728 9.075 1.727 C 6.804 1.705 4.618 2.588 2.996 4.178 C 1.375 5.768 0.448 7.937 0.424 10.208 C 0.448 12.48 1.375 14.648 2.996 16.239 C 4.618 17.829 6.804 18.712 9.075 18.69 C 10.918 18.718 12.717 18.155 14.214 17.084 C 15.71 16.01 16.824 14.486 17.388 12.735 C 17.424 12.614 17.436 12.486 17.421 12.361 C 17.407 12.237 17.369 12.116 17.307 12.007 C 17.245 11.896 17.163 11.8 17.064 11.722 C 16.964 11.646 16.851 11.588 16.728 11.556 Z";

const INCREMENT_DECREMENT_SVG_WIDTH_PX = 14;
const RESET_SVG_WIDTH_PX = 19;

const IncrementController = (props) => {
  const drawIcon = (path, width) => {
    return DrawSvgs.drawCustomSvgPath(path, width, width);
  };

  const currentValueEqualsParam = (matchValue) => {
    // Check whether or not icons should be disabled by comparing the current value
    // To the default/min/max value
    return props.value === matchValue ? true : false;
  };

  const updateValue = (checkValue, adjustment) => {
    // Increment/Decrement state value if doing so won't violate min/max value
    if (!currentValueEqualsParam(checkValue))
      props.setValue(props.value + adjustment);
  };

  const resetValue = (defaultValue) => {
    // Reset state value if it isn't already the default value
    if (!currentValueEqualsParam(defaultValue)) props.setValue(defaultValue);
  };

  return (
    <div className={"increment-controller"}>
      <label className={"increment-label"}>{props.label}</label>
      <div className={"icons"}>
        <div className={"decrement-and-increment-container"}>
          <div
            className={`icon-container decrement-container${
              currentValueEqualsParam(props.minValue) ? " disabled" : ""
            }`}
            onClick={() => updateValue(props.minValue, -props.incrementAmount)}
          >
            {drawIcon(DECREMENT_SVG_PATH, INCREMENT_DECREMENT_SVG_WIDTH_PX)}
          </div>
          <div
            className={`icon-container increment-container${
              currentValueEqualsParam(props.maxValue) ? " disabled" : ""
            }`}
            onClick={() => updateValue(props.maxValue, props.incrementAmount)}
          >
            {drawIcon(INCREMENT_SVG_PATH, INCREMENT_DECREMENT_SVG_WIDTH_PX)}
          </div>
        </div>
        <div
          className={`icon-container reset-container${
            currentValueEqualsParam(props.defaultValue) ? " disabled" : ""
          }`}
          onClick={() => {
            resetValue(props.defaultValue);
          }}
        >
          {drawIcon(RESET_SVG_PATH, RESET_SVG_WIDTH_PX)}
        </div>
      </div>
    </div>
  );
};

export default IncrementController;
