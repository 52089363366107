import { useEffect, useRef } from "react";

/**
 * Helper used to track previous state in components
 * leveraging React Hooks.
 */
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      ref.current = value;
    }

    return () => {
      abort = true;
    };
  });
  return ref.current;
};

export default usePrevious;
