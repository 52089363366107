import styles from "../styles/constants.scss";

export const SUBMIT_CTA_TEXT = "Submit";
export const NO_REASONS_ERROR_TEXT =
  "Please select at least one reason for suspected fraudulent cases.";
export const NO_FRAUD_TYPE_REASONS_ERROR_TEXT =
  "Please select at least one reason for suspected fraud type.";
export const NO_GENRES_ERROR_TEXT = "Please select at least one music genre.";
export const NO_SONG_TYPE_ERROR_TEXT = "Please classify the type of song.";
export const REQUIRED_TEXTBOX_ERROR = "Please fill in all required text boxes.";
export const FRAUD_TYPE_ERROR = "Please select the suspected fraud type.";
export const SUBMISSION_FAILURE_TEXT = "Submission failed, please try again.";
export const SUBMISSION_SUCCESS_TEXT = `Submission was successful! Loading in new data...`;

export const PILL_LIST_HEADER =
  "Please select the reason(s) that indicate behaviour associated with this fraud type:";
export const PILL_LIST_SUBHEADER = "Your labelled reason(s) for fraud:";
export const PILL_LIST_ERROR_MESSAGE =
  "Please label your reason(s) for fraud above, before making this decision.";

export const REQUIRED_TEXTBOX_PLACEHOLDER = {
  placeholder: "Please Explain",
  required: true,
};

export const OPTIONAL_TEXTBOX_PLACEHOLDER = {
  placeholder: "Additional Information",
  required: false,
};

export const SELECTION_GENRE_OPTIONS = {
  textboxWidth: 294,
  entityTypes: ["artist", "song"],
  options: [
    {
      text: "Lyrical music",
      tooltip: "Anything that involves words in a song.",
    },
    {
      text: "Ambient sounds",
      tooltip:
        "Also considered background noise. Includes sounds like rain, thunderstorms, city, and coffee shops.",
    },
    {
      text: "Audiobooks",
      tooltip:
        "Recorded reading of a book. Tracks often begin with chapter numbers.",
    },
    {
      text: "White noise",
      tooltip:
        "Sounds with a steady, uniform sound throughout. Includes sounds like a whirring fan, hissing radiator, or humming air conditioner.",
    },
    {
      text: "Sleep/meditation music",
      tooltip: "Calming sounds or music without any lyrics or speech.",
    },
    {
      text: "Instrumental music",
      tooltip:
        "Music with just instruments and no vocals. Some genres that are usually instrumental include lofi or classical music.",
    },
  ],
};

export const SELECTION_SONG_CLASSIFICATION_PROPS = {
  tooltipWidth: 275,
  options: [
    { labelText: "Original" },
    {
      labelText: "Cover",
      tooltip:
        "This song may be a cover if:\n • There are similar/identical lyrics by another artist\n • The song credits reference another artist",
    },
    {
      labelText: "Stolen Content",
      tooltip:
        'This song may contain stolen content if:\n • Claiming to be a "cover", but not listing the artist in the song credits section of the DSP\n • The song is identical to another artist’s song',
    },
  ],
};

export const SELECTION_SLIDER_PROPS = {
  understandingLevel: {
    options: ["Low", "Medium", "High"],
    defaultOption: "Medium",
    renderTextbox: true,
    textboxFields: {
      low: REQUIRED_TEXTBOX_PLACEHOLDER,
      medium: OPTIONAL_TEXTBOX_PLACEHOLDER,
    },
  },
  fraudPercentage: {
    options: ["0%", "0-20%", "20-40%", "40-60%", "60-80%", "80-100%", "100%"],
    defaultOption: "0%",
    colors: [
      styles.decisionBoxGreen,
      styles.decisionBoxLightGreen,
      styles.decisionBoxYellow,
      styles.decisionBoxDarkYellow,
      styles.decisionBoxOrange,
      styles.decisionBoxRed,
      styles.decisionBoxDarkRed,
    ],
  },
};
