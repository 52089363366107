import React, { useEffect, useState, useRef } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Format from "../../services/format";
import "./date-range-controller.scss";

const UPDATE_START_DATE_DELAY_MS = 1000;

/**
 * Searches the dataWeeks array for the object with the matching startDate value
 * to the startDate param given.
 * @param {String} startDate The start date of a data week
 * @param {Array<{startDate: String, endDate: String}>} dataWeeks Array of objects with data week start and end dates
 * @returns {Number | undefined} The index of the data week that matches the start date, or undefined if not found
 */
const getDataWeekIndex = (startDate, dataWeeks) => {
  try {
    return dataWeeks.findIndex((week) => week.startDate === startDate);
  } catch (e) {
    return undefined;
  }
};

/**
 * Generates a readable "startDate - endDate" string in the style of "MMM DD, YYYY - MMM DD, YYYY".
 * If there is a valid, numerical newStartEndIndex passed in, the string will be generated
 * from the startDate and endDate values of the object at that index in the dataWeeks array.
 * Otherwise defaults to the "currentStart - currentEnd" param values.
 * @param {Number | undefined} newStartEndIndex The start date of a data week
 * @param {String} currentStart The start date of the current data week
 * @param {String} currentEnd The end date of the current data week
 * @param {Array<{startDate: String, endDate: String}>} dataWeeks Array of objects with data week start and end dates
 * @returns {String} Formatted date range string
 */
const generateReadableDateRange = (
  newStartEndIndex,
  currentStart,
  currentEnd,
  dataWeeks
) => {
  let startDate = currentStart;
  let endDate = currentEnd;

  // If there is a new start date, display that
  if (newStartEndIndex >= 0) {
    startDate = dataWeeks?.[newStartEndIndex]?.startDate;
    endDate = dataWeeks?.[newStartEndIndex]?.endDate;
  }

  return `${Format.formatReadableDate(startDate)} - ${Format.formatReadableDate(
    endDate
  )}`;
};

const DateRangeController = (props) => {
  const [newDataWeekIndex, setNewDataWeekIndex] = useState();
  let mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      // On dismounting, set mounted to false
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      const startingIndex = getDataWeekIndex(props.startDate, props.dataWeeks);
      if (startingIndex >= 0 && mounted.current)
        setNewDataWeekIndex(startingIndex);
    }

    return () => {
      abort = true;
    };
  }, [props.dataWeeks, props.startDate]);

  useEffect(() => {
    // Will only run if the clearTimeout isn't run before then timeout fires
    // AKA as long as newDataWeekIndex doesn't change again
    const timer = setTimeout(() => {
      if (newDataWeekIndex >= 0 && newDataWeekIndex < props.dataWeeks.length) {
        const oldDataWeekIndex = getDataWeekIndex(
          props.startDate,
          props.dataWeeks
        );

        if (oldDataWeekIndex !== newDataWeekIndex) {
          // Actually update the state startDate value
          props.handleDateRangeChange(
            props.dataWeeks?.[newDataWeekIndex]?.startDate,
            props.dataWeeks?.[newDataWeekIndex]?.endDate
          );
        }
      }
    }, UPDATE_START_DATE_DELAY_MS);

    return () => {
      clearTimeout(timer);
    };
  }, [newDataWeekIndex, props]);

  /**
   * Updates the newDataWeekIndex in state as to change the displayed data
   * to the previous data week without updating the startDate prop value itself.
   */
  const setPreviousDataWeek = () => {
    if (newDataWeekIndex >= 0 && newDataWeekIndex >= 1 && mounted.current) {
      setNewDataWeekIndex(newDataWeekIndex - 1);
    }
  };

  /**
   * Updates the newDataWeekIndex in state as to change the displayed data week
   * to the next data week without updating the startDate prop value itself.
   */
  const setNextDataWeek = () => {
    if (
      newDataWeekIndex >= 0 &&
      newDataWeekIndex < props.dataWeeks.length - 1 &&
      mounted.current
    ) {
      setNewDataWeekIndex(newDataWeekIndex + 1);
    }
  };

  /**
   * Checks if the currently displayed data week is NOT the first data week in the array.
   * @returns {Boolean}
   */
  const checkPastDateRanges = () => {
    if (newDataWeekIndex > 0) {
      return true;
    } else {
      return (
        newDataWeekIndex !== 0 &&
        getDataWeekIndex(props.startDate, props.dataWeeks) > 0
      );
    }
  };

  /**
   * Checks if the currently displayed data week is NOT the last data week in the array.
   * @returns {Boolean}
   */
  const checkFutureDateRanges = () => {
    if (newDataWeekIndex < props.dataWeeks.length - 1) {
      return true;
    } else {
      return (
        newDataWeekIndex !== 0 &&
        getDataWeekIndex(props.startDate, props.dataWeeks) <
          props.dataWeeks.length - 1
      );
    }
  };

  return (
    <div className={"date-range-control-container"}>
      <ArrowBackIosNewIcon
        className={"date-range-control-icon"}
        style={{
          visibility: checkPastDateRanges() ? "visible" : "hidden",
        }}
        onClick={() => setPreviousDataWeek()}
      />
      <div className={"date-range-control-text-container"}>
        <span
          className={
            props.useStyle === "default"
              ? "default-date-range-control-text"
              : "secondary-date-range-control-text"
          }
        >
          {generateReadableDateRange(
            newDataWeekIndex,
            props.startDate,
            props.endDate,
            props.dataWeeks
          )}
        </span>
      </div>
      <ArrowForwardIosIcon
        className={"date-range-control-icon"}
        style={{
          visibility: checkFutureDateRanges() ? "visible" : "hidden",
        }}
        onClick={() => setNextDataWeek()}
      />
    </div>
  );
};

export default DateRangeController;
