import DrawSvgs from "../../services/draw_svgs";
import "./chart-tooltip.scss";

const ChartTooltip = (props) => {
  const {
    payload = [],
    label,
    squareIconSize = false,
    squareIconOpacity = "100%",
    chartType = false,
    labelSuffix = "",
    columnsCount = 1,
    rowLabelFormatter = (item) => item,
  } = props;

  const stackedAreaChartExtras = () => {
    const total = payload.reduce((result, entry) => result + entry.value, 0);
    // Stops NaN error when there's no plays
    const average = !total ? total : Math.round(total / payload.length);
    return (
      <>
        <li className={"tooltip-row large"}>
          <span>{props.totalLabel}</span>
          <span className={"tooltip-total-count"}>{total}</span>
        </li>
        <li className={"tooltip-row large"}>
          <span>{props.averageLabel}</span>
          <span>{average}</span>
        </li>
      </>
    );
  };

  const generateList = (payload) => {
    const uncutData = [...payload];
    const entriesPerColumn = Math.floor(uncutData.length / columnsCount);
    const lists = [];
    const data = [];

    // Extra columns
    if (columnsCount > 1) {
      for (let i = 1; i < columnsCount; i++) {
        const columnData = uncutData.splice(0, entriesPerColumn);
        data.push(columnData);
      }
    }

    // Final/Only column
    data.push(uncutData);

    data.forEach((column, index) => {
      // Add divider line as necessary
      if (lists.length)
        lists.push(
          <div className={"vertical-line"} key={`vertical-line-${index}`} />
        );

      lists.push(
        <ul className={"tooltip-list"} key={`tooltip-list-${index}`}>
          {chartType === "StackedArea" && !index && stackedAreaChartExtras()}
          {column.map((entry) => (
            <li
              key={`item-${entry.name}`}
              className={`tooltip-row ${entry?.className}`}
            >
              <div className={"row-name"}>
                {squareIconSize &&
                  DrawSvgs.drawSquareSvg(
                    squareIconSize,
                    entry.color,
                    squareIconOpacity
                  )}
                {rowLabelFormatter(entry.name)}
              </div>
              <span className={"row-value"}>{entry.value}</span>
            </li>
          ))}
        </ul>
      );
    });
    return lists;
  };

  return (
    <div className={"tooltip-content"}>
      <span
        className={"tooltip-row large tooltip-title"}
      >{`${label}${labelSuffix}`}</span>
      <div className={"tooltip-lists"}>{generateList(payload)}</div>
    </div>
  );
};

export default ChartTooltip;
