import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Auth from "../../services/auth";
import UseProfile from "../UseProfile/UseProfile";
import Logout from "../../components/Logout/Logout";
import LabelInputs from "../../components/LabelInputs/LabelInputs";
import UserDecisionBox from "../../components/UserDecisionBox/UserDecisionBox";
import DashboardFooter from "../../components/DashboardFooter/DashboardFooter";
import "./user.scss";

const DEFAULT_PROVIDER = "napster";
const NON_DSP_PROVIDER = "revelator";

const User = (props) => {
  const history = useHistory();
  const [provider, setProvider] = useState(DEFAULT_PROVIDER);
  const [dsp, setDsp] = useState(DEFAULT_PROVIDER);
  const [userId, setUserId] = useState("");
  const [tableDate, setTableDate] = useState("");

  // default dsp providers to same provider/dsp
  useEffect(() => {
    if (provider !== NON_DSP_PROVIDER) setDsp(provider);
    else setDsp("");
  }, [provider]);

  const {
    editLinkedUsers,
    editSelectedFraudReasons,
    selectedFraudReasons,
    setSelectedFraudReasons,
  } = UseProfile({
    ogEntityId: userId,
    entityType: "user",
    tableDateFromUrl: tableDate,
  });

  const handleLabelSubmit = useCallback(async () => {
    const checkAuthed = async () => {
      // Check authentication is still valid
      const authenticated = await Auth.checkUserAuthenticated();
      if (!authenticated) {
        history.push("/login");
      }
    };

    await checkAuthed();

    setProvider(DEFAULT_PROVIDER);
    setSelectedFraudReasons([]);
    setUserId("");
    setTableDate("");
  }, [history, setSelectedFraudReasons]);

  return (
    <Grid
      className={"label-user-container"}
      container
      justifyContent={"center"}
    >
      <Grid container item xs={10} spacing={2}>
        <Grid item xs={12}>
          <div className={"label-user-logout"}>
            <Logout />
          </div>
        </Grid>
        <Grid item xs={12}>
          <LabelInputs
            provider={provider}
            dsp={dsp}
            entityId={userId}
            entityType={"user"}
            tableDate={tableDate}
            handleProviderChange={setProvider}
            handleDspChange={setDsp}
            handleEntityChange={setUserId}
            handleTableDateChange={setTableDate}
          />
        </Grid>
        <Grid item xs={12}>
          <UserDecisionBox
            provider={provider}
            dsp={dsp}
            entityId={userId}
            partnerName={provider}
            tableDate={tableDate}
            linkedUsers={[]}
            editLinkedUsers={editLinkedUsers}
            handleFormSubmission={handleLabelSubmit}
            selectedFraudReasons={selectedFraudReasons}
            editSelectedFraudReasons={editSelectedFraudReasons}
          />
        </Grid>
        <Grid className={"label-user-footer"} item xs={12}>
          <DashboardFooter />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default User;
