import { useState, useEffect, useRef, useCallback } from "react";
import usePrevious from "../UsePrevious/UsePrevious";

// Heavily adapted from https://github.com/TheMartinCrabtree/react-canvas-demo/blob/master/src/hooks/useCanvas.js
export function UseTimelineMarks(
  width,
  height,
  markerWidth,
  markerHeight,
  hover = false
) {
  const canvasRef = useRef(null);
  const [markers, setMarkers] = useState([]);
  const [x, setX] = useState(null);
  const prevX = usePrevious(x);

  const hoverAnimation = useCallback(
    (ctx, info) => {
      let easeValue = 0.1; // fraction of animation completed: 0 is unchanged, 1 is fully changed.
      let currentHeight; // actual position of element for height
      const scaleHeight = () => {
        ctx.beginPath();

        const startHeight = markerHeight;
        const endHeight = info.hoverHeight;

        const fractionOfFinalHeight =
          easeOutQuad(easeValue) * (endHeight - startHeight);

        currentHeight = fractionOfFinalHeight + startHeight;

        // clear previous hover rects to avoid animation artifacts
        ctx.clearRect(prevX, 0, markerWidth, height);

        // recreate a marker if the cleared value is part of the selected group
        const erasedMarker = markers.find(
          (marker) => marker.x === prevX && marker.value === info.value
        );
        if (erasedMarker) {
          ctx.clearRect(erasedMarker.x, erasedMarker.y, markerWidth, height);
          ctx.beginPath();
          ctx.rect(erasedMarker.x, erasedMarker.y, markerWidth, markerHeight);
          ctx.fill();
        }

        // Starts y axis at center, moves upwards according to increasing marker height.
        ctx.fillRect(
          info.x,
          height / 2 - currentHeight / 2,
          markerWidth,
          currentHeight
        );

        if (easeValue < 1) {
          easeValue += 0.08; // determines speed
          requestAnimationFrame(scaleHeight);
        }
      };
      if (hover) {
        scaleHeight();
      }
    },
    [markers, height, hover, markerHeight, markerWidth, prevX]
  );

  const draw = useCallback(
    (ctx, info) => {
      // If a colored marker is hovered over, animate the height
      if (!info?.grey && info && info?.hoverIndex === info?.index) {
        setX(info?.x);
        hoverAnimation(ctx, info);
      }
      // Draw static marker regardless, for when animated marker is cleared
      ctx.clearRect(info?.x, info?.y, markerWidth, height);
      ctx.fillStyle = info.color;
      ctx.beginPath();
      ctx.rect(info.x, info.y, markerWidth, markerHeight);
      ctx.fill();
    },
    [height, hoverAnimation, markerHeight, markerWidth]
  );

  // Quadratic ease out for smoother animation, function from https://jsfiddle.net/jonataswalker/Laoc4hmr/,
  const easeOutQuad = (t) => {
    return t * (2 - t);
  };

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      const canvasObj = canvasRef?.current;
      const ctx = canvasObj?.getContext("2d");
      // clear the canvas area before rendering the markers held in state
      ctx?.clearRect(0, 0, width, height);

      // draw all markers held in state
      markers.forEach((coordinate) => {
        draw(ctx, coordinate);
      });
    }

    return () => {
      abort = true;
    };
  }, [draw, height, width, markers, hover]);

  return [markers, setMarkers, canvasRef];
}
