import React from "react";
import "./check-box.scss";

const DEFAULT_CHECKBOX_WIDTH_PX = 16;
const DEFAULT_CHECKBOX_BORDER_RADIUS_PX = 2;

const CheckBox = (props) => {
  const {
    checked,
    value,
    handleClick,
    className = "",
    widthMultiplier = 1,
  } = props;

  const checkboxWidth = widthMultiplier
    ? DEFAULT_CHECKBOX_WIDTH_PX * widthMultiplier
    : DEFAULT_CHECKBOX_WIDTH_PX;

  const generateCheckmarkStyle = (widthMultiplier) => {
    const formattedCheckmarkStyle = {
      width: DEFAULT_CHECKBOX_WIDTH_PX,
      height: DEFAULT_CHECKBOX_WIDTH_PX,
    };

    // If the size of the checkbox is changed from default
    // Resize and reposition the checkmark as well
    if (widthMultiplier !== 1) {
      const amountGreaterThanOne = widthMultiplier - 1;
      const placementAdjustment =
        (amountGreaterThanOne * DEFAULT_CHECKBOX_WIDTH_PX) / 2;
      formattedCheckmarkStyle.transform = `matrix(${widthMultiplier}, 0, 0, ${widthMultiplier}, ${placementAdjustment}, ${placementAdjustment})`;
    }

    return formattedCheckmarkStyle;
  };

  return (
    <div className={`checkbox${className ? ` ${className}` : ""}`}>
      {checked && (
        <div
          className={"checkmark"}
          style={generateCheckmarkStyle(widthMultiplier)}
        />
      )}
      <input
        type={"checkbox"}
        name={value}
        value={value}
        checked={checked}
        onChange={(e) => handleClick(e)}
        style={{
          // min and max width and height to keep values from getting overwritten
          minWidth: checkboxWidth,
          minHeight: checkboxWidth,
          maxWidth: checkboxWidth,
          maxHeight: checkboxWidth,
          borderRadius: DEFAULT_CHECKBOX_BORDER_RADIUS_PX * widthMultiplier,
        }}
      />
    </div>
  );
};

export default CheckBox;
