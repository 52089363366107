import ChartTooltip from "../ChartTooltip/ChartTooltip";
import Format from "../../services/format";
import "./pie-chart-tool-tip.scss";

const SQUARE_ICON_SIZE = 20;

const PieChartToolTip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const title = payload[0].payload.title;
    const artist = payload[0].payload.metadata?.artist;

    return (
      <ChartTooltip
        payload={[
          {
            name: `${payload[0].value}%`,
            color: payload[0].payload.fill,
          },
        ]}
        label={`${title ? `${Format.filterNamedPartners(title)}${artist ? ` - ${artist}` : ""}` : "N/A"}`}
        squareIconSize={SQUARE_ICON_SIZE}
      />
    );
  }

  return null;
};

export default PieChartToolTip;
