// lowest and highest suspicion ranges for metrics
// assume values are rounded to 2 decimal places
const METRIC_LIMITS = {
  unique_device_id_count: { high: 11, low: 6 },
  unique_event_country_count: { high: 8, low: 2 },
  unique_country_count: { high: 8, low: 2 },
  total_plays: { high: 3360, low: 2000 },
  avg_user_unique_device_id_count: { high: 10, low: 5 },
  avg_user_failed_track_rate: { high: 5, low: 1.99 },
  avg_user_total_flags: { high: 0.5, low: 0.29 },
  avg_user_overlapping_plays_ratio: { high: 4.99, low: 1.99 },
};

export default METRIC_LIMITS;
