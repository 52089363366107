import React from "react";
import "./dashboard-footer.scss";

const BUILD_VER = process.env.REACT_APP_BUILD_VERSION;
const FOOTER_TEXT = "Submit Label Studio Feedback";

const DashboardFooter = () => {
  return (
    <div className={"dashboard-footer-container"}>
      <div className={"build-version-label"}>
        {BUILD_VER ? `v${BUILD_VER}` : ""}
      </div>
      <a
        href="https://forms.gle/rb9GjBmEqC4DncKn7"
        target="_blank"
        rel="noopener noreferrer"
      >
        {FOOTER_TEXT}
      </a>
    </div>
  );
};

export default DashboardFooter;
