import React from "react";
import SelectionTextBox from "../SelectionTextBox/SelectionTextBox";
import "./selection-boxes.scss";

const DEFAULT_OPTIONS = ["I'm Not Sure", "Other"];

const SelectionBoxes = ({
  options = [], // Array of strings for options to select from
  selectedOption, // String of the selected option
  onChange = () => {}, // Callback function to run when the selected option is changed
  visibleTextbox = false, // Boolean to determine whether to that textbox is visible
  placeholderText = { placeholder: "", required: false }, // Object, the placeholder text and required boolean for the text box
  textboxContent = "", // String, the content of the textbox, if rendered
  setTextboxContent, // setState callback function to run to update the text
}) => {
  // Consolidate the options array with the default options
  const fullOptionsList = [...options, ...DEFAULT_OPTIONS];

  const handleOptionClick = (option) => {
    if (selectedOption === option) {
      onChange(undefined);
    } else {
      onChange(option, fullOptionsList.reverse().indexOf(option));
    }
  };

  const generateOptionBoxes = (optionList) => {
    return optionList.map((option, index) => {
      return (
        <div
          className={`selection-box-option${
            selectedOption === option ? " selected" : ""
          }`}
          key={`${option}-${index}`}
          onClick={() => handleOptionClick(option)}
        >
          {option}
        </div>
      );
    });
  };

  return (
    <div className={"selection-boxes-container"}>
      <div className={"selection-boxes"}>
        {generateOptionBoxes(fullOptionsList)}
      </div>
      {setTextboxContent && (
        <SelectionTextBox
          visible={visibleTextbox}
          selectedOption={selectedOption}
          placeholderText={placeholderText.placeholder}
          textRequired={placeholderText.required}
          setTextContent={setTextboxContent}
          textContent={textboxContent}
        />
      )}
    </div>
  );
};

export default SelectionBoxes;
