import React, { useState, useEffect } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./progress-ring.scss";

const TEXT_FONT_SIZE = "23px";
const RING_STROKE_WIDTH = 6; // 0 - 100

const ProgressRing = ({ percentage, color }) => {
  const [percentToRender, setPercentToRender] = useState(null);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      setPercentToRender(percentage);
    }

    return () => {
      abort = true;
    };
  }, [percentage]);

  return (
    <CircularProgressbar
      value={percentToRender}
      text={
        percentToRender !== null && percentToRender !== undefined
          ? `${percentToRender}%`
          : "n/a"
      }
      strokeWidth={RING_STROKE_WIDTH}
      styles={{
        path: {
          stroke: color,
          transition: "stroke-dashoffset 1s ease 0s",
        },
        text: {
          fill: color,
          fontSize: TEXT_FONT_SIZE,
        },
      }}
    />
  );
};

export default ProgressRing;
