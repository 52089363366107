import React, { useState } from "react";
import DrawSvgs from "../../services/draw_svgs";
import "./label-history-open-tabs-header.scss";

const X_SVG_PATH = "M15 15L1 1M1 15L15 1L1 15Z";
const X_SVG_WIDTH_PX = 16;
const X_SVG_COLOR = "#FFFFFF";

const CTA_BUTTON_TEXT = "Open All";
const POPUPS_BLOCKED_MESSAGE =
  "Please whitelist this website for popups and try again";

const LabelHistoryOpenTabsHeader = (props) => {
  const [popupsBlocked, setPopupsBlocked] = useState(false);

  const handleCtaClick = (event) => {
    event.preventDefault();

    let popupWasBlocked = false;

    // Requires the user to whitelist the website for popups
    const openTabs = (links) => {
      if (popupsBlocked) setPopupsBlocked(false);
      for (const link of links) {
        // Cease attempting to open new tabs if popups are blocked
        // As to avoid potentially being flagged as suspicious
        if (!popupWasBlocked) {
          const openedTab = window.open(link, "_blank");
          if (openedTab === null) popupWasBlocked = true;
        }
      }
    };

    openTabs(props.selectedLinks);

    if (popupWasBlocked) {
      // If tabs failed to open, display popup message
      setPopupsBlocked(true);
    } else {
      // Otherwise, clear checkboxes
      props.clearSelectedCheckboxes();
    }
  };

  const handleCloseClick = (event) => {
    event.preventDefault();
    props.clearSelectedCheckboxes();
  };

  return (
    <div
      className={`label-history-open-tabs-header-container ${
        props.selectedLinks && props.selectedLinks.length > 0
          ? "visible"
          : "hidden"
      }${popupsBlocked ? " popup-message" : ""}`}
    >
      <div className={"label-history-open-tabs-header-background"}>
        <div className={"label-history-left"}>
          <div
            className={"label-history-close-button"}
            onClick={(e) => handleCloseClick(e)}
          >
            {DrawSvgs.drawCustomSvgPath(
              X_SVG_PATH,
              X_SVG_WIDTH_PX,
              X_SVG_WIDTH_PX,
              X_SVG_COLOR
            )}
          </div>
          <span
            className={"label-history-number-selected"}
          >{`${props.selectedLinks.length} selected`}</span>
        </div>
        <button className={"cta-button"} onClick={(e) => handleCtaClick(e)}>
          {CTA_BUTTON_TEXT}
        </button>
      </div>
      <span className={"label-history-open-tabs-popup-message"}>
        {POPUPS_BLOCKED_MESSAGE}
      </span>
    </div>
  );
};

export default LabelHistoryOpenTabsHeader;
