import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import DateRangeController from "../DateRangeController/DateRangeController";
import Logout from "../Logout/Logout";
import Util from "../../services/util";
import Format from "../../services/format";
import Loading from "../Loading/Loading";
import SelectFilter from "../SelectFilter/SelectFilter";
import { ReactComponent as HistoryIcon } from "../../styles/icons/history.svg";
import { ReactComponent as HistoryHoverIcon } from "../../styles/icons/history_hover.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PARTNER_LIST from "../../config/available_partners";
import "./dashboard-header.scss";

const SONG_NO_ARTIST_NAME_PLACEHOLDER = "Artist Link";
const SONG_NO_ARTIST_INFO_MESSAGE = "No Artist Data Available";
const APPLY_BUTTON_TEXT = "Apply";

const DashboardHeader = (props) => {
  const history = useHistory();
  const scrollRef = useRef(null);
  const [headerTitle, setHeaderTitle] = useState("Loading...");
  const [headerYPosition, setHeaderYPosition] = useState(undefined);
  const [historyHover, setHistoryHover] = useState(false);
  const [loadingAnimation, setLoadingAnimation] = useState(false);
  const [songArtistInfo, setSongArtistInfo] = useState({ id: "", name: "" });
  const [entityType, setEntityType] = useState(props.entityType);
  const [partnerName, setPartnerName] = useState(props.partnerName);
  const [applyButtonActive, setApplyButtonActive] = useState(false);
  const setChangingDataSource = props.setChangingDataSource;

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      const formatHeaderTitle = async () => {
        let newHeaderTitle = props.entityId;

        if (props.entityType === "song") {
          // get song metadata and format it
          const metadata = await Util.getEntityMetadata(
            props.entityType,
            props.entityId,
            props.startDate,
            props.partnerName
          );

          setSongArtistInfo({
            id: metadata.track_artist_id,
            name: metadata.track_artist,
          });

          if (metadata.track_title) {
            newHeaderTitle = metadata.track_title;
          }
        } else if (props.entityType === "artist") {
          // get artist metadata and format it
          const metadata = await Util.getEntityMetadata(
            props.entityType,
            props.entityId,
            props.startDate,
            props.partnerName
          );

          if (metadata.artist_name) {
            newHeaderTitle = metadata.artist_name;
          }
        }

        setHeaderTitle(newHeaderTitle);
      };

      formatHeaderTitle();
    }

    return () => {
      abort = true;
    };
  }, [props.entityId, props.entityType, props.startDate, props.partnerName]);

  useEffect(() => {
    const onScroll = () => {
      const yPosition = scrollRef.current
        .getBoundingClientRect()
        .bottom.toFixed();
      setHeaderYPosition(yPosition);
    };

    // clean up listener
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  });

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      // End the loading state animation
      setLoadingAnimation(false);
      setChangingDataSource(false);
    }

    return () => {
      abort = true;
    };
  }, [props.entityType, props.entityId, setChangingDataSource]);

  const handleDataSourceChange = async () => {
    let entityId, formattedEntityId, metadata, tableDate;

    // Begin profile page switch loading animation
    setLoadingAnimation(true);
    setChangingDataSource(true);
    props.handlePartnerChange(partnerName);

    // get a random entity id and session variables
    // also confirm user is authenticated
    try {
      ({ entityId, metadata, tableDate } = await Util.getRandomEntity(
        entityType,
        partnerName
      ));
    } catch (error) {
      history.push("/login");
    }
    const providerNum = Format.getNumberByProvider(partnerName);

    const localEntityId = localStorage.getItem(`${providerNum}${entityType}Id`);
    const localTableDate = localStorage.getItem(
      `${providerNum}${entityType}TableDate`
    );

    // use local storage variables only if both are available
    if (localEntityId && localTableDate) {
      entityId = localEntityId;
      tableDate = localTableDate;

      if (entityType === "song") {
        metadata = await Util.getEntityMetadata(
          entityType,
          entityId,
          tableDate,
          partnerName
        );
      }
    }

    // set formatted id in song URL
    if (entityType === "song") {
      formattedEntityId = Format.formatSongUrl(
        metadata?.track_title,
        metadata?.track_artist
      );
    }

    // Check that we have valid values before updating
    if (entityType && entityId && tableDate && partnerName) {
      // reassign local storage variables
      localStorage.setItem("providerNum", providerNum);
      localStorage.setItem(`${providerNum}${entityType}Id`, entityId);
      localStorage.setItem("entityType", entityType);
      localStorage.setItem(`${providerNum}${entityType}TableDate`, tableDate);

      // reload page with new params
      if (entityType === "user" || entityType === "artist") {
        history.push({
          pathname: Format.composeProfileUrlPath(
            partnerName,
            entityType,
            entityId,
            tableDate
          ),
        });
      } else {
        history.push({
          pathname: Format.composeProfileUrlPath(
            partnerName,
            entityType,
            entityId,
            tableDate,
            formattedEntityId
          ),
        });
      }

      if (props.entityType === entityType) {
        // If the provider has changed but entity type is the same
        // Refresh the page avoid asynchronous state updating issues
        window.location.reload(false);
      }
    }

    window.scrollTo(0, 0);
  };

  const handleUserHistoryClick = () => {
    history.push("/history");
  };

  const generateHeaderTextSection = (ref) => {
    return (
      <div className={"dashboard-header-text"}>
        <div className={"header-text-container"} ref={ref}>
          {props.entityType === "artist" || props.entityType === "song" ? (
            <div className={"artist-header-container"}>
              <div className={"header-text"}>
                <div className={"artist-header"}>
                  <div className={"artist-header-title"}>{headerTitle}</div>
                  {props.entityType === "song" && (
                    <div className={"song-header-artist-link"}>
                      {songArtistInfo.id || songArtistInfo.name ? (
                        songArtistInfo.id ? (
                          <Link
                            to={`/artist/${
                              songArtistInfo.id
                            }/${localStorage.getItem(
                              `${partnerName}${props.entityType}TableDate`
                            )}`}
                            target={"_blank"}
                          >
                            {songArtistInfo.name ??
                              SONG_NO_ARTIST_NAME_PLACEHOLDER}
                            <ArrowForwardIosIcon
                              className={"song-header-artist-link-arrow"}
                            />
                          </Link>
                        ) : (
                          songArtistInfo.name
                        )
                      ) : (
                        SONG_NO_ARTIST_INFO_MESSAGE
                      )}
                    </div>
                  )}
                  {props.entityType === "artist" && (
                    <div className={"artist-id-text"}>
                      {`Artist ${props.entityId}`}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className={"header-text"}>{`${
              props.entityType.length > 0
                ? props.entityType[0].toUpperCase() +
                  props.entityType.substring(1)
                : ""
            } ${headerTitle}`}</div>
          )}
          <DateRangeController
            startDate={props.startDate}
            endDate={props.endDate}
            dataWeeks={props.dataWeeks}
            handleDateRangeChange={props.handleDateRangeChange}
            useStyle={"default"}
          />
        </div>
      </div>
    );
  };

  const generateStickyHeader = () => {
    const refHeight = scrollRef.current?.getBoundingClientRect().height;
    const refHeightHalved = refHeight / 2;
    const normalHeaderView =
      headerYPosition === undefined || headerYPosition >= 100 - refHeightHalved;

    return (
      <div className={"dashboard-header-upper-wrapper"}>
        <div className={"dashboard-header-upper-options-container"}>
          <div
            className={"dashboard-header-options dashboard-header-sticky"}
            style={{
              opacity: normalHeaderView ? 0 : 1,
              pointerEvents: `${normalHeaderView ? "none" : "auto"}`,
            }}
          >
            {generateHeaderTextSection()}
          </div>
          <div
            className={"dashboard-header-options"}
            style={{
              opacity: !normalHeaderView ? 0 : 1,
              pointerEvents: `${!normalHeaderView ? "none" : "auto"}`,
            }}
          >
            <div className={"entity-dropdown"}>
              <SelectFilter
                setSelectedField={(newPartner) => {
                  setPartnerName(newPartner);
                  setApplyButtonActive(false);
                  setEntityType(false);
                }}
                selectedField={partnerName ?? props.partnerName}
                filterOptions={PARTNER_LIST}
                label={"Currently sampling: "}
              />
              <SelectFilter
                setSelectedField={(newEntityType) => {
                  setEntityType(newEntityType);
                  // Only enable Apply if the selections type differ from current page
                  setApplyButtonActive(
                    newEntityType !== props.entityType ||
                      partnerName !== props.partnerName
                  );
                }}
                selectedField={entityType ?? props.entityType}
                filterOptions={
                  PARTNER_LIST?.find(
                    (partner) =>
                      partner.field === (partnerName ?? props.partnerName)
                  )?.entityTypes
                }
                label={""}
              />
              <button
                className={`header-apply-button ${
                  applyButtonActive ? "enabled" : "disabled"
                }`}
                onClick={handleDataSourceChange}
              >
                {APPLY_BUTTON_TEXT}
              </button>
            </div>
            <div className={"dashboard-header-history-logout"}>
              <div className={"label-links"}>
                <div className={"label-link"}>
                  <Link to={"/label/user"}>User</Link>
                </div>
                <div className={"label-link"}>
                  <Link to={"/label/artist"}>Artist</Link>
                </div>
                <div className={"label-link"}>
                  <Link to={"/label/song"}>Song</Link>
                </div>
              </div>
              <div
                className={"user-history-button-container"}
                onMouseEnter={() => setHistoryHover(true)}
                onMouseLeave={() => setHistoryHover(false)}
              >
                {historyHover ? (
                  <HistoryHoverIcon
                    className={"user-history-button"}
                    onClick={handleUserHistoryClick}
                  />
                ) : (
                  <HistoryIcon
                    className={"user-history-button"}
                    onClick={handleUserHistoryClick}
                  />
                )}
              </div>
              <Logout />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={"dashboard-header-container"}>
      {generateStickyHeader()}
      {generateHeaderTextSection(scrollRef)}
      {loadingAnimation && (
        <div className={"entity-loading-wrapper"}>
          <Loading />
        </div>
      )}
    </div>
  );
};

export default DashboardHeader;
