import React from "react";
import { useHistory } from "react-router-dom";
import "./logout.scss";

const LOGOUT_BUTTON_TEXT = "Logout";
const DEFAULT_THEME = "dark";

const Logout = (props) => {
  const history = useHistory();

  const onLogout = () => {
    localStorage.removeItem("tk");
    history.push("/login");
  };

  return (
    <button
      className={`logout-button ${props.theme ? props.theme : DEFAULT_THEME}`}
      onClick={onLogout}
    >
      {LOGOUT_BUTTON_TEXT}
    </button>
  );
};

export default Logout;
