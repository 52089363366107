import React, { useRef, useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ChartTooltip from "../ChartTooltip/ChartTooltip";
import Format from "../../services/format";
import usePrevious from "../UsePrevious/UsePrevious";
import "./timeline-tool-tip.scss";

const TOOLTIP_TITLE = "Play Event Information";
const PADDING = 5;

const TimelineToolTip = (props) => {
  const tooltipRef = useRef(null);
  // For sticky tooltip scrolling
  const [stickyXPosition, setStickyXPosition] = useState(undefined);
  const [ogYStickyPosition, setOgYStickyPosition] = useState(undefined);
  const [scrollY, setScrollY] = useState(undefined);
  const [ogScrollY, setOgScrollY] = useState(undefined);
  const prevRenderStickyTooltip = usePrevious(props.renderStickyTooltip);

  useEffect(() => {
    const onScroll = () => {
      if (props.renderStickyTooltip) {
        setScrollY(+window.scrollY.toFixed());
      }
    };

    if (props.renderStickyTooltip) {
      const startingScroll = +window.scrollY.toFixed();
      setScrollY(startingScroll);
      setOgScrollY(startingScroll);
    }

    if (!props.renderStickyTooltip && prevRenderStickyTooltip) {
      // Clean up state on close
      setStickyXPosition(undefined);
      setOgYStickyPosition(undefined);
      setScrollY(undefined);
      setOgScrollY(undefined);
    }

    // clean up listener
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, [
    props.renderStickyTooltip,
    prevRenderStickyTooltip,
    props.y,
    ogYStickyPosition,
  ]);

  const formatEvent = (playEvent) => {
    // Formats the data to be compatible with the generic ChartTooltip component
    const payload = [];

    if (playEvent) {
      Object.entries(playEvent).forEach((entry) => {
        const [key, value] = entry;
        if (!props.hiddenFields?.includes(key)) {
          let data = value;

          if (typeof data === "string") {
            // replace named partner instances
            data = Format.filterNamedPartners(data);
          }

          // For making the selected row bold
          const className = props.selectedField === key ? "selected" : "";

          // Avoid throwing an error and crashing if sent unexpected, invalid data
          if (
            typeof data === "string" ||
            data === null ||
            typeof data === "number"
          ) {
            // Assign the data as the value or N/A if it is null
            payload.push({ name: key, value: data ?? "N/A", className });
          }
        }
      });

      return payload;
    }
  };

  const ensureVisibility = (placement) => {
    const newPlacement = { ...placement };
    const measurements = {
      y: {
        tooltip: tooltipRef.current?.offsetHeight,
        window: window.innerHeight,
      },
      x: {
        tooltip: tooltipRef.current?.offsetWidth,
        window: window.innerWidth,
      },
    };

    const pxOffScreen = (axis, value) => {
      return value + measurements[axis].tooltip - measurements[axis].window;
    };

    Object.entries(placement).forEach(([attr, value]) => {
      // Filters out 'right' or 'left', whichever is unused
      if (typeof value === "number") {
        const axis = attr === "top" ? "y" : "x";
        // Doesn't recalculate x axis if tooltip is sticky
        if (axis !== "x" || stickyXPosition === undefined) {
          const difference = pxOffScreen(axis, value);
          if (difference > 0) {
            newPlacement[attr] = value - difference;

            // Add padding/margin from the edge of the window if there's room
            if (
              newPlacement[attr] > 0 &&
              pxOffScreen(axis, newPlacement[attr] - PADDING) < 0
            ) {
              newPlacement[attr] -= PADDING;
            }
            if (props.renderStickyTooltip && !prevRenderStickyTooltip) {
              if (axis === "x") {
                setStickyXPosition(newPlacement[attr]);
              }
            }
          }
        } else {
          // Keep the x value the same when tooltip is sticky
          newPlacement[attr] = stickyXPosition;
        }
        // When the sticky tooltip is first toggled
        // Assign the original 'Y' position to state
        if (
          props.renderStickyTooltip &&
          axis === "y" &&
          ogYStickyPosition === undefined
        ) {
          setOgYStickyPosition(newPlacement[attr]);
        }
      }
    });

    return newPlacement;
  };

  const getYBasedOnSticky = (sticky) => {
    // Calculates top distance for tooltip if sticky
    // And required values are not undefined
    // Otherwise, returns the default props.y
    const topDistance =
      sticky &&
      ogYStickyPosition !== undefined &&
      scrollY !== undefined &&
      ogScrollY !== undefined
        ? Math.round(ogYStickyPosition - (scrollY - ogScrollY))
        : props.y;
    return topDistance > PADDING ? topDistance : PADDING;
  };

  return (
    <div
      ref={tooltipRef}
      id={"timeline-tooltip"}
      className={`${props.renderStickyTooltip ? "sticky" : "hover"} ${
        props.display ? "visible" : "hidden"
      }`}
      style={ensureVisibility({
        top: getYBasedOnSticky(props.renderStickyTooltip),
        left: props.flipPosition ? "auto" : props.x,
        right: props.flipPosition ? props.x : "auto",
      })}
    >
      <ChartTooltip
        payload={formatEvent(props.playEvent)}
        label={TOOLTIP_TITLE}
        columnsCount={props.columnsCount}
        rowLabelFormatter={Format.formatTimelineTooltipRow}
      />
      <div
        className={`timeline-tooltip-close-icon ${
          props.display && props.includeCloseIcon ? "visible" : "hidden"
        }`}
        onClick={props.handleCloseIconClick}
      >
        <CloseIcon />
      </div>
    </div>
  );
};

export default TimelineToolTip;
