import React, { useState, useEffect, useCallback } from "react";
import Loading from "../Loading/Loading";
import PercentileMetric from "../PercentileMetric/PercentileMetric";
import Card from "../../containers/Card/Card";
import axios from "../../config/axios";
import "./artist-users.scss";

const INFO_ICON_TEXT =
  "The number of unique users divided by the total number of streams for the artist.";

const ArtistUsers = ({ artistId, tableDate, partnerName, pauseUpdates }) => {
  const [loading, setLoading] = useState(true);
  const [totalUsers, setTotalUsers] = useState(0);
  const [artistPlays, setArtistPlays] = useState(0);
  const [avgArtistUserPlays, setAvgArtistUserPlays] = useState(0);
  const [userDiversity, setUserDiversity] = useState(0);
  const [userDiversityPercentile, setUserDiversityPercentile] = useState(0);
  const [userDiversityZScore, setUserDiversityZScore] = useState(0);

  const getArtistUsersData = useCallback(
    async (artistId, tableDate, partnerName) => {
      if (artistId !== null && artistId.length) {
        try {
          const res = await axios.post(
            "/get-artist-total-users",
            {
              entityID: artistId,
              tableDate: tableDate,
              provider: partnerName,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("tk")}`,
              },
            }
          );

          const results = res.data.results;

          // Populate with data from the API
          setTotalUsers(results.total_users);
          setArtistPlays(results.artist_plays);
          // Allow up to 2 decimal digits, but gets rid of trailing zeroes
          // e.g '1' instead of '1.00'
          setAvgArtistUserPlays(
            Math.round(results.avg_artist_user_plays * 100) / 100
          );
          setUserDiversity(results.user_diversity);
          setUserDiversityPercentile(results.user_diversity_percentile);
          setUserDiversityZScore(results.user_diversity_z_score);
        } catch (error) {
          setTotalUsers(null);
          setArtistPlays(null);
          setAvgArtistUserPlays(null);
          setUserDiversity(null);
          setUserDiversityPercentile(null);
          setUserDiversityZScore(null);
        }

        // End loading state regardless
        setLoading(false);
      }
    },
    []
  );

  // Update component data on date or id change
  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort && !pauseUpdates) {
      setLoading(true);
      getArtistUsersData(artistId, tableDate, partnerName);
    }

    return () => {
      abort = true;
    };
  }, [artistId, tableDate, partnerName, pauseUpdates, getArtistUsersData]);

  return (
    <div className={"artist-users-wrapper"}>
      {!loading ? (
        <>
          <div className={"total-users-section"}>
            <div className={"total-users-label"}>Total Users</div>
            <div className={"total-users-value"}>{totalUsers}</div>
          </div>
          <div className={"artist-users-secondary-metrics"}>
            <div className={"artist-users-secondary-metric left"}>
              <div className={"secondary-metric-label"}>
                Plays for This Artist
              </div>
              <div className={"secondary-metric-value"}>{artistPlays}</div>
            </div>
            <div className={"artist-users-secondary-metric right"}>
              <div className={"secondary-metric-label"}>
                Avg Artist Plays per User
              </div>
              <div className={"secondary-metric-value"}>
                {avgArtistUserPlays}
              </div>
            </div>
          </div>
          <div className={"percentile-metric-wrapper"}>
            <PercentileMetric
              title={"User Diversity"}
              infoDesc={INFO_ICON_TEXT}
              score={userDiversity}
              percentile={userDiversityPercentile}
              zScore={userDiversityZScore}
              stackHeaders={true}
              titleLength={140}
            />
          </div>
        </>
      ) : (
        <div className={"artist-users-loader"}>
          <Loading />
        </div>
      )}
    </div>
  );
};

export default Card(ArtistUsers);
