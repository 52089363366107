// For the submit form
import { SUBMIT_FORM_REASONS } from "./shared_form_fraud_reasons";

// For card fraud reasons
export const ACCOUNT_TAKEOVER_REASON = {
  id: 1,
  label: "Signs of account takeover or sharing",
};
const LOW_VARIATION_REASON = {
  id: 2,
  label: "Low variation in stream duration",
};

const HIGH_COUNT_REASONS = {
  play: { id: 3, label: "Abnormally high play count" },
};

const LOW_DIVERSITY_REASONS = {
  users: { id: 4, label: "Low diversity in users" },
  devices: { id: 5, label: "Low diversity in device type" },
};

const THIRTY_TO_SIXTY_SECONDS_DURATION = {
  song: { id: 6, label: "High proportions of ~30-60 second plays" }, // assume song >= 60s
};

const UNIFORM_PLAY_DURATION_REASON = {
  id: 7,
  label: "Song play durations are too uniform",
};

export const CARD_FRAUD_REASONS = {
  percentileMetrics: {
    reasons: [UNIFORM_PLAY_DURATION_REASON],
  },
};

export const FORM_REASONS = {
  specific: [
    { reason: THIRTY_TO_SIXTY_SECONDS_DURATION.song },
    { reason: UNIFORM_PLAY_DURATION_REASON },
    { reason: HIGH_COUNT_REASONS.play },
    { reason: LOW_DIVERSITY_REASONS.users },
    { reason: LOW_DIVERSITY_REASONS.devices },
    { reason: LOW_VARIATION_REASON },
  ],
  shared: SUBMIT_FORM_REASONS.shared,
  preSubmit: SUBMIT_FORM_REASONS.preSubmit,
};
