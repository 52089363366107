import React from "react";
import "./dashboard-view.scss";

const DashboardView =
  (ViewComponent) =>
  ({ ...props }) => {
    return (
      <div className={"dashboard-view"}>
        <ViewComponent {...props} />
      </div>
    );
  };

export default DashboardView;
