import React from "react";
import Checkbox from "../CheckBox/CheckBox";
import InfoTip from "../InfoTip/InfoTip";
import SelectionTextBox from "../SelectionTextBox/SelectionTextBox";
import "./selection-checkbox-list.scss";

const SelectionCheckboxList = ({
  options = [], // Array of options to render, objects with required 'text' and 'tooltip' keys, and optional 'id' and 'textbox' keys
  row = true, // Boolean, controls whether the list is rendered in a row or column
  selectedOptions = [], // Array of selected options
  setSelectedOptions = () => {}, // setState callback function to alter the selected options
  customToggleLogicOnly = false, // Boolean, If true, the component will only run the setSelectedOptions callback
  tooltipWidth = 200, // Pixel width of the tooltip, if any are rendered
}) => {
  const toggleSelection = (value) => {
    customToggleLogicOnly
      ? setSelectedOptions(value)
      : setSelectedOptions((prevSelectedOptions) =>
          prevSelectedOptions.includes(value)
            ? prevSelectedOptions.filter((option) => option !== value)
            : [...prevSelectedOptions, value]
        );
  };

  return (
    <div
      className={`selection-checkbox-list-container ${row ? "row" : "column"}`}
    >
      {options.map(({ text, tooltip, id, textBox }, index) => {
        const value = id ?? index;
        return (
          <div
            key={`${text}-${value}`}
            className={"selection-checkbox-wrapper"}
          >
            <div
              className={`selection-checkbox-container${
                textBox ? " textbox-version" : ""
              }`}
            >
              <Checkbox
                checked={selectedOptions.includes(value)}
                value={text}
                handleClick={() => toggleSelection(value)}
                widthMultiplier={1.5}
              />
              <label htmlFor={text} onClick={() => toggleSelection(value)}>
                {text}
              </label>
              {!!tooltip && (
                <InfoTip
                  description={tooltip}
                  arrow={false}
                  tooltipWidth={tooltipWidth}
                />
              )}
            </div>
            {textBox && (
              <SelectionTextBox
                visible={true}
                placeholderText={"Please explain"}
                textRequired={true}
                setTextContent={textBox.setTextContent}
                textContent={textBox.textContent}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SelectionCheckboxList;
