import React from "react";
import TextField from "@mui/material/TextField";
import { ReactComponent as SearchIcon } from "../../styles/icons/labelstudio-search.svg";
import "./label-history-search.scss";

const PLACEHOLDER_TEXT = "Search by ID";

const LabelHistorySearch = (props) => {
  return (
    <TextField
      className={"search-bar"}
      placeholder={PLACEHOLDER_TEXT}
      variant={"outlined"}
      fullWidth={true}
      size={"small"}
      InputProps={{
        endAdornment: <SearchIcon className={"search-icon"} />,
      }}
      onChange={(e) => props.handleSearch(e.target.value)}
    />
  );
};

export default LabelHistorySearch;
