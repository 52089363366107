import React from "react";
import DrawSvgs from "../../services/draw_svgs";
import UseComponentVisible from "../UseComponentVisible/UseComponentVisible";
import UseLeftOrRight from "../UseLeftOrRight/UseLeftOrRight";
import Format from "../../services/format";
import styles from "../../styles/constants.scss";
import "./hanks_tip.scss";

// If resizing, use some sort of program to keep ratios the same
const LIGHTBULB_SVG_PATH =
  "M 15 6 C 11.2 6 8.5 8.7 8.5 12.1 C 8.5 13.6 9.1 14.7 10 15.7 C 10.14 15.88 10.31 16.09 10.49 16.3 L 10.59 16.41 C 10.8 16.66 11.02 16.92 11.22 17.18 C 11.62 17.72 12 18.31 12.21 19.02 C 12.27 19.21 12.24 19.41 12.15 19.58 C 12.05 19.75 11.89 19.88 11.71 19.94 C 11.52 19.99 11.32 19.97 11.14 19.88 C 10.97 19.79 10.84 19.64 10.78 19.45 C 10.64 18.99 10.38 18.56 10.02 18.09 C 9.84 17.85 9.64 17.61 9.44 17.38 L 9.35 17.27 C 9.17 17.06 8.98 16.83 8.79 16.6 C 7.91 15.5 7.04 14.14 7.04 12.1 C 7.04 7.85 10.46 4.49 15.04 4.49 C 19.63 4.49 23.04 7.85 23.04 12.1 C 23.04 14.14 22.17 15.5 21.29 16.6 C 21.1 16.83 20.91 17.06 20.73 17.27 L 20.64 17.38 C 20.43 17.63 20.23 17.86 20.06 18.09 C 19.7 18.56 19.44 18.99 19.3 19.45 C 19.25 19.64 19.11 19.8 18.94 19.89 C 18.77 19.99 18.56 20.01 18.37 19.95 C 18.18 19.9 18.02 19.77 17.92 19.59 C 17.83 19.41 17.81 19.21 17.87 19.02 C 18.08 18.31 18.46 17.72 18.86 17.18 C 19.07 16.92 19.28 16.66 19.49 16.41 L 19.59 16.3 C 19.77 16.09 19.94 15.88 20.11 15.66 C 20.91 14.68 21.54 13.64 21.54 12.1 C 21.54 8.73 18.85 5.99 15.04 5.99 Z M 12.54 25.23 C 12.54 25.04 12.62 24.84 12.76 24.7 C 12.9 24.56 13.09 24.48 13.29 24.48 L 16.79 24.48 C 16.99 24.48 17.18 24.56 17.32 24.7 C 17.46 24.84 17.54 25.04 17.54 25.23 C 17.54 25.43 17.46 25.62 17.32 25.77 C 17.18 25.91 16.99 25.98 16.79 25.98 L 13.29 25.98 C 13.09 25.98 12.9 25.91 12.76 25.77 C 12.62 25.62 12.54 25.43 12.54 25.23 Z M 11.79 21.48 C 11.59 21.48 11.4 21.56 11.26 21.7 C 11.12 21.84 11.04 22.04 11.04 22.23 C 11.04 22.43 11.12 22.62 11.26 22.77 C 11.4 22.91 11.59 22.98 11.79 22.98 L 18.29 22.98 C 18.49 22.98 18.68 22.91 18.82 22.77 C 18.96 22.62 19.04 22.43 19.04 22.23 C 19.04 22.04 18.96 21.84 18.82 21.7 C 18.68 21.56 18.49 21.48 18.29 21.48 L 11.79 21.48 Z";

const SVG_RADIUS = styles.hanksTipRadius.replace("px", "");
const SVG_DIAMETER = SVG_RADIUS * 2;
const DEFAULT_TEXT_TIP_WIDTH = 150;
const TEXT_TIP_PADDING = 16;

const HanksTip = ({
  tipText, // String, the text content of the tooltip
  textTipWidth = DEFAULT_TEXT_TIP_WIDTH, // Number, the PX width of the tooltip
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    UseComponentVisible(false);

  // draw up the svg icon
  const generateSvgIcon = () => {
    const svgInnerElements = [];
    svgInnerElements.push(
      DrawSvgs.formatEllipseSvg(
        SVG_RADIUS,
        SVG_RADIUS,
        "#ED2784",
        "100%",
        "tip-ellipse"
      )
    );
    svgInnerElements.push(
      DrawSvgs.formatCustomSvgPath(
        LIGHTBULB_SVG_PATH,
        styles.colorWhite,
        "100%",
        "tip-path"
      )
    );

    return DrawSvgs.composeSvg(
      svgInnerElements,
      SVG_DIAMETER,
      SVG_DIAMETER,
      ref
    );
  };

  return (
    <div className={"hanks-tip-container"}>
      <div
        className={"hanks-tip-icon"}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        {generateSvgIcon()}
      </div>
      <div
        className={"hanks-tip-text"}
        style={{
          display: isComponentVisible ? "inline-block" : "none",
          width: `${textTipWidth}px`,
          padding: TEXT_TIP_PADDING,
          ...UseLeftOrRight(ref, textTipWidth + TEXT_TIP_PADDING * 2),
        }}
      >
        {Format.splitAtNewLine(tipText).map((text, i) => (
          <div key={i}>{text}</div>
        ))}
      </div>
    </div>
  );
};

export default HanksTip;
