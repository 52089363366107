import React, { useState, useEffect, useCallback } from "react";
import MetricCard from "../MetricCard/MetricCard";
import axios from "../../config/axios";

import HANKS_TIPS_TEXT from "../../config/hanks_tips.json";

const CARD_TITLE = "Overlapping Plays";
const TOTAL_PLAYS_SUFFIX = " total plays";

const OverlappingPlaysCard = ({
  entityId,
  entityType,
  partnerName,
  tableDate,
  scoreLimits,
  pauseUpdates,
  cardFraudReasons,
  checkFraudReasonSelected,
  editSelectedFraudReasons,
}) => {
  const [loading, setLoading] = useState(true);
  const [totalPlaysText, setTotalPlaysText] = useState(null);
  const [percentile, setPercentile] = useState(null);
  const [score, setScore] = useState(null);
  const [zScore, setZScore] = useState(null);

  const getOverlappingPlaysData = useCallback(
    async (entityId, entityType, tableDate, partnerName) => {
      if (entityId !== null && entityId.length) {
        try {
          const res = await axios.post(
            "/get-overlapping-plays",
            {
              entityType,
              entityID: entityId,
              tableDate,
              provider: partnerName,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("tk")}`,
              },
            }
          );

          // Populate with data from the API
          const results = res.data.results;

          setPercentile(results.overlapping_plays_percentile);
          setTotalPlaysText(
            `${results.overlapping_plays ?? "n/a"}/${
              results.total_plays ?? "n/a"
            } ${TOTAL_PLAYS_SUFFIX}`
          );
          setZScore(results.overlapping_plays_z_score);
          setScore(results.overlapping_plays_ratio);
        } catch (error) {
          setPercentile(null);
          setZScore(null);
        }

        // End loading state regardless
        setLoading(false);
      }
    },
    []
  );

  // Update component data on date or id change
  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort && !pauseUpdates) {
      setLoading(true);
      getOverlappingPlaysData(entityId, entityType, tableDate, partnerName);
    }

    return () => {
      abort = true;
    };
  }, [
    entityId,
    entityType,
    tableDate,
    pauseUpdates,
    partnerName,
    getOverlappingPlaysData,
  ]);

  return (
    <MetricCard
      score={score}
      scoreLimits={scoreLimits}
      title={loading ? "Loading..." : CARD_TITLE}
      format={"%"}
      percentile={percentile}
      zScore={zScore}
      displaySecondaryMetrics={true}
      cardFraudReasons={cardFraudReasons}
      checkFraudReasonSelected={checkFraudReasonSelected}
      editSelectedFraudReasons={editSelectedFraudReasons}
      extraText={totalPlaysText}
      tipText={HANKS_TIPS_TEXT.userOverlappingPlays}
      textTipWidth={250}
    />
  );
};

export default OverlappingPlaysCard;
