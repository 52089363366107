import React from "react";
import "./selection-text-box.scss";

const PLACEHOLDER_TEXT_SUFFIX = { required: "*", optional: " - optional" };
const REQUIRED_ERROR_MESSAGE = "Required";

const SelectionTextBox = ({
  visible = true, // Boolean to determine if the component should be visible
  textRequired = false, // Boolean to indicate whether the input is required or optional
  setTextContent = () => {}, // setState callback function to run to update the text
  textContent = "", // String, the content of the text box
  placeholderText = "", // String, the placeholder text for the text box
  showErrorStyling = false, // Boolean to indicate whether to show error styling
}) => {
  return (
    <div className={"selection-text-box-wrapper"}>
      <div
        className={`selection-text-box-container ${
          visible ? "visible" : "hidden"
        }`}
      >
        <textarea
          className={`selection-text-box ${
            showErrorStyling ? "error required" : ""
          }`}
          onChange={(e) => {
            setTextContent(e.target.value);
          }}
          required={textRequired}
          value={textContent}
        />
        {placeholderText && !textContent && (
          <div
            className={`selection-text-box-placeholder ${
              textRequired ? "required" : "optional"
            }`}
          >
            {placeholderText}
            <span>
              {PLACEHOLDER_TEXT_SUFFIX[textRequired ? "required" : "optional"]}
            </span>
          </div>
        )}
        {showErrorStyling && (
          <span className={"selection-text-box-error-message"}>
            {REQUIRED_ERROR_MESSAGE}
          </span>
        )}
      </div>
    </div>
  );
};

export default SelectionTextBox;
