import randomColor from "randomcolor";
import styles from "../styles/constants.scss";

// default colors
const DEFAULT_COLOR_OPTIONS = [
  "#b036f7", // purple, med
  "#ef3ed8", // magenta, med
  "#47e55e", // green, med
  "#0004ff", // blue, med
  "#ff0000", // red, med
  "#ff9000", // orange, med
  "#00e0d5", // cyan, med
  "#f5ff00", // yellow, med
  "#db96ff", // purple, light
  "#ff99f1", // magenta, light
  "#96ffa8", // green, light
  "#8284ff", // blue, light
  "#ffa3a3", // red, light
  "#ffb657", // orange, light
  "#87ffe9", // cyan, light
  "#fcffb0", // yellow, light
  "#51007d", // purple, dark
  "#80006e", // magenta, dark
  "#007514", // green, dark
  "#00027d", // blue, dark
  "#a10000", // red, dark
  "#613200", // orange, dark
  "#009177", // cyan, dark
  "#7b8000", // yellow, dark
];

// pie chart colors
const PIE_COLORS = [
  styles.pieColorOne,
  styles.pieColorTwo,
  styles.pieColorThree,
  styles.pieColorFour,
  styles.pieColorFive,
  styles.pieColorSix,
];

class Colors {
  /**
   * Returns the list of standard default color options.
   */
  static getDefaultColors() {
    return DEFAULT_COLOR_OPTIONS;
  }

  /**
   * Returns the list of standard pie chart color options.
   */
  static getPieChartColors() {
    return PIE_COLORS;
  }

  /**
   * Generates a new color that hasn't already been used.
   * @param {Array<String>} usedColors list of colors that have already been used
   */
  static generateUnusedColor = (usedColors = []) => {
    const newColor = randomColor();

    // assume all default colors have already been used
    if (usedColors.length === 0) {
      usedColors = DEFAULT_COLOR_OPTIONS;
    }

    if (!usedColors.includes(newColor)) {
      return newColor;
    } else {
      return this.generateUnusedColor([...usedColors, newColor]);
    }
  };
}

export default Colors;
