import React, { useCallback, useEffect, useState } from "react";
import {
  PieChart as PieChartDisplay,
  Pie,
  Tooltip,
  Cell,
  Sector,
  ResponsiveContainer,
} from "recharts";
import SelectFilter from "../SelectFilter/SelectFilter";
import PieChartToolTip from "../PieChartToolTip/PieChartToolTip";
import Loading from "../Loading/Loading";
import Card from "../../containers/Card/Card";
import Format from "../../services/format";
import Colors from "../../services/colors";
import styles from "../../styles/constants.scss";
import "./pie-chart.scss";

const PIE_CHART_DEFAULT_COLOR = styles.colorBlack;
const NO_AVAILABLE_DATA = "No available data";

const LEGEND_DEFAULT_CHUNK_SIZE = 3;
const LEGEND_MAX_DEFAULT_CHUNK_LENGTH = 6;

const renderActiveShape = (props) => {
  const {
    cx: centerXcoord,
    cy: centerYcoord,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
  } = props;

  return (
    <g>
      <Sector // Base slice
        cx={centerXcoord}
        cy={centerYcoord}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector // Little slice on outside
        cx={centerXcoord}
        cy={centerYcoord}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 4} // Combination of inner + outer radius can introduce some movement during hover
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

const PieChart = ({
  chartId,
  title,
  data,
  dropdownOptions = [],
  dropdownDefaultOption = "",
  handleDropdownSelect,
  layout = "vertical",
  minHeight = 250,
}) => {
  const [sortedData, setSortedData] = useState([]);
  const [dropdownOption, setDropdownOption] = useState(dropdownDefaultOption);
  const [chunkSize, setChunkSize] = useState(LEGEND_DEFAULT_CHUNK_SIZE);
  const [chunkedData, setChunkedData] = useState([]);
  const [pieChartColors, setPieChartColors] = useState(
    Colors.getPieChartColors()
  );
  const [activeIndex, setActiveIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      if (!data) {
        setIsLoading(true);
        return;
      }

      const sortData = async () => {
        let filteredData;
        let dataArrToUse = data;

        if (
          dropdownOptions.length > 0 &&
          dropdownOption !== dropdownDefaultOption
        ) {
          filteredData = await handleDropdownSelect(chartId, dropdownOption);

          // If there is a filtered data set, use that, otherwise use the original data
          if (filteredData) {
            setIsUpdating(true);
            dataArrToUse = filteredData;
          } else {
            // If the request for the selected filter failed, reset the filter
            setDropdownOption(dropdownDefaultOption);
          }
        }

        // order by score descending
        return dataArrToUse.sort((a, b) => a.score < b.score);
      };

      sortData().then((newSortedData) => {
        let newChunkSize = newSortedData?.length;
        let newChunkedData = [];

        // chunk data in columns if using vertical layout
        if (layout === "vertical") {
          newChunkSize =
            newSortedData.length <= LEGEND_MAX_DEFAULT_CHUNK_LENGTH
              ? LEGEND_DEFAULT_CHUNK_SIZE
              : Math.ceil(newSortedData.length / 2);

          for (let i = 0; i < newSortedData.length; i += newChunkSize) {
            newChunkedData.push(newSortedData.slice(i, i + newChunkSize));
          }
        } else {
          // wrap sorted data in single chunked array
          newChunkedData = [newSortedData];
        }

        setSortedData(newSortedData);
        setChunkSize(newChunkSize);
        setChunkedData(newChunkedData);
        setIsUpdating(false);
      });
    }

    return () => {
      abort = true;
    };
  }, [
    data,
    layout,
    chartId,
    dropdownDefaultOption,
    dropdownOption,
    dropdownOptions.length,
    handleDropdownSelect,
  ]);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort && Array.isArray(data) && isLoading) {
      setIsLoading(false);
    }

    return () => {
      abort = true;
    };
  }, [data, isLoading]);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      if (!sortedData) return;

      // add additional colors if necessary
      if (sortedData.length > pieChartColors.length) {
        const newPieChartColors = [...pieChartColors];

        for (
          let i = pieChartColors.length - 1;
          i < sortedData.length - 1;
          i++
        ) {
          newPieChartColors.push(Colors.generateUnusedColor(newPieChartColors));
        }

        setPieChartColors(newPieChartColors);
      }
    }

    return () => {
      abort = true;
    };
  }, [sortedData, pieChartColors]);

  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const onPieLeave = useCallback(
    (_, index) => {
      setActiveIndex(null); // can change null to 'index' to test text onHover formatting for each slice
    },
    [setActiveIndex]
  );

  const onPieOver = useCallback(
    // Covers for the times when onMouseLeave fails to fire
    // When the cursor moves quickly
    (_, event) => {
      if (
        event?.target?.childNodes?.length &&
        event.target.childNodes.length > 0
      ) {
        setActiveIndex(null);
      }
    },
    [setActiveIndex]
  );

  const dataToRender = (dataToCheck) => {
    let valid = false;

    if (dataToCheck && dataToCheck.length > 0) {
      // assume data is valid unless every data object score is falsy
      valid = !dataToCheck.every((dataObj) => !dataObj.score);
    }

    return valid;
  };

  return (
    <div
      className={"pie-chart-container"}
      style={{ minHeight: `${minHeight}px ` }}
    >
      <div className={"pie-chart-title"}>{title}</div>
      {isLoading ? (
        <div className={"pie-chart-centered-option"}>
          <Loading />
        </div>
      ) : dataToRender(sortedData) ? (
        <>
          {dropdownOptions?.length > 0 && (
            <div className={"pie-chart-dropdown"}>
              <SelectFilter
                setSelectedField={async (newField) => {
                  setIsUpdating(true);
                  setDropdownOption(newField);
                  await handleDropdownSelect(chartId, newField);
                  setIsUpdating(false);
                }}
                selectedField={dropdownOption}
                filterOptions={dropdownOptions}
                label={"Filter By Device: "}
              />
            </div>
          )}
          <div
            className={
              layout === "horizontal"
                ? "pie-chart-content horizontal"
                : "pie-chart-content vertical"
            }
          >
            <div className={"pie-chart-chart-wrapper"}>
              <ResponsiveContainer width={"100%"} height={"100%"}>
                <PieChartDisplay onMouseOver={onPieOver}>
                  <Pie
                    dataKey={"score"}
                    nameKey={"title"}
                    data={sortedData}
                    isAnimationActive={false}
                    cx={"50%"} // Position center of pie chart along horizontal
                    cy={"50%"} // Position center of pie chart along vertical
                    activeIndex={activeIndex} // Required for onHover logic
                    activeShape={renderActiveShape} // onHover display percentage logic
                    onMouseEnter={onPieEnter}
                    onMouseLeave={onPieLeave}
                  >
                    {sortedData.map((entry, index) => {
                      return (
                        <Cell
                          key={`cell-${entry.title}-${index}`}
                          fill={
                            pieChartColors[index] ?? PIE_CHART_DEFAULT_COLOR
                          }
                        />
                      );
                    })}
                  </Pie>
                  {typeof activeIndex === "number" && (
                    <Tooltip
                      content={<PieChartToolTip />}
                      wrapperStyle={{ zIndex: 1 }}
                      allowEscapeViewBox={{ x: true, y: true }}
                    />
                  )}
                </PieChartDisplay>
              </ResponsiveContainer>
              {isUpdating && (
                <div className={"pie-chart-updating"}>
                  <Loading />
                </div>
              )}
            </div>
            <div className={"pie-chart-legend-wrapper"}>
              <div className={"pie-chart-legend"}>
                {chunkedData.map((dataSubset, colIndex) => {
                  return (
                    <div
                      className={
                        chunkedData.length > 1
                          ? "pie-chart-legend-col-wrapper"
                          : "pie-chart-legend-col-wrapper single-col"
                      }
                      key={colIndex}
                    >
                      {dataSubset.map((entry, index) => {
                        return (
                          <div
                            className={"pie-chart-legend-row"}
                            key={`${entry.title}-${index}`}
                          >
                            <div
                              className={"pie-chart-legend-row-color"}
                              style={{
                                backgroundColor:
                                  pieChartColors[
                                    index + colIndex * chunkSize
                                  ] ?? PIE_CHART_DEFAULT_COLOR,
                              }}
                            ></div>
                            <div className={"pie-chart-legend-row-text"}>
                              <span>{`${
                                entry.title
                                  ? Format.filterNamedPartners(entry.title)
                                  : "N/A"
                              }`}</span>
                              <b>
                                {entry?.metadata?.artist
                                  ? ` - ${entry.metadata.artist}`
                                  : ""}
                              </b>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={"pie-chart-centered-option"}>{NO_AVAILABLE_DATA}</div>
      )}
    </div>
  );
};

export default Card(PieChart);
