import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import DateRangeController from "../DateRangeController/DateRangeController";
import Timeline from "../Timeline/Timeline";
import IncrementController from "../IncrementController/IncrementController";
import TimelineScrollBar from "../TimelineScrollBar/TimelineScrollBar";
import usePrevious from "../UsePrevious/UsePrevious";
import Loading from "../Loading/Loading";
import Card from "../../containers/Card/Card";
import Format from "../../services/format";
import axios from "../../config/axios";
import "./dual-timelines.scss";

const ERROR_MESSAGE = "No data to display";

const INCREMENT_CONTROLLER_LABEL = "Timeline Zoom:";
const DEFAULT_TIMELINE_WIDTH_MULTIPLIER = 1;
const TIMELINE_WIDTH_INCREMENT_AMOUNT = 1;
// Set a limit to protect users from crashing the browser
// Each multiplier increases width by the original width
const MAX_TIMELINE_WIDTH_MULTIPLIER = 70;
const ZOOM_SCROLL_CENTER_ADJUSTMENT = 10;

const DualTimeline = (props) => {
  const history = useHistory();

  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);
  const [dateRange, setDateRange] = useState([]);
  const [dualTimelineData, setDualTimelineData] = useState([]);
  const [dualTimelineError, setDualTimelineError] = useState(false);
  const [retrievingData, setRetrievingData] = useState(false);
  const [selectedDay, setSelectedDay] = useState("");
  const prevSelectedDay = usePrevious(selectedDay);
  // only one sticky tooltip across all timelines
  const [stickyTooltipIndex, setStickyTooltipIndex] = useState(undefined);
  const [stickyTooltipActive, setStickyTooltipActive] = useState(false);
  const [stickyTooltipName, setStickyTooltipName] = useState("");
  // For handling timeline lengthening and scrolling modes
  const [timelineScrollLeft, setTimelineScrollLeft] = useState(0);
  const prevTimelineScrollLeft = usePrevious(timelineScrollLeft);
  const [timelineWidthMultiplier, setTimelineWidthMultiplier] = useState(
    DEFAULT_TIMELINE_WIDTH_MULTIPLIER
  );
  const prevTimelineWidthMultiplier = usePrevious(timelineWidthMultiplier);
  const [timelineScrollBarWidth, setTimelineScrollBarWidth] = useState(1);
  const setRenderTimeline = props.setRenderTimeline;

  const getTimelineData = useCallback(
    async (tableDate) => {
      setRetrievingData(true);
      setDualTimelineError(false);

      if (props.entityId !== null && props.entityId.length) {
        try {
          const res = await axios.post(
            "/get-entity-timeline",
            {
              entityType: props.entityType,
              entityID: props.entityId,
              provider: props.partnerName,
              tableDate,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("tk")}`,
              },
            }
          );

          const timelineData = res.data.results.data;
          const dateRangeData = res.data.results.dateRange;

          // If there is timeline data, show a timeline
          if (dateRangeData && timelineData) {
            setDateRange(dateRangeData);
            setDualTimelineData(timelineData);
          } else {
            setRenderTimeline(false);
          }
        } catch (error) {
          // If unauthenticated, redirect to login page
          if (error?.response?.status === 401) {
            history.push("/login");
          } else {
            setDualTimelineError(true);
          }
        }

        setRetrievingData(false);
      }
    },
    [
      props.entityType,
      props.entityId,
      props.partnerName,
      history,
      setRenderTimeline,
    ]
  );

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      const clearData = () => {
        setRetrievingData(true);
        setDualTimelineError(false);
        setDualTimelineData([]);
        setRenderTimeline(true);
        setSelectedDay("");
        setStickyTooltipIndex(undefined);
        setStickyTooltipActive(false);
        setStickyTooltipName("");
      };

      const fetchData = async () => {
        await getTimelineData(props.tableDate);
      };

      if (!props.pauseUpdates) {
        clearData();
        fetchData();
      }
    }

    return () => {
      abort = true;
    };
  }, [
    props.entityType,
    props.entityId,
    props.tableDate,
    getTimelineData,
    setRenderTimeline,
    props.pauseUpdates,
  ]);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      setStartDate(props.startDate);
      setEndDate(props.endDate);
    }

    return () => {
      abort = true;
    };
  }, [props.startDate, props.endDate]);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      // Center scroll on zoom in/out
      if (
        timelineScrollLeft > 0 &&
        prevTimelineWidthMultiplier !== timelineWidthMultiplier
      ) {
        if (prevTimelineWidthMultiplier < timelineWidthMultiplier) {
          setTimelineScrollLeft(
            timelineScrollLeft +
              ZOOM_SCROLL_CENTER_ADJUSTMENT +
              timelineScrollBarWidth / 2
          );
        } else {
          setTimelineScrollLeft(
            timelineScrollLeft -
              ZOOM_SCROLL_CENTER_ADJUSTMENT -
              timelineScrollBarWidth / 2
          );
        }
      }
    }

    return () => {
      abort = true;
    };
  }, [
    prevTimelineWidthMultiplier,
    timelineWidthMultiplier,
    timelineScrollLeft,
    timelineScrollBarWidth,
  ]);

  const handleDateRangeChange = async (startDate, endDate) => {
    await getTimelineData(startDate);

    setStartDate(startDate);
    setEndDate(endDate);
  };

  const generateDayRange = (dateRange) => {
    const daysArr = [{ label: "Full Week", field: "" }];

    for (let i = 0; i < dateRange.length; i++) {
      daysArr.push({
        label: Format.getUTCDayOfTheWeek(dateRange[i]),
        field: dateRange[i], // yyyy-MM-dd
      });
    }

    return daysArr;
  };

  const generateTimelines = (dualTimelineData) => {
    if (dualTimelineData?.length) {
      return dualTimelineData.map((timelineData, index) => {
        // Check if this is the timeline to put the scrollbar under
        // Defaults to last unless that timeline has no data
        const generateScrollBar =
          timelineData?.data?.length > 0 &&
          !dualTimelineData[index + 1]?.data?.length > 0;

        return (
          <React.Fragment key={timelineData.provider_name}>
            <Timeline
              startDate={startDate}
              endDate={endDate}
              entityId={props.entityId}
              partnerName={props.partnerName}
              timelineData={timelineData.data}
              timelineType={timelineData.provider_name}
              selectedDay={selectedDay}
              prevSelectedDay={prevSelectedDay}
              retrievingData={retrievingData}
              setStickyTooltipIndex={setStickyTooltipIndex}
              setStickyTooltipActive={setStickyTooltipActive}
              setStickyTooltipName={setStickyTooltipName}
              stickyTooltipIndex={stickyTooltipIndex}
              stickyTooltipName={stickyTooltipName}
              renderStickyTooltip={
                stickyTooltipActive &&
                stickyTooltipName === timelineData.provider_name
              }
              widthMultiplier={timelineWidthMultiplier}
              timelineScrollLeft={timelineScrollLeft}
              setTimelineScrollLeft={setTimelineScrollLeft}
              prevTimelineScrollLeft={prevTimelineScrollLeft}
              setScrollBarWidth={setTimelineScrollBarWidth}
            />
            {generateScrollBar && (
              <TimelineScrollBar
                width={timelineScrollBarWidth}
                leftPosition={timelineScrollLeft}
                setScroll={setTimelineScrollLeft}
                currentWidthMultiplier={timelineWidthMultiplier}
                visible={
                  timelineWidthMultiplier > DEFAULT_TIMELINE_WIDTH_MULTIPLIER
                }
              />
            )}
          </React.Fragment>
        );
      });
    }
  };

  return (
    <div className={"dual-timelines"}>
      {retrievingData && dualTimelineData.length === 0 && (
        <div className={"dual-timelines-loading"}>
          <Loading />
        </div>
      )}
      <div className={"legend-date-text-container"}>
        <DateRangeController
          dataWeeks={props.dataWeeks}
          startDate={startDate}
          endDate={endDate}
          handleDateRangeChange={handleDateRangeChange}
          useStyle={"secondary"}
        />
      </div>
      {!dualTimelineError &&
        (!retrievingData || dualTimelineData.length > 0) && (
          <>
            <div className={"legend-date-option-container"}>
              {generateDayRange(dateRange).map((day) => {
                return (
                  <div
                    className={
                      selectedDay === day.field
                        ? "legend-date-option selected"
                        : "legend-date-option"
                    }
                    key={`${day.label}-${day.field}`}
                    onClick={() => setSelectedDay(day.field)}
                  >
                    {day.label}
                  </div>
                );
              })}
            </div>
            <div className={"zoom-controller-container"}>
              <IncrementController
                label={INCREMENT_CONTROLLER_LABEL}
                value={timelineWidthMultiplier}
                setValue={setTimelineWidthMultiplier}
                maxValue={MAX_TIMELINE_WIDTH_MULTIPLIER}
                minValue={DEFAULT_TIMELINE_WIDTH_MULTIPLIER}
                defaultValue={DEFAULT_TIMELINE_WIDTH_MULTIPLIER}
                incrementAmount={TIMELINE_WIDTH_INCREMENT_AMOUNT}
              />
            </div>
          </>
        )}
      {dualTimelineError ? (
        <div className={"error-message"}>
          <span>{ERROR_MESSAGE}</span>
        </div>
      ) : (
        <div className={"dual-timelines-timeline-container"}>
          {generateTimelines(dualTimelineData)}
        </div>
      )}
    </div>
  );
};

export default Card(DualTimeline);
