import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoute/PrivateRoute";

// misc. views
import Login from "../views/Login/Login";
import NotFound from "../views/NotFound/NotFound";

// async views
import User from "../views/User/User";
import Artist from "../views/Artist/Artist";
import Song from "../views/Song/Song";

import UserProfile from "../views/UserProfile/UserProfile";
import SongProfile from "../views/SongProfile/SongProfile";
import ArtistProfile from "../views/ArtistProfile/ArtistProfile";
import LabelHistory from "../views/LabelHistory/LabelHistory";

/**
 * Handles application routes.
 */
const Router = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute exact path={"/label/user"} {...props} component={User} />
        <PrivateRoute
          exact
          path={"/label/artist"}
          {...props}
          component={Artist}
        />
        <PrivateRoute exact path={"/label/song"} {...props} component={Song} />
        <PrivateRoute
          exact
          path={"/:provider/song/:formatted_id/:table_date"}
          {...props}
          component={SongProfile}
        />
        <PrivateRoute
          exact
          path={"/:provider/user/:id/:table_date"}
          {...props}
          component={UserProfile}
        />
        <PrivateRoute
          exact
          path={"/:provider/artist/:id/:table_date"}
          {...props}
          component={ArtistProfile}
        />
        <PrivateRoute exact path={"/history"} component={LabelHistory} />
        <Route path={"/login"} component={Login} />
        <Redirect from={"/"} to={"/login"} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
