import axios from "../config/axios";
class Util {
  /**
   * Randomly selects an entity ID given an entity type, the weeks of data available for that ID,
   * and a random week from that list.
   * @param {String} entityType the entity type to query for
   * @param {String} partnerName the partner to query data from
   * @returns object containing entityId, tableDate, startDate, endDate | null
   */
  static getRandomEntity = async (entityType, partnerName) => {
    try {
      let options = {
        entityType,
        provider: partnerName,
      };

      const randomEntityRes = await axios.post("/get-random-entity", options, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tk")}`,
        },
      });

      // return entity with preferred date if available
      if (randomEntityRes.data.results.preferredDate) {
        return {
          entityId: decodeURIComponent(randomEntityRes.data.results.entityID),
          metadata: randomEntityRes.data.results?.metadata,
          tableDate: randomEntityRes.data.results.preferredDate,
        };
      }

      // otherwise get random date from available options
      const dataWeeks = randomEntityRes.data.results.validDates.map(
        (startDate) => {
          const range = this.getTableDateRange(startDate);

          return {
            startDate: range.start,
            endDate: range.end,
          };
        }
      );

      // get random date range
      const randomWeek =
        dataWeeks[Math.floor(Math.random() * dataWeeks.length)];

      return {
        entityId: decodeURIComponent(randomEntityRes.data.results.entityID),
        metadata: randomEntityRes.data.results?.metadata,
        tableDate: randomWeek.startDate,
      };
    } catch (error) {
      return null;
    }
  };

  /**
   * Retrieves a list of valid date ranges to retrieve data for given an entity type and entity ID.
   * @param {String} entityType the entity type to query for
   * @param {String} entityId the entity ID to query for
   * @param {String} partnerName the partner to query data from
   * @returns array of valid date range objects containing startDate and endDate
   */
  static getEntityDataWeeks = async (entityType, entityId, partnerName) => {
    try {
      let options = {
        entityID: entityId,
        entityType,
        provider: partnerName,
      };

      const tableDates = await axios.post("/get-entity-table-dates", options, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tk")}`,
        },
      });

      const dataWeeks = tableDates.data.results.map((startDate) => {
        const range = this.getTableDateRange(startDate);

        return {
          startDate: range.start,
          endDate: range.end,
        };
      });

      return dataWeeks;
    } catch (error) {
      return [];
    }
  };

  /**
   * Returns the start date and end date of a data date range given the
   * range table date.
   * @param {String} tableDate table date in the format yyyy-MM-dd
   * @returns object containing the start and end values of the date range
   */
  static getTableDateRange = (tableDate) => {
    try {
      // assume that date ranges are consistent:
      // table date is always the start date of the range
      // end date is always 6 days after the start date
      const date = new Date(tableDate);
      const endDate = new Date(date.setDate(date.getDate() + 6));

      // format end date
      const formattedEndDate = endDate.toISOString().split("T")[0];

      return {
        start: tableDate,
        end: formattedEndDate,
      };
    } catch (error) {
      return {
        start: "",
        end: "",
      };
    }
  };

  /**
   * Retrieves metadata associated with the given entity ID.
   * @param {String} entityType the entity type to query for
   * @param {String} entityId the entity id to query for
   * @param {String} tableDate date of the data to query for
   * @param {String} partnerName the partner to query data from
   * @returns object containing metadata for the given entity ID
   */
  static getEntityMetadata = async (
    entityType,
    entityId,
    tableDate,
    partnerName
  ) => {
    let metadata = {};

    try {
      const response = await axios.post(
        "/get-metadata",
        {
          entityType,
          entityID: entityId,
          tableDate,
          provider: partnerName,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tk")}`,
          },
        }
      );

      metadata = response.data.results;
    } catch (error) {
      // do nothing
    }

    return metadata;
  };

  /**
   * Composes href to Spotify search url for an artist.
   * @param {String} artistId the artist ID to search for
   * @param {String} tableDate date of the data to query the artist name from
   * @returns string href searching for the entity on Spotify
   */
  static spotifySearchArtistHref = async (artistId, tableDate) => {
    try {
      const spotifySearchLink = "https://open.spotify.com/search/";

      // Get artist name via ID
      const metadata = await this.getEntityMetadata(
        "artist",
        artistId,
        tableDate
      );
      const artistName = metadata?.artist_name;

      if (typeof artistName === "string") {
        // Clean url as much as possible
        return encodeURI(
          `${spotifySearchLink}${artistName.toLowerCase()}/artists`
        );
      } else {
        return "";
      }
    } catch {
      return "";
    }
  };

  /**
   * Catches as may use cases as possible where the page should load,
   * without breaking the History page's links' delayed loading.
   * @returns boolean
   */
  static profilePageShouldLoad = () => {
    try {
      const navTypeArr = window.performance
        .getEntriesByType("navigation")
        .map((nav) => nav.type);

      return (
        document.hasFocus() ||
        document.visibilityState === "visible" ||
        // "navigate" is the nav type when opening links via the History page
        (navTypeArr.length > 0 && !navTypeArr.includes("navigate"))
      );
    } catch {
      return false;
    }
  };
}

export default Util;
