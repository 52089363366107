import React, { Component } from "react";
import DashboardView from "../../containers/DashboardView/DashboardView";
import "./not-found.scss";

class NotFound extends Component {
  render() {
    return (
      <div className={"not-found-page"}>
        <div className={"not-found-404"}>404</div>
        <div className={"not-found-text"}>Page Not Found</div>
      </div>
    );
  }
}

export default DashboardView(NotFound);
