import React from "react";
import SelectionTextBox from "../SelectionTextBox/SelectionTextBox";
import "./selection-slider.scss";

const SelectionSlider = ({
  options = [], // Array of strings for options to select from
  selectedOption, // String of the selected option
  colors = [], // Array of colors for each option, optional
  onChange = () => {}, // Callback function to run when the selected option is changed
  maxOptionWidth = 0, // Maximum width of each option, in pixels, optional
  renderTextbox = false, // Boolean to determine whether to render a textbox
  visibleTextbox = false, // Boolean to determine whether to that textbox is visible
  placeholderText = { placeholder: "", required: false }, // Object, the placeholder text and required boolean for the text box
  textboxContent = "", // String, the content of the textbox, if rendered
  setTextboxContent = () => {}, // setState callback function to run to update the text
}) => {
  const optionBoxWidthPercentage = 100 / options.length;
  const optionBoxWidth = maxOptionWidth
    ? maxOptionWidth
    : optionBoxWidthPercentage;
  const percentOrPx = maxOptionWidth ? "px" : "%";

  const handleOptionClick = (option) => {
    onChange(option);
  };

  const generateOptionBoxes = (options) => {
    return options.map((option, index) => {
      return (
        <div
          className={"selection-slider-option"}
          key={`${option}-${index}`}
          style={{
            width: `${optionBoxWidth}${percentOrPx}`,
            backgroundColor: colors[index],
          }}
          onClick={() => handleOptionClick(option, index)}
        >
          <div
            className={`selection-slider-option-text${
              selectedOption === option ? " selected" : ""
            }`}
          >
            {option}
          </div>
        </div>
      );
    });
  };

  return (
    <div className={"selection-slider-container"}>
      <div
        className={`selection-slider${renderTextbox ? " with-textbox" : ""}`}
      >
        {generateOptionBoxes(options)}
        <div
          className={"selection-slider-selected-box"}
          style={{
            width: `${optionBoxWidth}${percentOrPx}`,
            left: `${
              options.indexOf(selectedOption) * optionBoxWidth
            }${percentOrPx}`,
          }}
        />
      </div>
      {renderTextbox && (
        <SelectionTextBox
          visible={visibleTextbox}
          selectedOption={selectedOption}
          placeholderText={placeholderText.placeholder}
          textRequired={placeholderText.required}
          setTextContent={setTextboxContent}
          textContent={textboxContent}
        />
      )}
    </div>
  );
};

export default SelectionSlider;
