import React, { useEffect, useState, useRef } from "react";
import "./slider.scss";

const INTERVAL_STEP = 0.5;
const INTERVAL_DURATION = 1;

const Slider = (props) => {
  const [sliderValue, setSliderValue] = useState(0);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      // On dismounting, set mounted to false
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      if (!props.percentile) return;

      if (sliderValue < props.percentile) {
        setTimeout(() => {
          if (mounted.current) setSliderValue((prev) => prev + INTERVAL_STEP);
        }, INTERVAL_DURATION);
      }
    }

    return () => {
      abort = true;
    };
  }, [props.percentile, sliderValue]);

  return (
    <span className={"slide-container"}>
      {props.percentile === null || props.percentile === undefined ? (
        <p>No percentile data to display</p>
      ) : (
        <input
          className={"slider"}
          type={"range"}
          min={1}
          max={100}
          step={0.1}
          value={sliderValue}
          readOnly
          onMouseEnter={(e) => {
            if (mounted.current) props.handleMouseEnter(e);
          }}
          onMouseLeave={() => {
            if (mounted.current) props.handleMouseLeave(false);
          }}
        />
      )}
    </span>
  );
};

export default Slider;
