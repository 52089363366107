import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import MetricCard from "../../components/MetricCard/MetricCard";
import PieChart from "../../components/PieChart/PieChart";
import Util from "../../services/util";
import Auth from "../../services/auth";
import Format from "../../services/format";
import axios from "../../config/axios";
import usePrevious from "../../components/UsePrevious/UsePrevious";

import METRIC_LIMITS from "../../config/limits";

const PIE_CHART_DEFAULT_FIELD = "default";

/**
 * Hook used to track many shared state variables for the decision boxes
 * As well as shared functions
 */
const UseProfile = ({
  ogEntityId,
  entityType,
  tableDateFromUrl,
  generateChangedUrl,
  providerNumFromUrl,
}) => {
  const history = useHistory();

  // set start date and end date
  let initialStartDate,
    initialEndDate = "";

  const dateRange = Util.getTableDateRange(
    tableDateFromUrl ||
      localStorage.getItem(`${providerNumFromUrl}tableDate`) ||
      undefined
  );

  initialStartDate = dateRange.start;
  initialEndDate = dateRange.end;

  const [entityId, setEntityId] = useState(decodeURIComponent(ogEntityId));
  const [startDate, setStartDate] = useState(initialStartDate);
  const [resettingPage, setResettingPage] = useState(false);
  const [updatingPage, setUpdatingPage] = useState(false);
  const [changingDataSource, setChangingDataSource] = useState(false);
  const prevEntityId = usePrevious(entityId);

  const [endDate, setEndDate] = useState(initialEndDate);
  const [dataWeeks, setDataWeeks] = useState([]);
  const [partnerName, setPartnerName] = useState(
    Format.getProviderByNumber(providerNumFromUrl)
  );
  const [metricCardData, setMetricCardData] = useState([]);
  const [selectedFraudReasons, setSelectedFraudReasons] = useState([]);
  const [loadPage, setLoadPage] = useState(Util.profilePageShouldLoad());
  const [userComparisonModalVisible, setUserComparisonModalVisible] =
    useState(false);
  const [userComparisonModalData, setUserComparisonModalData] = useState({});
  const [linkedUsers, setLinkedUsers] = useState([]);
  const [susPlaysPercent, setSusPlaysPercent] = useState();
  const [pieChartsData, setPieChartsData] = useState([]);
  const [originalPieChartData, setOriginalPieChartData] = useState([]);
  const [pieChartDeviceTypes, setPieChartDeviceTypes] = useState({});
  const [pieChartsLoading, setPieChartsLoading] = useState(true);

  /**
   * Resets the profile page, and updates the URL.
   * Only works for entity types that use the standard URL format (user and artist, not song)
   */
  const resetPage = useCallback(async () => {
    let newEntityId, tableDate, metadata, newFormattedEntityId;
    setResettingPage(true);

    // get new random entityType id and session variables
    // also confirm user is authenticated
    try {
      const results = await Util.getRandomEntity(entityType, partnerName);
      newEntityId = results.entityId;
      tableDate = results.tableDate;
      // Only relevant for song pages
      metadata = results.metadata;
    } catch (error) {
      history.push("/login");
    }

    const partnerNum = Format.getNumberByProvider(partnerName);
    const localEntityId = localStorage.getItem(`${partnerNum}${entityType}Id`);
    const localTableDate = localStorage.getItem(
      `${partnerNum}${entityType}TableDate`
    );

    // use local storage variables only if both are available
    if (localEntityId && localTableDate) {
      newEntityId = localEntityId;
      tableDate = localTableDate;
    }

    // set formatted entity id for song URLs
    if (entityType === "song") {
      metadata = await Util.getEntityMetadata(
        entityType,
        newEntityId,
        tableDate,
        partnerName
      );
      newFormattedEntityId = Format.formatSongUrl(
        metadata?.track_title,
        metadata?.track_artist
      );
    }

    // Check that we have valid values before updating
    if (newEntityId && tableDate) {
      // reassign local storage variables
      localStorage.setItem(`${partnerNum}${entityType}Id`, newEntityId);
      localStorage.setItem("entityType", entityType);
      localStorage.setItem(`${partnerNum}${entityType}TableDate`, tableDate);
      setEntityId(newEntityId);
      setStartDate(tableDate);

      // reload page with new params
      history.push({
        pathname: Format.composeProfileUrlPath(
          partnerName,
          entityType,
          newEntityId,
          tableDate,
          newFormattedEntityId
        ),
      });
    }
    setResettingPage(false);
    window.scrollTo(0, 0);
  }, [history, entityType, partnerName]);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort && !loadPage) {
      // Add onFocus listener
      const loadOnFocus = () => {
        setLoadPage(true);
        // Remove listener afterwards
        window.removeEventListener("focus", loadOnFocus);
      };
      window.addEventListener("focus", loadOnFocus);
    }

    return () => {
      abort = true;
    };
  });

  useEffect(() => {
    let abort = false;

    if (
      loadPage &&
      !abort &&
      (entityId === undefined || entityId === null || startDate === undefined)
    ) {
      resetPage();
    }

    return () => {
      abort = true;
    };
  }, [entityId, startDate, loadPage, resetPage]);

  useEffect(() => {
    let abort = false;

    const getDataWeeks = async () => {
      setDataWeeks(
        await Util.getEntityDataWeeks(entityType, entityId, partnerName)
      );
    };

    const updateState = async () => {
      // Check authentication is still valid
      const authenticated = await Auth.checkUserAuthenticated();
      if (!authenticated) {
        history.push("/login");
      } else {
        if (!changingDataSource) {
          setUpdatingPage(true);
          const dateRange = Util.getTableDateRange(
            startDate ? startDate : tableDateFromUrl
          );

          // Only update if there are dates
          if (dateRange.start && dateRange.end) {
            setStartDate(dateRange.start);
            setEndDate(dateRange.end);
          }
        }

        // set available data weeks
        if (entityId) await getDataWeeks();

        setUpdatingPage(false);
      }
    };

    // Run after tab has been in focus
    if (
      loadPage &&
      !abort &&
      !resettingPage &&
      !updatingPage &&
      prevEntityId !== entityId /* prevTableDateFromUrl !== tableDateFromUrl */
    ) {
      updateState();
    }

    return () => {
      abort = true;
    };
  }, [
    entityId,
    entityType,
    tableDateFromUrl,
    loadPage,
    history,
    initialStartDate,
    prevEntityId,
    resettingPage,
    updatingPage,
    startDate,
    partnerName,
    changingDataSource,
  ]);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      // Reset the fraud reasons if the ID changes
      // But persist through date week changing
      setSelectedFraudReasons([]);
    }

    return () => {
      abort = true;
    };
  }, [entityId]);

  useEffect(() => {
    let abort = false;

    async function getMetricCardData() {
      try {
        if (!changingDataSource) {
          const res = await axios.post(
            "/get-metric-cards",
            {
              entityType: entityType,
              entityID: entityId,
              tableDate: startDate,
              provider: partnerName,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("tk")}`,
              },
            }
          );

          setMetricCardData(res.data.results);
        }
      } catch (err) {
        // do nothing
      }
    }

    const getEntityPieCharts = async (entityId, tableDate, partnerName) => {
      if (entityId !== null && entityId?.length) {
        setPieChartsLoading(true);
        try {
          if (!changingDataSource) {
            const res = await axios.post(
              "/get-pie-chart",
              {
                entityType: entityType,
                entityID: entityId,
                tableDate: tableDate,
                provider: partnerName,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("tk")}`,
                },
              }
            );

            // Populate with data from the API
            const results = res.data.results;
            setPieChartsData(results);
            setOriginalPieChartData(results);
            setPieChartDeviceTypes(res.data.devices);
          }
        } catch (error) {
          setPieChartsData([]);
        }
        // Either way, set the pie charts loading state to false
        setPieChartsLoading(false);
      }
    };

    /**
     * Populate UserComparisonModal data in the profile page
     * for the User and Artist profile pages.
     * @param {String} entityId the entity ID to retrieve data for
     * @param {String} tableDate date to retrieve the data for in format (yyyy-MM-dd)
     * @param {String} partnerName the provider to ping data from
     */
    const getUserComparisonModalData = async (
      entityId,
      tableDate,
      partnerName
    ) => {
      if (entityId !== null && entityId?.length) {
        try {
          // TODO: Update API to use the same endpoint for all entity types?
          if (!changingDataSource) {
            const res = await axios.post(
              entityType === "user" ? "/get-similar-user-metrics" : "/get-sus",
              {
                entityType: entityType,
                userID: entityId, // necessary for User Profile
                entityID: entityId,
                tableDate: tableDate,
                provider: partnerName,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("tk")}`,
                },
              }
            );

            // Populate with data from the API
            setUserComparisonModalData(res.data.results);
          }
        } catch (error) {
          // Set to null as to not display the modal
          setUserComparisonModalData(null);
        }
      }
    };

    // Run after tab has been in focus
    if (loadPage && !abort && startDate && !changingDataSource) {
      getEntityPieCharts(entityId, startDate, partnerName);
      if (entityType !== "song") {
        getUserComparisonModalData(entityId, startDate, partnerName);
        // TODO: Move out of if statement once metric cards are live
        getMetricCardData();
      }
    }

    return () => {
      abort = true;
    };
  }, [
    entityId,
    entityType,
    startDate,
    loadPage,
    partnerName,
    changingDataSource,
  ]);

  /**
   * Generates metric cards
   * @param {Array<Object>} cardData array of objects containing card data
   * @param {Object} cardFraudReasons state tracked reasons for suspected fraud for the page
   */
  const generateMetricCards = (cardData, cardFraudReasons) => {
    if (cardData.length < 1) return null;

    return cardData.map((card, i) => {
      const shouldRenderCheckbox = cardFraudReasons.metricCards[card.key];

      if (shouldRenderCheckbox) {
        return (
          <Grid item xs={12} sm={4} key={card?.title || i}>
            <MetricCard
              score={card?.score}
              scoreLimits={METRIC_LIMITS[card.key] ?? null}
              title={card?.title.replace(/napster/gi, "DSP") || "Loading..."}
              percentile={card?.percentile}
              zScore={card?.zScore}
              displaySecondaryMetrics={true}
              cardFraudReasons={cardFraudReasons.metricCards[card.key]}
              checkFraudReasonSelected={checkFraudReasonSelected}
              editSelectedFraudReasons={editSelectedFraudReasons}
            />
          </Grid>
        );
      } else {
        return (
          <Grid item xs={12} sm={4} key={card?.title || i}>
            <MetricCard
              score={card?.score}
              scoreLimits={METRIC_LIMITS[card.key] ?? null}
              title={card?.title.replace(/napster/gi, "DSP") || "Loading..."}
              percentile={card?.percentile}
              zScore={card?.zScore}
              displaySecondaryMetrics={true}
            />
          </Grid>
        );
      }
    });
  };

  const handleDateRangeChange = (startDate, endDate) => {
    // update page URL
    history.replace({
      pathname: generateChangedUrl
        ? generateChangedUrl(startDate, entityId)
        : Format.composeProfileUrlPath(
            partnerName,
            entityType,
            entityId,
            startDate
          ),
    });

    // update date state vars
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handlePartnerChange = (newPartner) => {
    // TODO: Add more logic if necessary
    setPartnerName(newPartner);
  };

  const checkFraudReasonSelected = (fraudReasonId) =>
    // Checks if a reason is currently selected.
    selectedFraudReasons.some(
      (reasonObj) => reasonObj.reason === fraudReasonId
    );

  const editSelectedFraudReasons = (fraudReasonId, text = false) => {
    // Updates selectedFraudReasons state array, adding or removing a fraud reason from selection.
    setSelectedFraudReasons((prevSelectedFraudReasons) => {
      let newSelectedFraudReasons = [...prevSelectedFraudReasons];
      const reasonAlreadySelected = checkFraudReasonSelected(fraudReasonId);
      if (reasonAlreadySelected) {
        // remove reason from the array if it already exists
        newSelectedFraudReasons = newSelectedFraudReasons.filter(
          (reasonObj) => reasonObj.reason !== fraudReasonId
        );
      }

      if (!reasonAlreadySelected || text !== false) {
        const newReasonObj = {
          reason: fraudReasonId,
        };

        // add text if the reason has an associated textarea
        if (text) newReasonObj.text = text;

        // insert if reason wasn't already present
        // or reinsert with the new text area value if there is one
        newSelectedFraudReasons = newSelectedFraudReasons.concat(newReasonObj);
      }

      return newSelectedFraudReasons;
    });
  };

  const editLinkedUsers = (linkedUser) => {
    setLinkedUsers((prevLinkedUsers) => {
      // update linkedUsers state array, either inserting or deleting an entry
      const newLinkedUsers = [...prevLinkedUsers];
      if (!newLinkedUsers.includes(linkedUser)) {
        newLinkedUsers.push(linkedUser);
      } else {
        const userIndex = newLinkedUsers.indexOf(linkedUser);
        newLinkedUsers.splice(userIndex, 1);
      }

      return newLinkedUsers;
    });
  };

  const handlePieChartDropdownSelect = async (chartId, newOption) => {
    try {
      const foundChartIndex = pieChartsData.findIndex(
        (chart) => chart.id === chartId
      );

      if (foundChartIndex < 0) {
        return;
      }

      if (newOption === PIE_CHART_DEFAULT_FIELD) {
        return [originalPieChartData[foundChartIndex].scores];
      } else {
        // Get device type specific data
        try {
          if (!changingDataSource) {
            const res = await axios.post(
              "/get-pie-chart-by-device",
              {
                entityType,
                entityID: entityId,
                tableDate: startDate,
                pieID: chartId,
                deviceType: newOption,
                provider: partnerName,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("tk")}`,
                },
              }
            );

            return res.data.results.scores;
          }
        } catch (error) {
          // do nothing
        }
      }
    } catch (error) {
      // do nothing
    }
  };

  /**
   * Generates the pie chart with the specified variables/props values
   * Dependant on the title provided and the population of the PieChartsData array in state
   * @param {String} chartId The id of the pie chart
   * @param {String} title The title of the pie chart
   * @param {String} layout Whether the layout of the pie chart and legend is horizontal or vertical
   * @param {Array<Object>} cardFraudReasons state tracked reasons for suspected fraud linked to the pie chart
   * @param {Number} minHeight The minimum height of the pie chart component in pixels
   * @param {String} tipText The text to display in the tooltip, optional
   * @param {Number} textTipWidth The width of the tooltip in pixels, optional
   */
  const generatePieChart = ({
    chartId,
    title,
    layout,
    cardFraudReasons,
    minHeight,
    deviceTypeSource,
    tipText = null,
    textTipWidth = null,
  }) => {
    const pieChartData = pieChartsData?.find((data) => data.title === title);

    let dropdownOptions = [];
    if (deviceTypeSource && pieChartDeviceTypes[deviceTypeSource]?.length) {
      dropdownOptions = pieChartDeviceTypes[deviceTypeSource];

      // sort dropdown options and convert into workable format
      dropdownOptions = dropdownOptions.sort().map((option) => {
        return { label: option, field: option };
      });
      dropdownOptions.unshift({
        label: "Show all",
        field: PIE_CHART_DEFAULT_FIELD,
      });
    }

    return (
      <PieChart
        key={title}
        chartId={chartId}
        title={title}
        data={pieChartsLoading ? undefined : pieChartData?.scores || []}
        dropdownOptions={dropdownOptions}
        dropdownDefaultOption={PIE_CHART_DEFAULT_FIELD}
        handleDropdownSelect={handlePieChartDropdownSelect}
        minHeight={minHeight}
        layout={layout}
        tipText={tipText}
        textTipWidth={textTipWidth}
        cardFraudReasons={cardFraudReasons}
        checkFraudReasonSelected={checkFraudReasonSelected}
        editSelectedFraudReasons={editSelectedFraudReasons}
      />
    );
  };

  return {
    changingDataSource,
    checkFraudReasonSelected,
    dataWeeks,
    editLinkedUsers,
    editSelectedFraudReasons,
    endDate,
    entityId,
    generateMetricCards,
    generatePieChart,
    handleDateRangeChange,
    handlePartnerChange,
    linkedUsers,
    loadPage,
    metricCardData,
    partnerName,
    pieChartsData,
    resetPage,
    selectedFraudReasons,
    setChangingDataSource,
    setSelectedFraudReasons,
    setSusPlaysPercent,
    setUserComparisonModalVisible,
    startDate,
    susPlaysPercent,
    userComparisonModalData,
    userComparisonModalVisible,
  };
};

export default UseProfile;
