import React, { useState, useEffect, useCallback } from "react";
import { Redirect, Route } from "react-router-dom";
import Auth from "../../services/auth";

const PrivateRoute = (props) => {
  const { component: Component, ...rest } = props;

  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const updateState = useCallback(async () => {
    const nowAuthenticated = await Auth.checkUserAuthenticated();
    setAuthenticated(nowAuthenticated);
    setLoading(false);
  }, []);

  // Update state
  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      setLoading(true);
      updateState();
    }

    return () => {
      abort = true;
    };
  }, [updateState]);

  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated ? (
          <Component {...props} />
        ) : (
          !loading && (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  location: props.location,
                  reason: "credsExpired",
                  prevPathname:
                    window.location.pathname + window.location.search,
                },
              }}
            />
          )
        )
      }
    />
  );
};

export default PrivateRoute;
