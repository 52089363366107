// For the submit form
export const OTHER_REASON = "Other";
export const SECOND_REVIEW_REQUEST = "Request a second review";

export const SUBMIT_FORM_REASONS = {
  shared: [
    { reason: { label: OTHER_REASON, id: OTHER_REASON }, addTextArea: true },
  ],
  preSubmit: [
    {
      reason: { label: SECOND_REVIEW_REQUEST, id: SECOND_REVIEW_REQUEST },
      tooltip:
        "Requesting a second review helps track difficult cases and sends a notification to others for assistance.",
    },
  ],
};
