import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Loading from "../Loading/Loading";
import Colors from "../../services/colors";
import Format from "../../services/format";
import DrawSvgs from "../../services/draw_svgs";
import Card from "../../containers/Card/Card";
import DateRangeController from "../DateRangeController/DateRangeController";
import ChartTooltip from "../ChartTooltip/ChartTooltip";
import axios from "../../config/axios";

import {
  AreaChart as StackedChart,
  YAxis,
  XAxis,
  Area,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
} from "recharts";
import "./stacked-area-chart.scss";

const STACKED_AREA_CHART_TITLE = "Play Times (24h)";
const X_AXIS_LABEL = "Time of Day (24h)";
const Y_AXIS_LABEL = "Play Count";
const X_AXIS_DATA_KEY = "hour";
const AREA_FILL_OPACITY = "40%";
const SQUARE_ICON_SIZE = 20;
const SQUARE_ICON_OPACITY = "50%";
const ERROR_MESSAGE = "No data to display";
const TOOL_TIP_TOTAL = "Total Plays";
const TOOL_TIP_AVERAGE = "Daily Average";

const AXIS_LABEL_COLOR = "#4e4a54"; // $color-neutral-grey-3 in 'constants'
const AXIS_LABEL_FONT_SIZE = "14px";

const StackedAreaChart = (props) => {
  const history = useHistory();

  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);
  const [stackedChartData, setStackedChartData] = useState([]);
  const [stackedChartError, setStackedChartError] = useState(false);
  const [loading, setLoading] = React.useState(true);

  const getStackedChartData = useCallback(
    async (tableDate) => {
      if (props.entityId) {
        setLoading(true);

        try {
          const res = await axios.post(
            "/get-stacked-chart",
            {
              entityType: props.entityType,
              entityID: props.entityId,
              tableDate,
              provider: props.partnerName,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("tk")}`,
              },
            }
          );

          const data = res.data.results;

          setStackedChartData(data);
        } catch (error) {
          // If unauthenticated, redirect to login page
          if (error.response.status === 401) {
            history.push("/login");
          } else {
            setStackedChartError(true);
            setStackedChartData([]);
          }
        } finally {
          setLoading(false);
        }
      }
    },
    [props.entityType, props.entityId, props.partnerName, history]
  );

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort && !props.pauseUpdates) {
      const fetchData = async () => {
        await getStackedChartData(props.tableDate);
      };

      // reset state first
      setStackedChartData([]);
      setStackedChartError(false);

      fetchData();
    }

    return () => {
      abort = true;
    };
  }, [
    props.entityType,
    props.entityId,
    props.tableDate,
    props.pauseUpdates,
    getStackedChartData,
  ]);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      setStartDate(props.startDate);
      setEndDate(props.endDate);
    }

    return () => {
      abort = true;
    };
  }, [props.startDate, props.endDate]);

  const handleDateRangeChange = async (startDate, endDate) => {
    await getStackedChartData(startDate);

    setStartDate(startDate);
    setEndDate(endDate);
  };

  const generateAreas = (data) => {
    if (data?.length && typeof data[0] === "object") {
      const dates = Object.keys(data[0]).filter((key) => key !== "hour");
      const colors = Colors.getDefaultColors();

      return dates.map((date, index) => {
        const color = colors[index];

        return (
          <Area
            className={"stacked-chart-area"}
            key={`stacked-chart-${date}`}
            stackId={"1"}
            dataKey={date}
            stroke={color}
            fill={color}
            fillOpacity={AREA_FILL_OPACITY}
          />
        );
      });
    }
  };

  const renderLegend = (data) => {
    const { payload } = data;
    return (
      <>
        <DateRangeController
          dataWeeks={props.dataWeeks}
          startDate={startDate}
          endDate={endDate}
          handleDateRangeChange={handleDateRangeChange}
          useStyle={"default"}
        />
        <ul className={"legend-list"}>
          {payload.map((entry) => (
            <li key={`item-${entry.value}`} className={"legend-row"}>
              {DrawSvgs.drawSquareSvg(SQUARE_ICON_SIZE, entry.color)}
              {Format.formatDate(entry.value)}
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <div className={"stacked-chart-container"}>
      <span className={"stacked-chart-title"}>{STACKED_AREA_CHART_TITLE}</span>
      {loading ? (
        <Loading className={"stacked-chart-loader"} />
      ) : stackedChartError ? (
        <div className={"error-message"}>
          <span>{ERROR_MESSAGE}</span>
        </div>
      ) : (
        !stackedChartError && (
          <ResponsiveContainer width={"100%"} height={"100%"}>
            <StackedChart
              data={stackedChartData}
              margin={{
                top: 10,
                right: 20,
                bottom: 20,
                left: 5,
              }}
            >
              <XAxis
                dataKey={X_AXIS_DATA_KEY}
                interval={"preserveStartEnd"}
                style={{
                  fontSize: AXIS_LABEL_FONT_SIZE,
                }}
              >
                <Label
                  value={X_AXIS_LABEL}
                  position={"bottom"}
                  style={{
                    fontSize: AXIS_LABEL_FONT_SIZE,
                    fill: AXIS_LABEL_COLOR,
                  }}
                />
              </XAxis>
              <YAxis
                style={{
                  fontSize: AXIS_LABEL_FONT_SIZE,
                }}
              >
                <Label
                  value={Y_AXIS_LABEL}
                  position={"insideLeft"}
                  angle={-90}
                  style={{
                    fontSize: AXIS_LABEL_FONT_SIZE,
                    fill: AXIS_LABEL_COLOR,
                  }}
                />
              </YAxis>
              {generateAreas(stackedChartData)}
              <Tooltip
                content={
                  <ChartTooltip
                    rowLabelFormatter={Format.formatDate}
                    squareIconSize={SQUARE_ICON_SIZE}
                    chartType={"StackedArea"}
                    labelSuffix={":00"}
                    totalLabel={TOOL_TIP_TOTAL}
                    averageLabel={TOOL_TIP_AVERAGE}
                    squareIconOpacity={SQUARE_ICON_OPACITY}
                  />
                }
                allowEscapeViewBox={{ x: true, y: true }}
                position={{ y: 0 }}
              />
              <Legend
                className={"stacked-chart-legend"}
                allowEscapeViewBox={{ x: false, y: false }}
                verticalAlign={"top"}
                align={"right"}
                layout={"vertical"}
                iconType={"square"}
                iconSize={SQUARE_ICON_SIZE}
                wrapperStyle={{ right: "-5px", top: "-5px" }}
                content={renderLegend}
              />
            </StackedChart>
          </ResponsiveContainer>
        )
      )}
    </div>
  );
};

export default Card(StackedAreaChart);
