import React, { useState, useEffect, useCallback } from "react";
import "./timeline-scroll-bar.scss";

const SCROLL_THUMB_MIN_WIDTH_PX = 40;
const SCROLL_THUMB_MAX_WIDTH_PX = 200;
const SCROLL_WIDTH_DECREMENT_PX = 10;

const EXAMPLE_LEFT_PX_FOR_RATIO_CALCUlATION = 34;
const DEFAULT_SCROLL_RATIO = 1;

const TimelineScrollBar = (props) => {
  const [scrollThumbWidth, setScrollThumbWidth] = useState(
    SCROLL_THUMB_MAX_WIDTH_PX + SCROLL_WIDTH_DECREMENT_PX
  );
  const [currentScrollRatio, setCurrentScrollRatio] =
    useState(DEFAULT_SCROLL_RATIO);

  const calculateMaxLeft = useCallback(() => {
    // The timeline doesn't need to scroll to 100% of its full width
    // Which must be factored into the maximum scroll amount
    const maxPercentageOfScroll = 100 - 100 / props.currentWidthMultiplier;
    return Math.ceil(
      (props.width * props.currentWidthMultiplier * maxPercentageOfScroll) / 100
    );
  }, [props.currentWidthMultiplier, props.width]);

  const generateLeft = useCallback(
    (containerLeftValue) => {
      // Calculates the new left value of the scroll bar
      // Based on the current left value of the (bigger) container it's scrolling
      if (typeof containerLeftValue === "number" && containerLeftValue > 0) {
        const fullWidth = calculateMaxLeft();
        const percentageOfFullWidth = (100 / fullWidth) * containerLeftValue;
        const adjustedWidth = props.width - scrollThumbWidth;
        const pxLeft = (adjustedWidth * percentageOfFullWidth) / 100;
        return Math.ceil(pxLeft);
      } else {
        return 0;
      }
    },
    [calculateMaxLeft, props.width, scrollThumbWidth]
  );

  const generateLeftRatio = useCallback(() => {
    // Calculates the ratio of scroll thumb movement
    // To the scrolling movement of the container it's affecting
    if (props.currentWidthMultiplier > 1) {
      const ratio =
        EXAMPLE_LEFT_PX_FOR_RATIO_CALCUlATION /
        generateLeft(EXAMPLE_LEFT_PX_FOR_RATIO_CALCUlATION);
      return ratio;
    } else {
      return 1;
    }
  }, [props.currentWidthMultiplier, generateLeft]);

  const handleMouseDownOnThumb = (event) => {
    // Begin draggable thumb/scrolling event
    event.preventDefault();
    document.addEventListener("mouseup", handleMouseOnUpAfterDown);
    document.addEventListener("mousemove", handleMouseMoveWhileDragging);
  };

  const handleMouseOnUpAfterDown = (event) => {
    // End draggable thumb/scrolling event
    // Allows cursor to leave scrollbar thumb without ending scrolling
    event.preventDefault();
    document.removeEventListener("mouseup", handleMouseOnUpAfterDown);
    document.removeEventListener("mousemove", handleMouseMoveWhileDragging);
  };

  const handleMouseMoveWhileDragging = (event) => {
    // Adjusts both the linked container's left scroll value
    // And the scroll thumb's left value in sync
    event.preventDefault();
    const maxScroll = calculateMaxLeft();
    props.setScroll((prevScroll) => {
      // Math.floor to keep it from jittering back and forth
      const newScroll = Math.floor(
        prevScroll + event.movementX * currentScrollRatio
      );
      if (newScroll > 0) {
        return newScroll < maxScroll ? newScroll : maxScroll;
      } else {
        return 0;
      }
    });
  };

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      // Update width of the scroll thumb and scroll ratio
      const newThumbWidth =
        SCROLL_THUMB_MAX_WIDTH_PX -
        SCROLL_WIDTH_DECREMENT_PX * props.currentWidthMultiplier;
      setScrollThumbWidth(
        Math.ceil(
          newThumbWidth > SCROLL_THUMB_MIN_WIDTH_PX
            ? newThumbWidth
            : SCROLL_THUMB_MIN_WIDTH_PX
        )
      );
      setCurrentScrollRatio(generateLeftRatio());
    }

    return () => {
      abort = true;
    };
  }, [props.currentWidthMultiplier, props.width, generateLeftRatio]);

  return (
    <div
      className={`scroll-bar ${props.visible ? "visible" : "hidden"}`}
      style={{
        width: props.width ? props.width : "1px",
      }}
    >
      {props.visible && (
        <div
          className={"scroll-thumb"}
          onMouseDown={(e) => handleMouseDownOnThumb(e)}
          style={{
            width: scrollThumbWidth,
            left: generateLeft(props.leftPosition),
            transitionProperty: "left",
            transitionDuration: "0.1s",
          }}
        />
      )}
    </div>
  );
};

export default TimelineScrollBar;
