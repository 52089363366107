const ENTITY_TYPES = {
  user: { label: "Users", field: "user" },
  artist: { label: "Artists", field: "artist" },
  song: { label: "Songs", field: "song" },
};

const PARTNER_LIST = [
  {
    label: "Provider 1",
    field: "napster",
    entityTypes: [ENTITY_TYPES.user, ENTITY_TYPES.artist, ENTITY_TYPES.song],
  },
  {
    label: "Provider 2",
    field: "soundcloud",
    entityTypes: [ENTITY_TYPES.user, ENTITY_TYPES.artist, ENTITY_TYPES.song],
  },
];

export default PARTNER_LIST;
