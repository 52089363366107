import React, { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Format from "../../services/format";
import Loading from "../Loading/Loading";
import Card from "../../containers/Card/Card";
import styles from "../../styles/constants.scss";
import axios from "../../config/axios";
// For striped divs
import PinkStripes from "../../styles/icons/pink-stripes.svg";

import "./artist-play-distribution.scss";

const TITLE = "Play Distribution";
const TOTAL_USERS_LABEL = "Total # Of Users for This Artist";
const SEE_ALL_TITLE = "See All";
const UNAVAILABLE_BAR_MESSAGE = "n/a ";
const UNABLE_TO_RENDER_MESSAGE = "Unable to render graph due to missing data";

const BAR_INFO = [
  {
    title: "Total Plays (All Artists)",
    dataKey: "total_plays",
    stripes: false,
    backgroundColor: styles.colorLightBlue,
    tooltip: {
      message: " total plays for all artists",
    },
  },
  {
    title: "Monetized Plays (All Artists)",
    dataKey: "monetized_plays",
    stripes: false,
    backgroundColor: styles.colorPurple,
    defaultVisible: true,
    defaultPositioning: true,
    greyColor: styles.totalPlaysColor,
    tooltip: {
      message: " of all artist plays are monetized",
      divisor: "total_plays",
      percentageColor: styles.legiblePurpleColor,
    },
  },
  {
    title: "Plays for This Artist",
    dataKey: "artist_plays",
    stripes: true,
    backgroundColor: styles.colorPink,
    backroundHexOpacity: 80,
    defaultVisible: true,
    defaultColor: styles.colorWhite,
    defaultHexOpacity: 33,
    greyColor: styles.colorLightGrey,
    tooltip: {
      message: " of all plays are for this artist",
      divisor: "total_plays",
    },
  },
  {
    title: "Monetized Plays for This Artist",
    dataKey: "monetized_artist_plays",
    stripes: true,
    backgroundColor: styles.colorPurple,
    tooltip: {
      message: " of plays for this artist are monetized",
      divisor: "artist_plays",
    },
  },
  {
    title: "Non-Monetized Plays for This Artist",
    dataKey: "non_monetized_artist_plays",
    stripes: true,
    backgroundColor: styles.colorLightBlue,
    tooltip: {
      message: " of plays for this artist are non-monetized",
      divisor: "artist_plays",
    },
  },
];

const ArtistPlayDistribution = ({
  artistId,
  tableDate,
  partnerName,
  pauseUpdates,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  // Values are numbers/indexes except for the `See All` option and `null` value
  // This is to support logic that relies on color shifting/altered visibility
  // Depending what bar in the 'stack' is highlighted (hovered/selected)
  const [hoverBarIndex, sethoverBarIndex] = useState(null);
  const [selectedBarIndex, setSelectedBarIndex] = useState(SEE_ALL_TITLE);
  const [missingRequiredData, setMissingRequiredData] = useState(false);
  const [playCounts, setPlayCounts] = useState({});

  const getArtistPlayDistributionData = useCallback(
    async (artistId, tableDate, partnerName) => {
      if (artistId !== null && artistId.length) {
        try {
          const res = await axios.post(
            "/get-play-distribution",
            {
              entityType: "artist",
              entityID: artistId,
              tableDate: tableDate,
              provider: partnerName,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("tk")}`,
              },
            }
          );

          // Populate with data from the API
          setData(res.data.results);
        } catch (error) {
          setData({});
        }

        // End loading state regardless
        setLoading(false);
      }
    },
    []
  );

  // Update component data on date or id change
  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort && !pauseUpdates) {
      setLoading(true);
      setSelectedBarIndex(SEE_ALL_TITLE);
      getArtistPlayDistributionData(artistId, tableDate, partnerName);
    }

    return () => {
      abort = true;
    };
  }, [
    artistId,
    tableDate,
    pauseUpdates,
    partnerName,
    getArtistPlayDistributionData,
  ]);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort && data !== null) {
      // Sort out if all fields have the basic required play count data
      const dataPlayCounts = {};
      for (const barData of BAR_INFO) {
        const dataKey = barData.dataKey;
        const playCount = data?.[dataKey]?.count;
        if (
          !isNaN(playCount) &&
          playCount !== undefined &&
          playCount !== null
        ) {
          dataPlayCounts[dataKey] = playCount;
        } else {
        }
      }

      // Can't render the graph at all without at least the
      // Monetized and total play counts as a minimum
      const minimunRequiredPlayCounts =
        dataPlayCounts?.total_plays !== undefined &&
        dataPlayCounts?.monetized_plays !== undefined;

      // Can calculate missing artist play count if the later two bars exist
      // But only bother if the bottom/main two bars have data
      if (minimunRequiredPlayCounts) {
        if (dataPlayCounts?.artist_plays === undefined) {
          if (
            dataPlayCounts?.monetized_artist_plays !== undefined &&
            dataPlayCounts?.non_monetized_artist_plays !== undefined
          ) {
            dataPlayCounts.artist_plays =
              dataPlayCounts.monetized_artist_plays +
              dataPlayCounts.non_monetized_artist_plays;
          }
        } else if (
          (dataPlayCounts?.monetized_artist_plays === undefined) !==
          (dataPlayCounts?.non_monetized_artist_plays === undefined)
        ) {
          // Can calculate monetized OR non monetized artist plays if only one is missing
          const keyMissingData =
            dataPlayCounts?.monetized_artist_plays === undefined
              ? "monetized_artist_plays"
              : "non_monetized_artist_plays";
          const presentKeyData =
            keyMissingData !== "monetized_artist_plays"
              ? "monetized_artist_plays"
              : "non_monetized_artist_plays";

          dataPlayCounts[keyMissingData] =
            dataPlayCounts.artist_plays - dataPlayCounts[presentKeyData];
        }
      }

      setPlayCounts(dataPlayCounts);
      setMissingRequiredData(!minimunRequiredPlayCounts);
    }
    return () => {
      abort = true;
    };
  }, [data]);

  const generateButtons = (buttonInfo) => {
    return buttonInfo.map(
      (
        {
          title, // The title of the button
          stripes = false, // Boolean to determine if the button should have stripes
          backgroundColor, // Color for the button's circle's background
        },
        index
      ) => {
        const barIdentifier = title === SEE_ALL_TITLE ? SEE_ALL_TITLE : index;
        return (
          <button
            key={title}
            className={`distribution-button${
              selectedBarIndex === barIdentifier ? " selected" : ""
            }`}
            onClick={() => setSelectedBarIndex(barIdentifier)}
            onMouseEnter={() => sethoverBarIndex(barIdentifier)}
            onMouseLeave={() => sethoverBarIndex(null)}
          >
            {title !== SEE_ALL_TITLE && (
              <div
                className={`circle`}
                style={{
                  // Button colored circle backgrounds aren't transparent
                  backgroundColor: backgroundColor,
                  backgroundImage: stripes ? `url(${PinkStripes})` : "none",
                }}
              />
            )}
            {title}
          </button>
        );
      }
    );
  };

  const generateButtonRow = () => {
    return (
      <div className={"distribution-buttons"}>
        {generateButtons([{ title: SEE_ALL_TITLE }])}
        {generateButtons(BAR_INFO)}
      </div>
    );
  };

  const calculateBarWidthPercent = (barDataKey) => {
    if (playCounts[barDataKey] !== undefined) {
      return (playCounts[barDataKey] / playCounts.total_plays) * 100;
    }
  };

  const calculateBarStart = (barDataKey, defaultToZero) => {
    // Returns the offset for the bars and the direction of that offset
    if (defaultToZero) {
      return { direction: "left", percentage: "0%" };
    }
    // Calculate the monetized bar width to use as a reference for placement
    const monetizedBarWidth = calculateBarWidthPercent("monetized_plays");
    const direction =
      barDataKey === "non_monetized_artist_plays" ? "left" : "right";
    let percentage =
      direction === "left" ? monetizedBarWidth : 100 - monetizedBarWidth;
    // Subtract artist plays' overflow to the right beyond the monetized plays width
    if (barDataKey === "artist_plays")
      percentage -= calculateBarWidthPercent("non_monetized_artist_plays");

    return { direction, percentage: `${percentage}%` };
  };

  const getHighlightedBar = () => {
    return hoverBarIndex !== null && hoverBarIndex !== undefined
      ? hoverBarIndex
      : selectedBarIndex;
  };

  const addOpacityToHex = (hex, hexOpacitySuffix) => {
    return `${hex}${hexOpacitySuffix ?? ""}`;
  };

  const generatePlayBar = (
    {
      dataKey, // key for the data prop to get the associated value/count from the API
      defaultVisible = false, // Boolean to determine if the bar is visible by default
      stripes = false, // Boolean to determine if the bar should have stripes
      defaultColor, // Color for the bar's color in default viewing mode, optional
      greyColor, // Grey color for if the bar is visible but another is highlighted, optional
      defaultHexOpacity, // Opacity change for the bar's color in default viewing mode, optional
      backgroundColor, // Color for the bar's background, lowest priority/overritten in certain views
      backroundHexOpacity, // Opacity change for the bar's backgroundColor, optional
      defaultPositioning = false, // Boolean to determine if the bar's position needs calculation
      title, // String title of the bar's associated field
    },
    index
  ) => {
    const highlightedBar = getHighlightedBar();
    const isHighlighted = highlightedBar === index;
    if (playCounts[dataKey] !== undefined) {
      const defaultView = highlightedBar === SEE_ALL_TITLE;
      const notGreyedOut = isHighlighted || (defaultView && defaultVisible);
      // Dynamically use the right background color variation for the circumstances
      // If required, add opacity to the hex color
      const adjustedBackgroundColor =
        defaultColor && defaultView
          ? addOpacityToHex(defaultColor, defaultHexOpacity)
          : addOpacityToHex(backgroundColor, backroundHexOpacity);
      // Check if the bar is even visible at all
      const isVisible =
        isHighlighted ||
        (defaultVisible && defaultView) ||
        highlightedBar > index;
      const { direction, percentage } = calculateBarStart(
        dataKey,
        defaultPositioning
      );

      return (
        <div
          className={`distribution-bar${stripes ? " striped-div" : ""}`}
          style={{
            backgroundColor: notGreyedOut ? adjustedBackgroundColor : greyColor,
            backgroundImage:
              stripes && notGreyedOut ? `url(${PinkStripes})` : null,
            width: `${calculateBarWidthPercent(dataKey)}%`,
            zIndex: index,
            display: isVisible ? "inherit" : "none",
            [direction]: percentage,
          }}
        />
      );
    } else {
      return isHighlighted ? (
        <div
          className={"distribution-bar unavailable-bar-message"}
          style={{ zIndex: index }}
        >
          {UNAVAILABLE_BAR_MESSAGE}
          {title}
        </div>
      ) : (
        <></>
      );
    }
  };

  const generateDividers = () => {
    const dividers = [];
    for (let i = 1; i < 10; i++) {
      dividers.push(
        <div
          key={i}
          className={"divider"}
          style={{
            left: `${i * 10}%`,
          }}
        />
      );
    }

    return dividers;
  };

  const generateToolTipMessage = (highlightedBar) => {
    const dataKey = highlightedBar.dataKey;
    const {
      message,
      divisor = false,
      percentageColor = styles.colorPink,
    } = highlightedBar.tooltip;
    let percentage = "";

    if (divisor) {
      percentage = `${Format.formatPercentage(
        playCounts[dataKey] / playCounts[divisor]
      )}%`;
    }

    return (
      <div className={"tooltip-message"}>
        {playCounts?.[dataKey] !== undefined ? playCounts[dataKey] : "n/a"}
        {divisor
          ? ` / ${
              playCounts?.[divisor] !== undefined ? playCounts[divisor] : "n/a"
            } `
          : " "}
        {percentage &&
          playCounts?.[divisor] !== undefined &&
          playCounts?.[dataKey] !== undefined && (
            <span style={{ color: percentageColor }}>{percentage}</span>
          )}
        <div className={"tooltip-bar-type-message"}>{message}</div>
      </div>
    );
  };

  const generateToolTip = () => {
    const highlightedBar = BAR_INFO?.[getHighlightedBar()];
    const percentile = data?.[highlightedBar?.dataKey]?.percentile;
    const formattedPercentile =
      !isNaN(percentile) && percentile !== undefined && percentile !== null
        ? Format.formatPercentile(percentile)
        : false;
    const zScore = data?.[highlightedBar?.dataKey]?.zScore;
    return !!highlightedBar ? (
      <div className={"data-text"}>
        {generateToolTipMessage(highlightedBar)}
        <div className={"z-score-and-percentile"}>
          <div className={"percentile"}>
            {formattedPercentile
              ? formattedPercentile +
                Format.getPercentileSuffix(formattedPercentile)
              : "n/a"}
            {" percentile"}
          </div>
          {"z-score: "}
          {!isNaN(zScore) && zScore !== undefined && zScore !== null
            ? Format.toDecimals(zScore, 2)
            : "n/a"}
        </div>
      </div>
    ) : (
      <></>
    );
  };

  const generateBarChart = () => {
    // Container also functions as the bottom bar (Total Plays)
    const highlightedBar = getHighlightedBar();

    return (
      <div
        className={"distribution-bar-graph"}
        style={{
          backgroundColor:
            highlightedBar === SEE_ALL_TITLE || highlightedBar === 0
              ? styles.colorLightBlue
              : styles.colorOffWhite,
        }}
      >
        {generateDividers()}
        {BAR_INFO.slice(1).map((barInfo, index) => {
          // Skip Total Plays
          return (
            <React.Fragment key={barInfo.dataKey}>
              {generatePlayBar(barInfo, index + 1)}
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  return (
    <Grid className={"artist-play-distribution-wrapper"} container>
      {!loading ? (
        <>
          <div className={"distribution-header"}>
            <h3 className={"distribution-title"}>{TITLE}</h3>
            <h4 className={"distribution-total-users"}>
              {TOTAL_USERS_LABEL}
              <div className={"distribution-total-users-number"}>
                {data?.total_users ?? "n/a"}
              </div>
            </h4>
          </div>
          {missingRequiredData ? (
            <div className={"unable-to-render-message"}>
              {UNABLE_TO_RENDER_MESSAGE}
            </div>
          ) : (
            <>
              {generateButtonRow()}
              {generateBarChart()}
              <div className={"distribution-tooltip"}>{generateToolTip()}</div>
            </>
          )}
        </>
      ) : (
        <Loading />
      )}
    </Grid>
  );
};

export default Card(ArtistPlayDistribution);
