import React, { useState } from "react";
import UseDecisionBox from "../UseDecisionBox/UseDecisionBox";
import Loading from "../Loading/Loading";
import PillList from "../PillList/PillList";
import SelectionCheckboxList from "../SelectionCheckboxList/SelectionCheckboxList";
import SelectionBoxes from "../SelectionBoxes/SelectionBoxes";
import SelectionTextBox from "../SelectionTextBox/SelectionTextBox";
import SelectionRadioButtons from "../SelectionRadioButtons/SelectionRadioButtons";
import { FORM_REASONS } from "../../config/song_fraud_reasons";
import {
  SUBMIT_CTA_TEXT,
  SUBMISSION_SUCCESS_TEXT,
  NO_SONG_TYPE_ERROR_TEXT,
  PILL_LIST_HEADER,
  PILL_LIST_SUBHEADER,
  PILL_LIST_ERROR_MESSAGE,
  REQUIRED_TEXTBOX_PLACEHOLDER,
  OPTIONAL_TEXTBOX_PLACEHOLDER,
  SELECTION_GENRE_OPTIONS,
  SELECTION_SONG_CLASSIFICATION_PROPS,
  SELECTION_SLIDER_PROPS,
} from "../../config/decision-box-shared-props";

const SECTION_LABELS = [
  { label: "1. Label the reason(s) for fraud:" },
  { label: "2. Rate your understanding of this song:", required: true },
  {
    label: "3. Select the genre of this song:",
    required: true,
    bonusText: " Select all that apply",
  },
  {
    label: "4. Classify this song:",
    required: true,
    bonusText:
      " Further investigation outside of the label studio is often required to make this decision",
  },
  { label: "5. Select the portion of fraudulent plays:", required: true },
  {
    label: "6. Classify the type of fraud:",
    required: true,
    bonusText: " Select one",
  },
];

const FRAUD_REASONS_PER_COLUMN = [2, 2, 2];

const SELECTION_BOXES_PROPS = {
  options: ["Bot Behaviour"],
  renderTextbox: true,
};

const SongDecisionBox = ({
  provider, // String, provider source of the song
  dsp, // String, DSP source of the song
  entityId, // String, the unique ID of the song
  partnerName, // String, the name of the partner providing the data
  tableDate, // String, The first day of the week being sampled (e.g. "2022-06-15")
  handleFormSubmission, // Callback function to run on form submission
  selectedFraudReasons, // Array of selected fraud reasons
  editSelectedFraudReasons, // setState callback function to edit the selectedFraudReasons array
}) => {
  // State tracked value used only by the Song Decision Box
  const [selectedSongType, setSelectedSongType] = useState(undefined);

  const {
    error,
    setError,
    success,
    loading,
    reviewRequest,
    setReviewRequest,
    selectedUnderstanding,
    setSelectedUnderstanding,
    understandingText,
    setUnderstandingText,
    selectedPercent,
    setSelectedPercent,
    selectedFraudType,
    setSelectedFraudType,
    setFraudTypeIndex,
    selectedGenreTypes,
    setSelectedGenreTypes,
    fraudTypeText,
    setFraudTypeText,
    selectedFraudTypeReasons,
    setSelectedFraudTypeReasons,
    generateFraudReasonTextArr,
    renderSectionLabel,
    renderLineBreak,
    renderSelectionSlider,
    renderFraudReasons,
    handleSubmit,
  } = UseDecisionBox({
    selectedFraudReasons,
    editSelectedFraudReasons,
    selectionBoxesProps: SELECTION_BOXES_PROPS,
    provider,
    dsp,
    entityId,
    entityType: "song",
    formReasons: FORM_REASONS,
    tableDate,
    handleFormSubmission,
    partnerName,
  });

  /**
   * Checks if the labeller has properly filled in the song type selection.
   * @returns {Boolean}
   */
  const songClassificationCompleted = () => {
    if (selectedSongType === undefined) {
      setError(NO_SONG_TYPE_ERROR_TEXT);
      return false;
    }
    return true;
  };

  /**
   * Runs song classification validation. If it passes, the submit handler callback is called.
   * If submission is successful, reset the song type selection.
   * @returns {undefined}
   */
  const handleSongSubmit = async (e) => {
    e.preventDefault();
    if (songClassificationCompleted()) {
      const submissionSuccess = await handleSubmit(e, {
        entityClassification: selectedSongType,
      });
      if (submissionSuccess) setSelectedSongType(undefined);
    }
  };

  return (
    <div className={"decision-box-wrapper"}>
      {loading && (
        <div className={"decision-box-loading"}>
          <Loading />
        </div>
      )}
      <div className={"decision-box-container"}>
        <div className={"decision-box-section-container"}>
          {renderSectionLabel(SECTION_LABELS[0])}
          <div className={"decision-box-section fraud-reasons-section"}>
            {renderFraudReasons(FRAUD_REASONS_PER_COLUMN)}
          </div>
        </div>
        {renderLineBreak()}
        <div className={"decision-box-section-container"}>
          {renderSectionLabel(SECTION_LABELS[1])}
          <div className={"decision-box-section understanding-slider-section"}>
            {renderSelectionSlider(
              SELECTION_SLIDER_PROPS.understandingLevel,
              selectedUnderstanding,
              setSelectedUnderstanding,
              understandingText,
              setUnderstandingText,
              178
            )}
          </div>
        </div>
        {renderLineBreak()}
        <div className={"decision-box-section-container"}>
          {renderSectionLabel(SECTION_LABELS[2])}
          <div className={"decision-box-section genre-selection-section"}>
            <SelectionCheckboxList
              options={SELECTION_GENRE_OPTIONS.options}
              tooltipWidth={SELECTION_GENRE_OPTIONS.textboxWidth}
              selectedOptions={selectedGenreTypes}
              setSelectedOptions={setSelectedGenreTypes}
            />
          </div>
        </div>
        {renderLineBreak()}
        <div className={"decision-box-section-container"}>
          {renderSectionLabel(SECTION_LABELS[3])}
          <div className={"decision-box-section song-type-section"}>
            <SelectionRadioButtons
              options={SELECTION_SONG_CLASSIFICATION_PROPS.options}
              selectedOption={selectedSongType}
              setSelectedOption={setSelectedSongType}
              tooltipWidth={SELECTION_SONG_CLASSIFICATION_PROPS.tooltipWidth}
            />
          </div>
        </div>
        {renderLineBreak()}
        <div className={"decision-box-section-container"}>
          {renderSectionLabel(SECTION_LABELS[4])}
          <div
            className={"decision-box-section fraud-percentage-slider-section"}
          >
            {renderSelectionSlider(
              SELECTION_SLIDER_PROPS.fraudPercentage,
              selectedPercent,
              setSelectedPercent
            )}
          </div>
        </div>
        {renderLineBreak()}
        <div
          className={`decision-box-section-container ${
            selectedPercent !==
            SELECTION_SLIDER_PROPS.fraudPercentage.options[0]
              ? "visible"
              : "hidden"
          }`}
        >
          {renderSectionLabel(SECTION_LABELS[5])}
          <div className={"decision-box-section fraud-type-section"}>
            <SelectionBoxes
              options={SELECTION_BOXES_PROPS.options}
              selectedOption={selectedFraudType}
              onChange={(option, index) => {
                setSelectedFraudType(option);
                setFraudTypeIndex(index);
              }}
            />
            <div
              className={`decision-box-pill-list ${
                selectedFraudType === undefined
                  ? "no-margin"
                  : SELECTION_BOXES_PROPS.options.includes(selectedFraudType)
                  ? "visible"
                  : ""
              }`}
            >
              {selectedFraudReasons?.length > 0 ? (
                <label className={"decision-box-pill-list-label"}>
                  {PILL_LIST_HEADER}
                  <span>{PILL_LIST_SUBHEADER}</span>
                </label>
              ) : (
                <span className={"decision-box-pill-list-error"}>
                  {PILL_LIST_ERROR_MESSAGE}
                </span>
              )}
              <PillList
                options={generateFraudReasonTextArr()}
                selectedOptions={selectedFraudTypeReasons[selectedFraudType]}
                setSelectedOptions={(newFraudTypeReasons) => {
                  setSelectedFraudTypeReasons((prevFraudTypeReasons) => {
                    return {
                      ...prevFraudTypeReasons,
                      [selectedFraudType]: newFraudTypeReasons,
                    };
                  });
                }}
              />
            </div>
            <SelectionTextBox
              visible={!!selectedFraudType}
              selectedOption={selectedFraudType}
              placeholderText={
                (SELECTION_BOXES_PROPS.options.includes(selectedFraudType)
                  ? OPTIONAL_TEXTBOX_PLACEHOLDER
                  : REQUIRED_TEXTBOX_PLACEHOLDER
                ).placeholder
              }
              textRequired={
                (SELECTION_BOXES_PROPS.options.includes(selectedFraudType)
                  ? OPTIONAL_TEXTBOX_PLACEHOLDER
                  : REQUIRED_TEXTBOX_PLACEHOLDER
                ).required
              }
              textContent={fraudTypeText[selectedFraudType]}
              setTextContent={(newText) => {
                setFraudTypeText((prevTextObj) => {
                  return {
                    ...prevTextObj,
                    [selectedFraudType]: newText,
                  };
                });
              }}
            />
          </div>
          {renderLineBreak()}
        </div>
        <div className={"decision-box-presubmit"}>
          {/* Request review singular checkbox */}
          <SelectionCheckboxList
            options={[
              {
                tooltip: FORM_REASONS.preSubmit[0].reason.tooltip,
                text: FORM_REASONS.preSubmit[0].reason.label,
              },
            ]}
            selectedOptions={reviewRequest}
            setSelectedOptions={setReviewRequest}
            tooltipWidth={294}
          />
        </div>
        <p className={`decision-box-message ${success ? "success" : "error"}`}>
          {success ? SUBMISSION_SUCCESS_TEXT : !!error && error}
        </p>
        <button className={"decision-box-submit"} onClick={handleSongSubmit}>
          {SUBMIT_CTA_TEXT}
        </button>
      </div>
    </div>
  );
};

export default SongDecisionBox;
