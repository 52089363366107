import React, { useState } from "react";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableRow from "@mui/material/TableRow";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Checkbox from "../CheckBox/CheckBox";
import Format from "../../services/format";
import Util from "../../services/util";
import { SELECTION_SLIDER_PROPS } from "../../config/decision-box-shared-props.js";
import "./label-history-table.scss";

const ESTIMATED_FRAUD_MAPPING = SELECTION_SLIDER_PROPS.fraudPercentage.options;

// estimated fraud field colors
const COLOR_RED = "#e31a1c";
const COLOR_GREEN = "#01aa6d";
const DEFAULT_COLOR = "#000000";

// column sorting keys
const DEFAULT_SORT_DIR = "asc";
const SORT_DESCENDING = "desc";
const SORT_ASCENDING = "asc";

const CTA_BUTTON_TEXT = "Edit Submission";

const LabelHistoryTable = (props) => {
  const [sortField, setSortField] = useState(props.defaultSortField);
  const [sortDirection, setSortDirection] = useState(DEFAULT_SORT_DIR);

  const setEstimatedFraudColor = (estimatedFraud) => {
    if (estimatedFraud >= 5) {
      return COLOR_RED;
    } else if (estimatedFraud <= 1) {
      return COLOR_GREEN;
    } else {
      return DEFAULT_COLOR;
    }
  };

  const setSortOrder = (sortField) => {
    setSortField(sortField);
    setSortDirection(
      sortDirection === SORT_ASCENDING ? SORT_DESCENDING : SORT_ASCENDING
    );
  };

  const sortByComparator = (array, comparator) => {
    const arrayToBeSorted = array.map((element, index) => [element, index]);

    arrayToBeSorted.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return arrayToBeSorted.map((element) => element[0]);
  };

  const getComparator = (sortDirection, sortField) => {
    return sortDirection === SORT_DESCENDING
      ? (a, b) => descendingComparator(a, b, sortField)
      : (a, b) => -descendingComparator(a, b, sortField);
  };

  const descendingComparator = (a, b, sortField) => {
    // reverse sort direction for IDs
    if (sortField === "id_value") {
      if (b[sortField] > a[sortField]) return -1;
      if (b[sortField] < a[sortField]) return 1;
      return 0;
    }

    if (b[sortField] < a[sortField]) return -1;
    if (b[sortField] > a[sortField]) return 1;
    return 0;
  };

  return (
    <div className={"label-history-table-container"}>
      {props.data && (
        <TableContainer>
          <Table>
            <TableHead className={"label-history-table-header"}>
              <TableRow>
                {props.headers &&
                  Object.keys(props.headers).map((header, i) => {
                    // ignore first and last columns
                    if (
                      i === 0 ||
                      i === Object.keys(props.headers).length - 1
                    ) {
                      return (
                        <TableCell
                          className={"label-history-table-header-cell"}
                          key={header + i}
                        >
                          {header}
                        </TableCell>
                      );
                    } else {
                      const fieldIsSelected =
                        sortField === props.headers[header];

                      return (
                        <TableCell
                          className={"label-history-table-header-cell"}
                          key={header + i}
                        >
                          <TableSortLabel
                            className={"header-cell-sort-label"}
                            active={true}
                            direction={
                              fieldIsSelected ? sortDirection : DEFAULT_SORT_DIR
                            }
                            onClick={() => setSortOrder(props.headers[header])}
                            IconComponent={
                              fieldIsSelected
                                ? ArrowDropDownIcon
                                : ArrowDropUpIcon
                            }
                          >
                            {header}
                          </TableSortLabel>
                        </TableCell>
                      );
                    }
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data &&
                sortByComparator(
                  props.data,
                  getComparator(sortDirection, sortField)
                ).map((entry, i) => {
                  // use combination of id + timestamp to account for invalid values
                  const entryId = entry.id_value + entry.timestamp;
                  const dateRange = Util.getTableDateRange(entry.table_date);

                  return (
                    <TableRow key={entryId + i}>
                      <TableCell>
                        <Checkbox
                          checked={props.isCheckboxSelected(
                            props.tableDate,
                            entryId
                          )}
                          value={entryId}
                          handleClick={(e) =>
                            props.onCheckboxClick(props.tableDate, e)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {Format.formatInTwelveHourTime(
                          new Date(entry.timestamp)
                        )}
                      </TableCell>
                      <TableCell>
                        {Format.getNumberByProvider(entry.provider)}
                      </TableCell>
                      <TableCell>
                        {/* TODO: Once dsp starts differing from providers, use different function */}
                        {Format.getNumberByProvider(entry.dsp)}
                      </TableCell>
                      <TableCell>
                        {Format.capitalizeFirstLetter(entry.id_type)}
                      </TableCell>
                      <TableCell className={"label-history-table-cell-id"}>
                        {entry.id_value}
                      </TableCell>
                      <TableCell>
                        {`${Format.formatReadableDate(
                          dateRange.start
                        )} - ${Format.formatReadableDate(dateRange.end)}`}
                      </TableCell>
                      <TableCell
                        style={{
                          color: setEstimatedFraudColor(
                            entry.fraudulent_plays_rate
                          ),
                        }}
                      >
                        {ESTIMATED_FRAUD_MAPPING[entry.fraudulent_plays_rate]
                          ? ESTIMATED_FRAUD_MAPPING[entry.fraudulent_plays_rate]
                          : ""}
                      </TableCell>
                      <TableCell>
                        <Link
                          to={Format.composeProfileUrlPath(
                            entry.provider,
                            entry.id_type,
                            entry.id_type === "song"
                              ? entry.id_value_hashed
                              : entry.id_value,
                            entry.table_date,
                            Format.formatSongUrl(
                              // making some confident assumptions here with incoming song id format
                              ...Format.getSongNameAndArtist(entry.id_value)
                            )
                          )}
                          target={"_blank"}
                        >
                          <button className={"cta-button"}>
                            {CTA_BUTTON_TEXT}
                          </button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default LabelHistoryTable;
