import React, { useState, useEffect } from "react";
import Card from "../../containers/Card/Card";
import Loading from "../Loading/Loading";
import Format from "../../services/format";
import styles from "../../styles/constants.scss";
import "./metric-card.scss";

const MetricCard = ({
  title,
  score,
  format,
  scoreLimits,
  percentile,
  zScore,
  displaySecondaryMetrics,
  extraText,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [formattedPercentile, setFormattedPercentile] = useState(percentile);

  const getScoreColor = (score, scoreLimits) => {
    let color = styles.defaultMetricColor;

    if (!scoreLimits || !scoreLimits.high || !scoreLimits.low) {
      return color;
    }

    if (score > scoreLimits.high) {
      color = styles.redMetricColor;
    } else if (score > scoreLimits.low) {
      color = styles.yellowMetricColor;
    }

    return color;
  };

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort && title !== undefined && score !== undefined && isLoading) {
      setIsLoading(false);
    }

    return () => {
      abort = true;
    };
  }, [title, score, isLoading]);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (
      !abort &&
      !isNaN(percentile) &&
      percentile !== null &&
      percentile !== undefined
    ) {
      setFormattedPercentile(Format.formatPercentile(percentile));
    }

    return () => {
      abort = true;
    };
  }, [percentile]);

  return (
    <div
      className={`metric-card-container${extraText ? " with-extra-text" : ""}`}
    >
      {!isLoading ? (
        <>
          <div className={"metric-header-title"}>
            {title && Format.getFormattedTitle(title)}
          </div>
          <div
            className={"metric-score"}
            style={{
              color: getScoreColor(score, scoreLimits),
            }}
          >
            {score !== undefined && score !== null
              ? format
                ? Format.toDecimals(score, 1) + format
                : Format.toDecimals(score, 1)
              : "n/a"}
          </div>
          {displaySecondaryMetrics && (
            <div className={"metric-secondary-score-container"}>
              <div className={"metric-secondary-scores"}>
                <div className={"metric-secondary-score left"}>
                  {
                    <span>
                      {formattedPercentile !== undefined &&
                      formattedPercentile !== null ? (
                        <span>
                          {formattedPercentile}
                          <sup>
                            {Format.getPercentileSuffix(formattedPercentile)}
                          </sup>
                        </span>
                      ) : (
                        "n/a"
                      )}
                      {" percentile"}
                    </span>
                  }
                </div>
                <div className={"metric-secondary-score right"}>
                  {`z-score: ${
                    zScore !== undefined && zScore !== null && !isNaN(zScore)
                      ? Format.toDecimals(zScore, 2)
                      : "n/a"
                  }`}
                </div>
              </div>
              {extraText && (
                <div className={"metric-extra-text"}>{extraText}</div>
              )}
            </div>
          )}
        </>
      ) : (
        <div className={"metric-card-loader"}>
          <Loading />
        </div>
      )}
    </div>
  );
};

export default Card(MetricCard);
