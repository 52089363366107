import React, { useEffect } from "react";
import DrawSvgs from "../../services/draw_svgs";
import styles from "../../styles/constants.scss";
import usePrevious from "../UsePrevious/UsePrevious";
import "./pill-list.scss";

const PLUS_ICON_PATH =
  "M 0.51 6.49 L 12.47 6.48 L 6.5 6.4 L 6.5 12.5 L 6.5 0.53";
const CHECK_ICON_PATH = "M 1.333 6.64 L 5.372 10.531 L 12.102 2.748";
const ICON_WIDTH_PX = 13;

const PillList = ({
  options = [], // Array of strings for options to select from
  selectedOptions = [], // Array of strings for the currently selected options
  setSelectedOptions = [], // setState callback function to update the selected options
}) => {
  const prevOptions = usePrevious(options);

  useEffect(() => {
    // Remove selectedOption if it is no longer in the options array
    if (prevOptions?.length > options?.length) {
      setSelectedOptions(
        selectedOptions.filter((selectedOption) =>
          options.includes(selectedOption)
        )
      );
    }
  }, [options, prevOptions, selectedOptions, setSelectedOptions]);

  const handleOptionClick = (option) => {
    if (selectedOptions.includes(option)) {
      // Remove option
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      // Add option
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const generatePillOptions = (optionList) => {
    return optionList.map((option, index) => {
      const optionSelected = selectedOptions.includes(option);
      return (
        <div
          className={`pill-list-option${optionSelected ? " selected" : ""}`}
          key={`${option}-${index}`}
          onClick={() => handleOptionClick(option)}
        >
          {DrawSvgs.drawCustomSvgPath(
            optionSelected ? CHECK_ICON_PATH : PLUS_ICON_PATH,
            ICON_WIDTH_PX,
            ICON_WIDTH_PX,
            optionSelected ? styles.colorWhite : styles.colorDarkGrey,
            "0%"
          )}
          {option}
        </div>
      );
    });
  };

  return (
    <div className={"pill-list-container"}>{generatePillOptions(options)}</div>
  );
};

export default PillList;
