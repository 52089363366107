import React, { useState, useEffect, useRef } from "react";
import InfoTip from "../InfoTip/InfoTip";
import Slider from "../Slider/Slider";
import Format from "../../services/format";
import "./percentile-metric.scss";

const DEFAULT_DIVIDER_WIDTH = "50%";
const TOOL_TIP_WIDTH = 250;
const SUBSCRIPT_WIDTH_PX = "50px";

const PercentileMetric = ({
  title,
  infoDesc,
  score,
  percentile,
  zScore,
  stackHeaders,
  titleLength,
}) => {
  const sliderRef = useRef();
  const [formattedPercentile, setFormattedPercentile] = useState(null);
  const [displayToolTip, setDisplayToolTip] = useState(false);
  const [xPosition, setXPosition] = useState(0);
  const [yPosition, setYPosition] = useState(0);
  const [dividerWidth, setDividerWidth] = useState(DEFAULT_DIVIDER_WIDTH);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      // On dismounting, set mounted to false
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const resizeDivider = () => {
      try {
        setDividerWidth(
          stackHeaders
            ? DEFAULT_DIVIDER_WIDTH
            : `${sliderRef.current.clientWidth / 2 + titleLength}px`
        );
      } catch (error) {
        setDividerWidth(DEFAULT_DIVIDER_WIDTH);
      }
    };

    window.addEventListener("resize", resizeDivider);
    resizeDivider();
    return () => {
      window.removeEventListener("resize", resizeDivider);
    };
  }, [titleLength, stackHeaders]);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      setFormattedPercentile(Format.formatPercentile(percentile));
    }

    return () => {
      abort = true;
    };
  }, [percentile]);

  const handleMouseEnter = (e) => {
    if (mounted.current) {
      setXPosition(e.pageX);
      setYPosition(e.pageY);
      setDisplayToolTip(true);
    }
  };

  const handleMouseLeave = (infoTipVisibility) => {
    if (mounted.current) {
      setDisplayToolTip(infoTipVisibility);
    }
  };

  return (
    <>
      {stackHeaders ? (
        <div className={"percentile-metric-container stacked-container"}>
          {titleLength > 0 && (
            <div
              className={"percentile-metric-label"}
              style={{ width: titleLength }}
            >
              <div className={"header-sub-section"}>{title}</div>
              <div className={"header-sub-section percentile-metric-info-icon"}>
                <InfoTip
                  description={infoDesc}
                  arrow={true}
                  tooltipWidth={TOOL_TIP_WIDTH}
                />
              </div>
            </div>
          )}
          <div className={"stacked-content-wrapper"}>
            <div
              className={"stacked-content"}
              style={{
                width: `calc(100% - ${SUBSCRIPT_WIDTH_PX})`,
              }}
            >
              {formattedPercentile !== null && (
                <div
                  className={"divider"}
                  style={{
                    width: dividerWidth,
                  }}
                />
              )}
              <div className={"percentile-metric-slider-wrapper"}>
                <div className={"percentile-metric-slider"} ref={sliderRef}>
                  <Slider
                    score={score}
                    percentile={formattedPercentile}
                    zScore={zScore}
                    handleMouseEnter={handleMouseEnter}
                    handleMouseLeave={handleMouseLeave}
                  />
                </div>
              </div>
            </div>
            {formattedPercentile !== null && (
              <div
                className={"percentile-metric-subscript"}
                style={{
                  width: SUBSCRIPT_WIDTH_PX,
                }}
              >
                {formattedPercentile +
                  Format.getPercentileSuffix(formattedPercentile)}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={"percentile-metric-container inline-container"}>
          {titleLength > 0 && (
            <div
              className={"percentile-metric-label"}
              style={{ width: titleLength }}
            >
              <div className={"header-sub-section"}>{title}</div>
              <div className={"header-sub-section percentile-metric-info-icon"}>
                <InfoTip
                  description={infoDesc}
                  arrow={true}
                  tooltipWidth={TOOL_TIP_WIDTH}
                />
              </div>
            </div>
          )}
          {formattedPercentile !== null && (
            <div
              className={"divider"}
              style={{
                width: dividerWidth,
              }}
            />
          )}
          <div
            className={"percentile-metric-slider-wrapper"}
            style={{
              width: `calc(100% - ${titleLength}px - ${SUBSCRIPT_WIDTH_PX})`,
            }}
          >
            <div className={"percentile-metric-slider"} ref={sliderRef}>
              <Slider
                score={score}
                percentile={formattedPercentile}
                zScore={zScore}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
              />
            </div>
          </div>
          {formattedPercentile !== null && (
            <div
              className={"percentile-metric-subscript"}
              style={{ width: SUBSCRIPT_WIDTH_PX }}
            >
              {formattedPercentile +
                Format.getPercentileSuffix(formattedPercentile)}
            </div>
          )}
        </div>
      )}
      {displayToolTip && (
        <div
          className={"slider-info"}
          style={{
            top: `${yPosition}px`,
            left: `${xPosition}px`,
          }}
        >
          <div>
            score:{" "}
            {score || typeof score === "number"
              ? parseFloat(score.toFixed(3))
              : "n/a"}
          </div>
          <div>
            z-score:{" "}
            {zScore || typeof zScore === "number"
              ? parseFloat(zScore.toFixed(3))
              : "n/a"}
          </div>
        </div>
      )}
    </>
  );
};

export default PercentileMetric;
