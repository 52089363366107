import React, { useState, useEffect } from "react";
import HanksTip from "../HanksTip/HanksTip";
import Util from "../../services/util";
import "./artist-header.scss";

const ArtistHeader = ({
  artistId,
  tableDate,
  partnerName,
  getArtistName = true,
  title,
  hanksTip,
  hanksTipTextWidth,
}) => {
  const [headerText, setHeaderText] = useState("");

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      async function getHeaderText() {
        // just set static header
        if (!getArtistName) {
          setHeaderText(title);
          return;
        }

        let artistName = `Artist ${artistId}`;

        // get artist name via ID
        const metadata = await Util.getEntityMetadata(
          "artist",
          artistId,
          tableDate,
          partnerName
        );

        if (metadata.artist_name) {
          artistName = metadata.artist_name;
        }

        setHeaderText(artistName + title);
      }

      getHeaderText();
    }

    return () => {
      abort = true;
    };
  }, [artistId, tableDate, getArtistName, partnerName, title]);

  return (
    <div className={"artist-header-wrapper"}>
      <div className={"artist-header-text"}>{headerText}</div>
      {hanksTip && (
        <div className={"artist-header-hanks-tip"}>
          <HanksTip tipText={hanksTip} textTipWidth={hanksTipTextWidth} />
        </div>
      )}
    </div>
  );
};

export default ArtistHeader;
