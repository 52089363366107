import React, { useEffect, useState } from "react";
import Loading from "../Loading/Loading";
import PercentileMetric from "../PercentileMetric/PercentileMetric";
import Card from "../../containers/Card/Card";
import axios from "../../config/axios";
import "./percentile-metrics.scss";

const PercentileMetrics = ({
  title,
  entityType,
  entityId,
  partnerName,
  tableDate,
  pauseUpdates, // set to true when changing data source
  stackHeaders, // set to true when using layout where title(s) are stacked on top of progress bar
  titleLength, // set to 0 to hide titles
}) => {
  const [percentileData, setPercentileData] = useState([]);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      const getPercentileMetricData = async () => {
        let options = {
          entityType: entityType,
          entityID: entityId,
          tableDate,
          provider: partnerName,
        };

        if (entityType === "artist-avg-user") {
          options.entityType = "artist"; // reassign entity type
          options.averageUser = true;
        }

        if (entityId) {
          try {
            const res = await axios.post("/get-metric-percentiles", options, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("tk")}`,
              },
            });

            setPercentileData(res.data.results);
          } catch (error) {
            setPercentileData("Error while retrieving data");
          }
        }
      };

      if (!pauseUpdates) {
        // Reset state first
        setPercentileData([]);

        getPercentileMetricData();
      }
    }

    return () => {
      abort = true;
    };
  }, [entityType, entityId, tableDate, pauseUpdates, partnerName]);

  return (
    <div className={"percentile-metrics-container"}>
      {title && <span className={"percentile-metrics-title"}>{title}</span>}
      {!Array.isArray(percentileData) ? (
        <p>Something went wrong with data retrieval.</p>
      ) : percentileData.length > 0 ? (
        percentileData.map((metric) => {
          if (!metric.score || isNaN(metric.percentile)) {
            return <div key={metric.title} />;
          }

          return (
            <div className={"percentile-metrics-row"} key={metric.title}>
              <PercentileMetric
                title={metric.title}
                infoDesc={metric.description}
                score={metric.score}
                percentile={metric.percentile}
                zScore={metric.zScore}
                stackHeaders={stackHeaders}
                titleLength={titleLength}
              />
            </div>
          );
        })
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Card(PercentileMetrics);
