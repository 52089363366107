import React from "react";
import Checkbox from "../CheckBox/CheckBox";
import "./card-fraud-reason.scss";

const TEXT_AREA_PLACEHOLDER = "Please explain";

const CardFraudReason = ({
  reason, // String, the fraud reason label rendered
  reasonId, // Number ID for the reason
  checkReasonSelected = () => {}, // Function to check if the fraud reason has been selected
  editSelectedReasons = () => {}, // Function to add, remove or edit reason from state
  selectedFraudReasons = [], // Profile's state tracking of all selected fraud reasons
  addTextArea = false, // Whether or not the fraud reason has an associated textarea
  row, // Whether the reason has row styling
  description, // Optional description for the fraud reason
  justifyContent, // justifyContent styling based on alignment requirements and the like
}) => {
  /**
   * Renders a textbox linked to a fraud reason.
   * @param {Boolean} visible whether or not the text box should be visible
   * @returns {React.Component}
   */
  const renderTextArea = (visible) => {
    return (
      <div className={"textarea-container"}>
        <textarea
          placeholder={TEXT_AREA_PLACEHOLDER}
          className={`textarea${visible ? "" : " disabled"}`}
          onChange={(e) => editSelectedReasons(reasonId, e.target.value)}
          value={
            selectedFraudReasons?.[
              selectedFraudReasons.findIndex(
                (reasonObj) => reasonObj.reason === reasonId
              )
            ]?.text ?? ""
          }
        />
      </div>
    );
  };

  return (
    <div
      className={`card-fraud-reason${row ? ` row` : ""}`}
      style={{
        justifyContent: justifyContent,
      }}
    >
      {!!description && (
        <div className={"card-fraud-description"}>{`*${description}`}</div>
      )}
      <div
        className={`card-fraud-reason-text${
          addTextArea ? " with-textarea" : ""
        }`}
      >
        <Checkbox
          className={"card-fraud-checkbox"}
          checked={checkReasonSelected(reasonId)}
          value={reason}
          handleClick={() => editSelectedReasons(reasonId)}
        />
        <label htmlFor={reason} onClick={() => editSelectedReasons(reasonId)}>
          {reason}
        </label>
      </div>
      {addTextArea && renderTextArea(checkReasonSelected(reasonId))}
    </div>
  );
};

export default CardFraudReason;
