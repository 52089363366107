import React from "react";

const TOOLTIP_DELAY = 400;

const TimelineCanvas = (props) => {
  const handleHover = (event) => {
    // Sets hover groupings on mouse stop
    // Unless a sticky click event is currently active
    if (props.allowHoverEvents) {
      props.handleEventMarkerHover(event, props.grey);
      !props.grey && props.handleTooltipLocation(event);
    }
  };

  const handleMouseOut = (e) => {
    setTimeout(() => {
      if (props.allowHoverEvents) {
        props.handleEventMarkerHover(undefined, undefined);
        if (!props.grey) {
          props.setPlayEventHover(false);
        }
      }
    }, TOOLTIP_DELAY);
  };

  return (
    <canvas
      className={"timeline-canvas-stage"}
      ref={props.canvasRef}
      width={props.width}
      height={props.height}
      onMouseMove={handleHover}
      onMouseOut={handleMouseOut}
      onClick={props.handleEventMarkerClick}
    />
  );
};
export default TimelineCanvas;
