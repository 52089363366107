import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import UseComponentVisible from "../UseComponentVisible/UseComponentVisible";
import UseLeftOrRight from "../UseLeftOrRight/UseLeftOrRight";
import Format from "../../services/format";
import "./info-tip.scss";

const TOOLTIP_PADDING_PX = 16;
const TOOLTIP_LEFT_MARGIN_PX = 5;
const DEFAULT_FONT_SIZE = "inherit";
const DEFAULT_ICON_WIDTH_PX = 18;
const DEFAULT_TOOLTIP_WIDTH_PX = 175;

const InfoTip = (props) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    UseComponentVisible(false);

  const iconWidth = props.iconWidth ? props.iconWidth : DEFAULT_ICON_WIDTH_PX;
  const tooltipWidth = props.tooltipWidth
    ? props.tooltipWidth
    : DEFAULT_TOOLTIP_WIDTH_PX;

  const handleOnClick = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  return (
    <div className={"info-tip-container"}>
      <InfoIcon
        className={"info-icon"}
        ref={ref}
        onClick={() => handleOnClick()}
        style={{
          width: iconWidth,
          height: iconWidth,
        }}
      />
      <div
        className={`info-text${props.arrow ? " arrow" : ""}${
          props.bottomAlign ? " bottom-align" : ""
        }`}
        style={{
          display: isComponentVisible ? "inline-block" : "none",
          fontSize: props.fontSize ? props.fontSize : DEFAULT_FONT_SIZE,
          padding: TOOLTIP_PADDING_PX,
          marginLeft: TOOLTIP_LEFT_MARGIN_PX,
          width: tooltipWidth,
          ...UseLeftOrRight(
            ref,
            tooltipWidth + TOOLTIP_PADDING_PX * 2,
            TOOLTIP_LEFT_MARGIN_PX * 2,
            true
          ),
        }}
      >
        {Format.splitAtNewLine(props.description).map((text, i) => (
          <div key={i}>{text}</div>
        ))}
      </div>
    </div>
  );
};

export default InfoTip;
