export const USER_COMP_TABLE_METRICS = [
  {
    label: "total_plays",
    title: "Total Plays (All Artists)",
  },
  {
    label: "artist_total_plays",
    title: "Total Plays (This Artist)",
  },
  {
    label: "total_flags",
    title: "Total Flags",
  },
  { label: "unique_song_count", title: "Unique Songs" },
  { label: "unique_artist_count", title: "Unique Artists" },
  { label: "unique_device_id_count", title: "Device IDs" },
  {
    label: "events_per_play_rate",
    title: "Amplitude Events /\nNumber of Plays",
  },

  { label: "distributor_risk", title: "Distributor Risk Score" },
  { label: "failed_track_rate", title: "Failed Track Events Rate" },
];

export const USER_RADAR_COMP_METRICS = [
  {
    title: "artist_total_plays_score",
    label: "Total Plays (This Artist)",
  },
  {
    title: "total_flags_score",
    label: "Total Flags",
  },
  { title: "unique_song_count_score", label: "Unique Songs" },
  { title: "unique_artist_count_score", label: "Unique Artists" },
  { title: "unique_device_id_count_score", label: "Device IDs" },
  {
    title: "events_per_play_rate_score",
    label: "Amplitude Events/Number of Plays",
  },

  { title: "distributor_risk_score", label: "Distributor Risk Score" },
  { title: "failed_track_rate_score", label: "Failed Track Events Rate" },

  {
    title: "total_plays_score",
    label: "Total Plays (All Artists)",
  },
];
