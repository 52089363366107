import React from "react";
import LinkedUsersList from "../LinkedUsersList/LinkedUsersList";
import UseDecisionBox from "../UseDecisionBox/UseDecisionBox";
import HanksTip from "../HanksTip/HanksTip";
import Loading from "../Loading/Loading";
import PillList from "../PillList/PillList";
import SelectionCheckboxList from "../SelectionCheckboxList/SelectionCheckboxList";
import SelectionBoxes from "../SelectionBoxes/SelectionBoxes";
import SelectionTextBox from "../SelectionTextBox/SelectionTextBox";
import { FORM_REASONS } from "../../config/artist_fraud_reasons";
import {
  SUBMIT_CTA_TEXT,
  SUBMISSION_SUCCESS_TEXT,
  PILL_LIST_HEADER,
  PILL_LIST_SUBHEADER,
  PILL_LIST_ERROR_MESSAGE,
  REQUIRED_TEXTBOX_PLACEHOLDER,
  OPTIONAL_TEXTBOX_PLACEHOLDER,
  SELECTION_GENRE_OPTIONS,
  SELECTION_SLIDER_PROPS,
} from "../../config/decision-box-shared-props";

const PERCENTAGE_SLIDER_HANKS_TIP = {
  text: "Suspicious plays are an estimate determined by summing the total play count for this artist, from all suspicious users. This suspicious play estimate could change based on factors such as the top device types across users, the artist profile, or other metrics that you have investigated on this page.",
  width: 374,
};

const SECTION_LABELS = [
  { label: "1. Label the reason(s) for fraud:" },
  { label: "2. Rate your understanding of this artist:", required: true },
  {
    label: "3. Select the genres present in this artist's music:",
    required: true,
    bonusText: " Select all that apply",
  },
  {
    label: (susPlaysPercent) =>
      `4. It is estimated that ${
        typeof susPlaysPercent === "number" ? `${susPlaysPercent}%` : "n/a"
      } of this artists streams are fraudulent.`,
    bonusText:
      " Update the slider below if you have detected anything that would cause you to question this number:",
  },
  {
    label: "5. Classify the type of fraud:",
    required: true,
    bonusText: " Select one",
  },
];

const FRAUD_REASONS_PER_COLUMN = [8, 7, 10, 5];

const SELECTION_BOXES_PROPS = {
  options: ["Legitimate Artist with Fake Streams", "Fake Artist"],
  renderTextbox: true,
};

const ArtistDecisionBox = ({
  provider, // String, provider source of the artist
  dsp, // String, DSP source of the artist
  entityId, // String, the unique ID of the artist
  partnerName, // String, the name of the partner providing the data
  susPlaysPercent, // Number, the percent of suspicious plays for this artist
  tableDate, // String, The first day of the week being sampled (e.g. "2022-06-15")
  handleFormSubmission, // Callback function to run on form submission
  linkedUsers, // Array of userIds that have been marked as similar
  editLinkedUsers, // setState callback function to edit the linkedUsers array
  selectedFraudReasons, // Array of selected fraud reasons
  editSelectedFraudReasons, // setState callback function to edit the selectedFraudReasons array
}) => {
  const {
    error,
    success,
    loading,
    reviewRequest,
    setReviewRequest,
    selectedUnderstanding,
    setSelectedUnderstanding,
    understandingText,
    setUnderstandingText,
    selectedPercent,
    setSelectedPercent,
    selectedFraudType,
    setSelectedFraudType,
    setFraudTypeIndex,
    selectedGenreTypes,
    setSelectedGenreTypes,
    fraudTypeText,
    setFraudTypeText,
    selectedFraudTypeReasons,
    setSelectedFraudTypeReasons,
    generateFraudReasonTextArr,
    renderSectionLabel,
    renderLineBreak,
    renderSelectionSlider,
    renderFraudReasons,
    handleSubmit,
  } = UseDecisionBox({
    selectedFraudReasons,
    editSelectedFraudReasons,
    selectionBoxesProps: SELECTION_BOXES_PROPS,
    provider,
    dsp,
    entityId,
    entityType: "artist",
    formReasons: FORM_REASONS,
    tableDate,
    linkedUsers,
    handleFormSubmission,
    defaultFraudPercentage: susPlaysPercent,
    partnerName,
  });

  return (
    <div className={"decision-box-wrapper"}>
      {loading && (
        <div className={"decision-box-loading"}>
          <Loading />
        </div>
      )}
      <div className={"decision-box-container"}>
        <div className={"decision-box-section-container"}>
          {renderSectionLabel(SECTION_LABELS[0])}
          <div className={"decision-box-section fraud-reasons-section"}>
            {renderFraudReasons(FRAUD_REASONS_PER_COLUMN)}
          </div>
        </div>
        {renderLineBreak()}
        <div className={"decision-box-section-container"}>
          {renderSectionLabel(SECTION_LABELS[1])}
          <div className={"decision-box-section understanding-slider-section"}>
            {renderSelectionSlider(
              SELECTION_SLIDER_PROPS.understandingLevel,
              selectedUnderstanding,
              setSelectedUnderstanding,
              understandingText,
              setUnderstandingText,
              178
            )}
          </div>
        </div>
        {renderLineBreak()}
        <div className={"decision-box-section-container"}>
          {renderSectionLabel(SECTION_LABELS[2])}
          <div className={"decision-box-section genre-selection-section"}>
            <SelectionCheckboxList
              options={SELECTION_GENRE_OPTIONS.options}
              tooltipWidth={SELECTION_GENRE_OPTIONS.textboxWidth}
              selectedOptions={selectedGenreTypes}
              setSelectedOptions={setSelectedGenreTypes}
            />
          </div>
        </div>
        {renderLineBreak()}
        <div className={"decision-box-section-container"}>
          <div className={"decision-box-hanks-tip"}>
            <HanksTip
              tipText={PERCENTAGE_SLIDER_HANKS_TIP.text}
              textTipWidth={PERCENTAGE_SLIDER_HANKS_TIP.width}
            />
          </div>
          {renderSectionLabel(SECTION_LABELS[3], susPlaysPercent)}
          <div
            className={"decision-box-section fraud-percentage-slider-section"}
          >
            {renderSelectionSlider(
              SELECTION_SLIDER_PROPS.fraudPercentage,
              selectedPercent,
              setSelectedPercent
            )}
          </div>
        </div>
        {renderLineBreak()}
        <div
          className={`decision-box-section-container ${
            selectedPercent !==
            SELECTION_SLIDER_PROPS.fraudPercentage.options[0]
              ? "visible"
              : "hidden"
          }`}
        >
          {renderSectionLabel(SECTION_LABELS[4])}
          <div className={"decision-box-section fraud-type-section"}>
            <SelectionBoxes
              options={SELECTION_BOXES_PROPS.options}
              selectedOption={selectedFraudType}
              onChange={(option, index) => {
                setSelectedFraudType(option);
                setFraudTypeIndex(index);
              }}
            />
            <div
              className={`decision-box-pill-list ${
                selectedFraudType === undefined
                  ? "no-margin"
                  : SELECTION_BOXES_PROPS.options.includes(selectedFraudType)
                  ? "visible"
                  : ""
              }`}
            >
              {selectedFraudReasons?.length > 0 ? (
                <label className={"decision-box-pill-list-label"}>
                  {PILL_LIST_HEADER}
                  <span>{PILL_LIST_SUBHEADER}</span>
                </label>
              ) : (
                <span className={"decision-box-pill-list-error"}>
                  {PILL_LIST_ERROR_MESSAGE}
                </span>
              )}
              <PillList
                options={generateFraudReasonTextArr()}
                selectedOptions={selectedFraudTypeReasons[selectedFraudType]}
                setSelectedOptions={(newFraudTypeReasons) => {
                  setSelectedFraudTypeReasons((prevFraudTypeReasons) => {
                    return {
                      ...prevFraudTypeReasons,
                      [selectedFraudType]: newFraudTypeReasons,
                    };
                  });
                }}
              />
            </div>
            <SelectionTextBox
              visible={!!selectedFraudType}
              selectedOption={selectedFraudType}
              placeholderText={
                (SELECTION_BOXES_PROPS.options.includes(selectedFraudType)
                  ? OPTIONAL_TEXTBOX_PLACEHOLDER
                  : REQUIRED_TEXTBOX_PLACEHOLDER
                ).placeholder
              }
              textRequired={
                (SELECTION_BOXES_PROPS.options.includes(selectedFraudType)
                  ? OPTIONAL_TEXTBOX_PLACEHOLDER
                  : REQUIRED_TEXTBOX_PLACEHOLDER
                ).required
              }
              textContent={fraudTypeText[selectedFraudType]}
              setTextContent={(newText) => {
                setFraudTypeText((prevTextObj) => {
                  return {
                    ...prevTextObj,
                    [selectedFraudType]: newText,
                  };
                });
              }}
            />
          </div>
          {renderLineBreak()}
        </div>
        <div className={"decision-box-presubmit"}>
          {/* Request review singular checkbox */}
          <SelectionCheckboxList
            options={[
              {
                tooltip: FORM_REASONS.preSubmit[0].reason.tooltip,
                text: FORM_REASONS.preSubmit[0].reason.label,
              },
            ]}
            selectedOptions={reviewRequest}
            setSelectedOptions={setReviewRequest}
            tooltipWidth={294}
          />
        </div>
        {linkedUsers && (
          <LinkedUsersList
            linkedUsers={linkedUsers}
            editLinkedUsers={editLinkedUsers}
          />
        )}
        <p className={`decision-box-message ${success ? "success" : "error"}`}>
          {success ? SUBMISSION_SUCCESS_TEXT : !!error && error}
        </p>
        <button className={"decision-box-submit"} onClick={handleSubmit}>
          {SUBMIT_CTA_TEXT}
        </button>
      </div>
    </div>
  );
};

export default ArtistDecisionBox;
