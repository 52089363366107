import { useState, useEffect, useCallback } from "react";

const OFFSET_AMOUNT = "100%";
/**
 * Have a tooltip appear to the left or right of the info icon
 * Depending on if it'd be off-screen
 */
const UseLeftOrRight = (
  ref,
  fulltooltipWidth,
  additionalOffset = 0,
  offsetRef = false
) => {
  const [side, setSide] = useState(null);

  // Calculate the direction (left or right) for absolute positioning
  const calculateSide = useCallback(() => {
    const xPositioning = ref?.current?.getBoundingClientRect()?.x;
    const tooltipOnScreen =
      ref?.current?.clientWidth +
        xPositioning +
        additionalOffset +
        fulltooltipWidth <
      window.innerWidth;

    return tooltipOnScreen ? "left" : "right";
  }, [ref, additionalOffset, fulltooltipWidth]);

  useEffect(() => {
    const setComponentSide = () => {
      setSide(calculateSide());
    };

    window.addEventListener("resize", setComponentSide);

    return () => {
      window.removeEventListener("resize", setComponentSide);
    };
  }, [calculateSide]);

  // determine offset value
  const offSet = offsetRef
    ? OFFSET_AMOUNT
    : `calc(${OFFSET_AMOUNT} - ${ref?.current?.clientWidth ?? 0}px)`;

  // Return css left or right styling
  // Run calculateSide() on initial screensize
  return {
    [side ?? calculateSide()]: offSet,
  };
};

export default UseLeftOrRight;
