import React from "react";
import Grid from "@mui/material/Grid";
import UseProfile from "../UseProfile/UseProfile";
import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
import ArtistTotalPlays from "../../components/ArtistTotalPlays/ArtistTotalPlays";
import ArtistPlayCount from "../../components/ArtistPlayCount/ArtistPlayCount";
import ArtistTopSongs from "../../components/ArtistTopSongs/ArtistTopSongs";
import ArtistPlayDistribution from "../../components/ArtistPlayDistribution/ArtistPlayDistribution";
import PercentileMetrics from "../../components/PercentileMetrics/PercentileMetrics";
import ArtistDecisionBox from "../../components/ArtistDecisionBox/ArtistDecisionBox";
import ArtistHeader from "../../components/ArtistHeader/ArtistHeader";
import ArtistUsers from "../../components/ArtistUsers/ArtistUsers";
import ArtistSuspiciousUsers from "../../components/ArtistSuspiciousUsers/ArtistSuspiciousUsers";
import ArtistAmplitudeEvents from "../../components/ArtistAmplitudeEvents/ArtistAmplitudeEvents";
import MetricCard from "../../components/MetricCard/MetricCard";
import ArtistMonthlyListeners from "../../components/ArtistMonthlyListeners/ArtistMonthlyListeners";
import UserComparisonModal from "../../components/UserComparisonModal/UserComparisonModal";
import DashboardFooter from "../../components/DashboardFooter/DashboardFooter";
import DashboardView from "../../containers/DashboardView/DashboardView";
import { CARD_FRAUD_REASONS } from "../../config/artist_fraud_reasons";

import {
  USER_COMP_TABLE_METRICS,
  USER_RADAR_COMP_METRICS,
} from "../../config/user_comp_metrics_artist";

import HANKS_TIPS_TEXT from "../../config/hanks_tips.json";
import METRIC_LIMITS from "../../config/limits";

const ENTITY_TYPE = "artist";

const ArtistProfile = (props) => {
  const {
    changingDataSource,
    checkFraudReasonSelected,
    dataWeeks,
    editLinkedUsers,
    editSelectedFraudReasons,
    endDate,
    entityId,
    generatePieChart,
    handleDateRangeChange,
    handlePartnerChange,
    linkedUsers,
    loadPage,
    metricCardData,
    partnerName,
    resetPage,
    selectedFraudReasons,
    setChangingDataSource,
    setSusPlaysPercent,
    setUserComparisonModalVisible,
    startDate,
    susPlaysPercent,
    userComparisonModalData,
    userComparisonModalVisible,
  } = UseProfile({
    ogEntityId: props.match.params.id,
    entityType: ENTITY_TYPE,
    tableDateFromUrl: props.match.params.table_date,
    providerNumFromUrl: props.match.params.provider,
  });

  return loadPage ? (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      {userComparisonModalData && (
        <UserComparisonModal
          open={userComparisonModalVisible}
          titleAdjective={"Suspicious"}
          initialUser={false}
          userCtaButtonPrefix={"View"}
          tableDataLabels={USER_COMP_TABLE_METRICS}
          chartDataLabels={USER_RADAR_COMP_METRICS}
          partnerName={partnerName}
          data={userComparisonModalData}
          tableDate={startDate}
          linkedUsers={linkedUsers}
          handleCheckboxChange={editLinkedUsers}
          handleClose={() => setUserComparisonModalVisible(false)}
          cardFraudReasons={CARD_FRAUD_REASONS.userComparisonModal}
          checkFraudReasonSelected={checkFraudReasonSelected}
          editSelectedFraudReasons={editSelectedFraudReasons}
          selectedFraudReasons={selectedFraudReasons}
        />
      )}
      <Grid item xs={12}>
        <DashboardHeader
          entityId={entityId}
          entityType={ENTITY_TYPE}
          partnerName={partnerName}
          startDate={startDate}
          endDate={endDate}
          dataWeeks={dataWeeks}
          handleDateRangeChange={handleDateRangeChange}
          handlePartnerChange={handlePartnerChange}
          setChangingDataSource={setChangingDataSource}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <ArtistTotalPlays
          artistId={entityId}
          setSusPlaysPercent={setSusPlaysPercent}
          partnerName={partnerName}
          tableDate={startDate}
          tipText={HANKS_TIPS_TEXT.artistTotalPlays}
          textTipWidth={300}
          cardFraudReasons={CARD_FRAUD_REASONS.artistTotalPlays}
          pauseUpdates={changingDataSource}
          checkFraudReasonSelected={checkFraudReasonSelected}
          editSelectedFraudReasons={editSelectedFraudReasons}
          selectedFraudReasons={selectedFraudReasons}
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <ArtistPlayCount
          artistId={entityId}
          partnerName={partnerName}
          xAxisKey={"date"}
          startDate={startDate}
          endDate={endDate}
          tipText={HANKS_TIPS_TEXT.artistPlayCount}
          textTipWidth={280}
          pauseUpdates={changingDataSource}
          cardFraudReasons={CARD_FRAUD_REASONS.artistPlayCount}
          checkFraudReasonSelected={checkFraudReasonSelected}
          editSelectedFraudReasons={editSelectedFraudReasons}
          selectedFraudReasons={selectedFraudReasons}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ArtistTopSongs
          artistId={entityId}
          partnerName={partnerName}
          tableDate={startDate}
          yAxisKey={"name"}
          pauseUpdates={changingDataSource}
          cardFraudReasons={CARD_FRAUD_REASONS.artistTopSongs}
          checkFraudReasonSelected={checkFraudReasonSelected}
          editSelectedFraudReasons={editSelectedFraudReasons}
          selectedFraudReasons={selectedFraudReasons}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        {generatePieChart({
          title: "Proportions of ~30-60 Second Plays",
          layout: "horizontal",
          cardFraudReasons: CARD_FRAUD_REASONS.pieCharts.streamLength,
          minHeight: 370,
        })}
      </Grid>
      <Grid item xs={12}>
        <PercentileMetrics
          entityType={ENTITY_TYPE}
          partnerName={partnerName}
          entityId={entityId}
          tableDate={startDate}
          titleLength={260}
          pauseUpdates={changingDataSource}
          cardFraudReasons={CARD_FRAUD_REASONS.percentileMetrics.streams}
          checkFraudReasonSelected={checkFraudReasonSelected}
          editSelectedFraudReasons={editSelectedFraudReasons}
          selectedFraudReasons={selectedFraudReasons}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12}>
            <ArtistHeader
              artistId={entityId}
              tableDate={startDate}
              partnerName={partnerName}
              title={"'s Users"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ArtistUsers
              artistId={entityId}
              tableDate={startDate}
              partnerName={partnerName}
              pauseUpdates={changingDataSource}
              cardFraudReasons={CARD_FRAUD_REASONS.artistUsers}
              checkFraudReasonSelected={checkFraudReasonSelected}
              editSelectedFraudReasons={editSelectedFraudReasons}
              selectedFraudReasons={selectedFraudReasons}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ArtistSuspiciousUsers
              artistId={entityId}
              tableDate={startDate}
              partnerName={partnerName}
              overlayData={userComparisonModalData}
              cardFraudReasons={CARD_FRAUD_REASONS.artistSuspiciousUsers}
              openUserComparisonModal={() =>
                setUserComparisonModalVisible(true)
              }
              pauseUpdates={changingDataSource}
              checkFraudReasonSelected={checkFraudReasonSelected}
              editSelectedFraudReasons={editSelectedFraudReasons}
              selectedFraudReasons={selectedFraudReasons}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {generatePieChart({
              title: "User Account Type",
              layout: "vertical",
              cardFraudReasons: CARD_FRAUD_REASONS.pieCharts.accountType,
              minHeight: 418,
              tipText: HANKS_TIPS_TEXT.artistUserType,
              textTipWidth: 250,
            })}
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12} md={4}>
                {generatePieChart({
                  title: "User Device Types for All Artists",
                  layout: "vertical",
                  cardFraudReasons: CARD_FRAUD_REASONS.pieCharts.deviceType,
                  minHeight: 413,
                })}
              </Grid>
              <Grid item xs={12} md={4}>
                {generatePieChart({
                  title: "User Device Types for This Artist",
                  layout: "vertical",
                  cardFraudReasons:
                    CARD_FRAUD_REASONS.pieCharts.artistDeviceType,
                  minHeight: 413,
                })}
              </Grid>
              <Grid item xs={12} md={4}>
                <ArtistAmplitudeEvents
                  artistId={entityId}
                  tableDate={startDate}
                  partnerName={partnerName}
                  tipText={HANKS_TIPS_TEXT.artistAmplitudeEvents}
                  textTipWidth={250}
                  pauseUpdates={changingDataSource}
                  cardFraudReasons={CARD_FRAUD_REASONS.artistAmplitudeEvents}
                  checkFraudReasonSelected={checkFraudReasonSelected}
                  editSelectedFraudReasons={editSelectedFraudReasons}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12} md={4}>
                    {generatePieChart({
                      title: "Top 5 Events Across Users for All Artists",
                      layout: "vertical",
                      cardFraudReasons:
                        CARD_FRAUD_REASONS.pieCharts.eventDiversity,
                      minHeight: 445,
                    })}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {generatePieChart({
                      title: "Top 5 Labels Across Users for All Artists",
                      layout: "vertical",
                      cardFraudReasons:
                        CARD_FRAUD_REASONS.pieCharts.labelDiversity,
                      minHeight: 445,
                    })}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {generatePieChart({
                      title: "Top 5 Distributors Across Users for All Artists",
                      layout: "vertical",
                      cardFraudReasons:
                        CARD_FRAUD_REASONS.pieCharts.distributorDiversity,
                      minHeight: 445,
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ArtistHeader
          artistId={entityId}
          tableDate={startDate}
          partnerName={partnerName}
          title={"'s Average User"}
          hanksTip={HANKS_TIPS_TEXT.artistHeader}
          hanksTipTextWidth={215}
        />
      </Grid>
      <Grid item xs={12}>
        <ArtistPlayDistribution
          artistId={entityId}
          tableDate={startDate}
          partnerName={partnerName}
          pauseUpdates={changingDataSource}
          cardFraudReasons={CARD_FRAUD_REASONS.artistPlayDistribution}
          checkFraudReasonSelected={checkFraudReasonSelected}
          editSelectedFraudReasons={editSelectedFraudReasons}
          selectedFraudReasons={selectedFraudReasons}
        />
      </Grid>
      <Grid item xs={12}>
        <PercentileMetrics
          entityType={"artist-avg-user"}
          entityId={entityId}
          partnerName={partnerName}
          tableDate={startDate}
          title={"Average User - Listening Diversity"}
          titleLength={260}
          pauseUpdates={changingDataSource}
          cardFraudReasons={CARD_FRAUD_REASONS.percentileMetrics.avgUser}
          checkFraudReasonSelected={checkFraudReasonSelected}
          editSelectedFraudReasons={editSelectedFraudReasons}
          selectedFraudReasons={selectedFraudReasons}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} sm={6} lg={3}>
            <MetricCard
              score={metricCardData[0]?.score || null}
              scoreLimits={METRIC_LIMITS[metricCardData[0]?.key] ?? null}
              title={metricCardData[0]?.title || null}
              percentile={metricCardData[0]?.percentile || null}
              zScore={metricCardData[0]?.zScore || null}
              displaySecondaryMetrics={true}
              cardFraudReasons={
                CARD_FRAUD_REASONS.metricCards[
                  "avg_user_unique_device_id_count"
                ]
              }
              partnerName={partnerName}
              checkFraudReasonSelected={checkFraudReasonSelected}
              editSelectedFraudReasons={editSelectedFraudReasons}
              selectedFraudReasons={selectedFraudReasons}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <MetricCard
              score={metricCardData[1]?.score || null}
              format={"%"}
              scoreLimits={METRIC_LIMITS[metricCardData[1]?.key] ?? null}
              title={metricCardData[1]?.title || null}
              percentile={metricCardData[1]?.percentile || null}
              zScore={metricCardData[1]?.zScore || null}
              displaySecondaryMetrics={true}
              tipText={HANKS_TIPS_TEXT.avgUserFailedEvents} // assume failed events data will always return third
              textTipWidth={300}
              cardFraudReasons={
                CARD_FRAUD_REASONS.metricCards["avg_user_failed_track_rate"]
              }
              checkFraudReasonSelected={checkFraudReasonSelected}
              editSelectedFraudReasons={editSelectedFraudReasons}
              selectedFraudReasons={selectedFraudReasons}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <MetricCard
              score={metricCardData[2]?.score || null}
              scoreLimits={METRIC_LIMITS[metricCardData[2]?.key] ?? null}
              title={metricCardData[2]?.title || null}
              percentile={metricCardData[2]?.percentile || null}
              zScore={metricCardData[2]?.zScore || null}
              displaySecondaryMetrics={true}
              cardFraudReasons={
                CARD_FRAUD_REASONS.metricCards["avg_user_total_flags"]
              }
              checkFraudReasonSelected={checkFraudReasonSelected}
              editSelectedFraudReasons={editSelectedFraudReasons}
              selectedFraudReasons={selectedFraudReasons}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <MetricCard
              score={metricCardData[3]?.score || null}
              format={"%"}
              scoreLimits={METRIC_LIMITS[metricCardData[3]?.key] ?? null}
              title={metricCardData[3]?.title || null}
              percentile={metricCardData[3]?.percentile || null}
              zScore={metricCardData[3]?.zScore || null}
              displaySecondaryMetrics={true}
              tipText={HANKS_TIPS_TEXT.avgUserOverlappingPlays}
              textTipWidth={300}
              cardFraudReasons={
                CARD_FRAUD_REASONS.metricCards[
                  "avg_user_overlapping_plays_ratio"
                ]
              }
              checkFraudReasonSelected={checkFraudReasonSelected}
              editSelectedFraudReasons={editSelectedFraudReasons}
              selectedFraudReasons={selectedFraudReasons}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ArtistHeader
          artistId={entityId}
          tableDate={startDate}
          partnerName={partnerName}
          getArtistName={false}
          title={"Artist"}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            {generatePieChart({
              title: "Top 3 Labels for This Artist",
              layout: "horizontal",
              cardFraudReasons: CARD_FRAUD_REASONS.pieCharts.artistTopLabels,
              minHeight: 329,
              tipText: HANKS_TIPS_TEXT.artistTopLabels,
              textTipWidth: 220,
            })}
          </Grid>
          <Grid item xs={12} md={6}>
            {generatePieChart({
              title: "Top 3 Distributors for This Artist",
              layout: "horizontal",
              cardFraudReasons:
                CARD_FRAUD_REASONS.pieCharts.artistTopDistributors,
              minHeight: 329,
            })}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ArtistMonthlyListeners
          artistId={entityId}
          tableDate={startDate}
          partnerName={partnerName}
          tipText={HANKS_TIPS_TEXT.artistMonthlyListeners}
          textTipWidth={300}
          cardFraudReasons={CARD_FRAUD_REASONS.artistMonthlyListeners}
          checkFraudReasonSelected={checkFraudReasonSelected}
          editSelectedFraudReasons={editSelectedFraudReasons}
          selectedFraudReasons={selectedFraudReasons}
        />
      </Grid>
      <Grid item xs={12}>
        <ArtistDecisionBox
          provider={partnerName}
          dsp={partnerName}
          entityId={entityId}
          susPlaysPercent={susPlaysPercent}
          tableDate={startDate}
          linkedUsers={linkedUsers}
          editLinkedUsers={editLinkedUsers}
          handleFormSubmission={resetPage}
          selectedFraudReasons={selectedFraudReasons}
          editSelectedFraudReasons={editSelectedFraudReasons}
        />
      </Grid>
      <Grid item xs={12}>
        <DashboardFooter />
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

export default DashboardView(ArtistProfile);
