import React from "react";
import HanksTip from "../../components/HanksTip/HanksTip";
import CardFraudReasons from "../../components/CardFraudReasons/CardFraudReasons";
import "./card.scss";

const Card =
  (CardContent) =>
  ({ ...props }) => {
    const renderFraudReasons =
      props?.cardFraudReasons?.reasons &&
      props?.cardFraudReasons?.reasons?.length > 0;

    return (
      <div className={"card-wrapper"}>
        <div className={"inner-card-wrapper"}>
          <div className={"card-content"}>
            {props.tipText && (
              <div className={"hanks-tip-container-wrapper"}>
                <HanksTip
                  tipText={props.tipText}
                  textTipWidth={props.textTipWidth}
                />
              </div>
            )}
            <CardContent {...props} />
          </div>
          {renderFraudReasons && (
            <CardFraudReasons
              fraudReasonInfo={props.cardFraudReasons}
              checkReasonSelected={props.checkFraudReasonSelected}
              editSelectedReasons={props.editSelectedFraudReasons}
              selectedFraudReasons={props.selectedFraudReasons}
            />
          )}
        </div>
      </div>
    );
  };

export default Card;
