import React from "react";
import CardFraudReason from "../CardFraudReason/CardFraudReason";
import "./card-fraud-reasons.scss";

const justifyContentAlignment = (alignment, description) => {
  // Returns a justify-content value based on params given
  return description
    ? "space-between"
    : alignment === "left"
    ? "flex-start"
    : "flex-end";
};

const CardFraudReasons = (props) => {
  const {
    reasons,
    alignment = "left",
    description,
    row,
    title, // String, optional title for the fraud reasons list
    allowTextAreas = true, // Can overwrite and disallow 'addTextArea' for child reasons
  } = props?.fraudReasonInfo;
  const justifyContent = justifyContentAlignment(alignment, description);

  const renderReasons = () => {
    if (reasons && reasons.length > 0) {
      return reasons.map((fraudReason) => {
        const reason = fraudReason?.reason ?? fraudReason;

        return (
          <CardFraudReason
            key={reason?.label}
            reason={reason?.cardLabel ?? reason?.label}
            reasonId={reason?.id}
            checkReasonSelected={props.checkReasonSelected}
            editSelectedReasons={props.editSelectedReasons}
            selectedFraudReasons={props.selectedFraudReasons}
            addTextArea={fraudReason?.addTextArea && allowTextAreas}
            justifyContent={justifyContent}
            description={description}
            row={row}
          />
        );
      });
    }
  };

  return (
    <div className={"card-fraud-reasons-container"}>
      {title?.length > 0 && <h4>{title}</h4>}
      <div
        className={"card-fraud-reasons"}
        style={{
          flexDirection: row ? "row" : "column",
        }}
      >
        {renderReasons()}
      </div>
    </div>
  );
};

export default CardFraudReasons;
