import React, { useState } from "react";
import "./similar-users-button.scss";

const MIN_DATA_ENTRIES_TO_RENDER = 2;
const SIMILAR_USERS_BTN_LABEL = "Sample similar users";
const UNAVAILABLE_USERS_LABEL = "No available similar users";

const SimilarUsersButton = (props) => {
  const [renderHover, setRenderHover] = useState(false);
  const [xPosition, setXPosition] = useState(0);
  const [yPosition, setYPosition] = useState(0);

  const handleMouseMove = (event) => {
    setXPosition(event.clientX);
    setYPosition(event.clientY);
  };

  const handleButtonClick = () => {
    if (clickable) {
      props.handleOnClick();
    }
  };

  const clickable =
    props.data && props.data.length >= MIN_DATA_ENTRIES_TO_RENDER;

  return (
    <>
      <button
        className={
          clickable ? "similar-users-button" : "similar-users-button disabled"
        }
        onClick={() => handleButtonClick()}
        onMouseEnter={() => setRenderHover(true)}
        onMouseLeave={() => setRenderHover(false)}
        onMouseMove={(e) => handleMouseMove(e)}
      >
        {SIMILAR_USERS_BTN_LABEL}
      </button>
      <div
        className={"similar-users-hover-overlay"}
        onMouseMove={(e) => handleMouseMove(e)}
        style={{
          display:
            props.data !== null &&
            props.data.length < MIN_DATA_ENTRIES_TO_RENDER &&
            renderHover
              ? "block"
              : "none",
          top: yPosition,
          left: xPosition,
        }}
      >
        {UNAVAILABLE_USERS_LABEL}
      </div>
    </>
  );
};

export default SimilarUsersButton;
