// For the submit form
import { SUBMIT_FORM_REASONS } from "./shared_form_fraud_reasons";

// For card fraud reasons
export const ACCOUNT_TAKEOVER_REASON = {
  id: 1,
  label: "Signs of account takeover or sharing",
};
export const SIMILARITY_REASON = {
  id: 2,
  label:
    "Exhibits user behaviour that is very similar to other suspicious users",
};
const TOO_ACTIVE_REASON = { id: 3, label: "Too many hours active" };
const COMMERCIAL_USE_REASON = { id: 4, label: "Possible commercial use" };
const UNIFORM_PLAY_DURATION_REASON = {
  id: 5,
  label: "Song play durations are too uniform",
};
const HIGH_RISK_DISTRIBUTORS_REASON = {
  id: 6,
  label: "User is associated with high risk distributor(s)",
};

const HIGH_COUNT_REASONS = {
  play: { id: 7, label: "Abnormally high play count" },
  device: { id: 8, label: "Abnormally high device ID count" },
  device_type: { id: 9, label: "Abnormally high device type count" },
  country: { id: 10, label: "Abnormally high country count" },
  city: { id: 11, label: "Abnormally high city count" },
  overlapping: {
    id: 12,
    label: "High percent of overlapping plays",
  },
};

const LOW_DIVERSITY_REASONS = {
  distributors: { id: 13, label: "Low diversity in distributors played" },
  artists: { id: 14, label: "Low diversity in artists played" },
  labels: { id: 15, label: "Low diversity in labels played" },
  songs: { id: 16, label: "Low diversity in songs played" },
  events: { id: 17, label: "Low diversity in user events" },
};

const THIRTY_TO_SIXTY_SECONDS_DURATION = {
  user: {
    id: 18,
    label: "High proportions of ~30-60 second plays on longer songs",
  }, // assume songs >= 60s
};

const FAILED_EVENTS_REASON = {
  id: 19,
  label: "High proportion of failed events",
};

const PLAY_DISTRIBUTION_REASONS = {
  song_distribution: {
    id: 20,
    label: "Uniform play distribution across songs",
  },
  song_device_distribution: {
    id: 21,
    label: "Large discrepancy in song selection based on device",
  },
  artist_distribution: {
    id: 22,
    label: "Uniform play distribution across artists",
  },
  artist_device_distribution: {
    id: 23,
    label: "Large discrepancy in artist selection based on device",
  },
  label_distribution: {
    id: 24,
    label: "Uniform play distribution across labels",
  },
  label_device_distribution: {
    id: 25,
    label: "Large discrepancy in labels based on device",
  },
  event_distribution: {
    id: 26,
    label: "Uniform play distribution across user events",
  },
  event_device_distribution: {
    id: 27,
    label: "Large discrepancy in user events based on device",
  },
  distributor_distribution: {
    id: 28,
    label: "Uniform play distribution across distributors",
  },
  distributor_device_distribution: {
    id: 29,
    label: "Large discrepancy in distributors based on device",
  },
};

export const CARD_FRAUD_REASONS = {
  totalPlays: {
    reasons: [HIGH_COUNT_REASONS.play],
  },
  stackedAreaChart: {
    reasons: [TOO_ACTIVE_REASON],
    alignment: "right",
    description: "Exact hours may not match timeline below",
  },
  dualTimeline: {
    reasons: [ACCOUNT_TAKEOVER_REASON, COMMERCIAL_USE_REASON],
    row: true,
  },
  percentileMetrics: {
    reasons: [UNIFORM_PLAY_DURATION_REASON, HIGH_RISK_DISTRIBUTORS_REASON],
    row: true,
  },
  metricCards: {
    unique_device_id_count: {
      reasons: [HIGH_COUNT_REASONS.device],
    },
    unique_event_country_count: {
      reasons: [HIGH_COUNT_REASONS.country],
    },
    unique_country_count: {
      reasons: [HIGH_COUNT_REASONS.country],
    },
    unique_city_count: {
      reasons: [HIGH_COUNT_REASONS.city],
    },
    unique_device_type_count: {
      reasons: [HIGH_COUNT_REASONS.device_type],
    },
    unique_event_device_type_count: {
      reasons: [HIGH_COUNT_REASONS.device_type],
    },
    overlapping_plays: {
      reasons: [HIGH_COUNT_REASONS.overlapping],
    },
  },
  pieChart: {
    listens_rate: {
      reasons: [THIRTY_TO_SIXTY_SECONDS_DURATION.user],
    },
    top_5_track_distributors: {
      reasons: [
        LOW_DIVERSITY_REASONS.distributors,
        PLAY_DISTRIBUTION_REASONS.distributor_distribution,
        PLAY_DISTRIBUTION_REASONS.distributor_device_distribution,
      ],
    },
    top_5_track_labels: {
      reasons: [
        LOW_DIVERSITY_REASONS.labels,
        PLAY_DISTRIBUTION_REASONS.label_distribution,
        PLAY_DISTRIBUTION_REASONS.label_device_distribution,
      ],
    },
    top_25_track_artists: {
      reasons: [
        LOW_DIVERSITY_REASONS.artists,
        PLAY_DISTRIBUTION_REASONS.artist_distribution,
        PLAY_DISTRIBUTION_REASONS.artist_device_distribution,
      ],
    },
    top_5_event_type: {
      reasons: [
        LOW_DIVERSITY_REASONS.events,
        PLAY_DISTRIBUTION_REASONS.event_distribution,
        FAILED_EVENTS_REASON,
        PLAY_DISTRIBUTION_REASONS.event_device_distribution,
      ],
    },
    top_5_songs: {
      reasons: [
        LOW_DIVERSITY_REASONS.songs,
        PLAY_DISTRIBUTION_REASONS.song_distribution,
        PLAY_DISTRIBUTION_REASONS.song_device_distribution,
      ],
    },
  },
};

export const FORM_REASONS = {
  specific: [
    { reason: HIGH_COUNT_REASONS.play },
    { reason: TOO_ACTIVE_REASON },
    {
      reason: ACCOUNT_TAKEOVER_REASON,
      tooltip:
        "Signs of account takeover/sharing may include profiles that repeatedly jump between countries, playing multiple tracks simultaneously (with different IPs or countries), or experience sudden changes in streaming content.",
    },
    { reason: COMMERCIAL_USE_REASON },
    { reason: HIGH_COUNT_REASONS.device },
    { reason: HIGH_COUNT_REASONS.device_type },
    { reason: HIGH_COUNT_REASONS.country },
    { reason: HIGH_COUNT_REASONS.city },
    { reason: HIGH_COUNT_REASONS.overlapping },
    { reason: HIGH_RISK_DISTRIBUTORS_REASON },
    { reason: THIRTY_TO_SIXTY_SECONDS_DURATION.user },
    { reason: LOW_DIVERSITY_REASONS.distributors },
    { reason: LOW_DIVERSITY_REASONS.labels },
    { reason: LOW_DIVERSITY_REASONS.artists },
    { reason: LOW_DIVERSITY_REASONS.songs },
    { reason: LOW_DIVERSITY_REASONS.events },
    { reason: UNIFORM_PLAY_DURATION_REASON },
    { reason: PLAY_DISTRIBUTION_REASONS.song_distribution },
    { reason: PLAY_DISTRIBUTION_REASONS.song_device_distribution },
    { reason: PLAY_DISTRIBUTION_REASONS.artist_distribution },
    { reason: PLAY_DISTRIBUTION_REASONS.artist_device_distribution },
    { reason: PLAY_DISTRIBUTION_REASONS.label_distribution },
    { reason: PLAY_DISTRIBUTION_REASONS.label_device_distribution },
    { reason: PLAY_DISTRIBUTION_REASONS.event_distribution },
    { reason: FAILED_EVENTS_REASON },
    { reason: PLAY_DISTRIBUTION_REASONS.event_device_distribution },
    { reason: PLAY_DISTRIBUTION_REASONS.distributor_distribution },
    { reason: PLAY_DISTRIBUTION_REASONS.distributor_device_distribution },
    {
      reason: SIMILARITY_REASON,
      tooltip:
        "Music fraud often occurs on hundreds of accounts simultaneously, which may exhibit similar user behaviour patterns or play similar content.",

      addTextArea: true,
    },
  ],
  shared: SUBMIT_FORM_REASONS.shared,
  preSubmit: SUBMIT_FORM_REASONS.preSubmit,
};
