import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./linked-users-list.scss";

const LINKED_USERS_TITLE = "Linked similar users";
const NO_LINKED_USERS_MESSAGE = "Currently no linked users";

const LinkedUsersList = ({ linkedUsers, editLinkedUsers }) => {
  const formattedLinkedUsers = [];
  for (const linkedUser of linkedUsers) {
    formattedLinkedUsers.push(
      <div className={"linked-user"} key={`linked-user-${linkedUser}`}>
        {`User ${linkedUser}`}
        <CloseIcon onClick={() => editLinkedUsers(linkedUser)} />
      </div>
    );
  }

  return (
    <div className={"linked-users-container"}>
      <p>{LINKED_USERS_TITLE}</p>
      {linkedUsers?.length !== 0 ? (
        <div className={"linked-users"}>{formattedLinkedUsers}</div>
      ) : (
        NO_LINKED_USERS_MESSAGE
      )}
    </div>
  );
};

export default LinkedUsersList;
