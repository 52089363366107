// For the submit form
import { SUBMIT_FORM_REASONS } from "./shared_form_fraud_reasons";

// For card fraud reasons
const SIMILAR_USER_BEHAVIOUR_REASON = {
  reason: { id: 1, label: "Similar behaviour across suspicious users" },
  addTextArea: true,
};
const UNIFORM_STREAM_DURATIONS = {
  id: 2,
  label: "Stream durations are too uniform",
};
const UNEXPLAINED_SPIKES_REASON = {
  id: 3,
  label: "Unusual spikes or dips in play count",
};
const PLATFORM_DISCREPANCY_REASON = {
  reason: { id: 4, label: "Large discrepancy of popularity across platforms" },
  addTextArea: true,
};

const HIGH_PROPORTION_REASONS = {
  noAmplitude: {
    id: 5,
    label: "High proportion of users without Amplitude events",
  },
  shortMonetizedPlays: {
    id: 6,
    label: "High proportions of ~30-60 second plays",
  },
  trialAccounts: { id: 7, label: "High proportion of trial accounts" },
};

const HIGH_PERCENTAGE_REASONS = {
  suspiciousPlays: { id: 8, label: "High percentage of suspicious plays" },
  suspiciousTrackPlays: {
    id: 9,
    label: "High percentage of suspicious plays in one or multiple tracks",
  },
  suspiciousUsers: { id: 10, label: "High percentage of suspicious users" },
};

const HIGH_NUMBER_REASONS = {
  plays: {
    total: {
      id: 11,
      label: "Average user - High total plays",
      cardLabel: "High number of total plays",
    },
    monetized: {
      id: 12,
      label: "Average user - High monetized plays",
      cardLabel: "High number of monetized plays",
    },
    artist: {
      id: 13,
      label: "Average user - High plays for this artist",
      cardLabel: "High number of plays for this artist",
    },
    monetizedArtist: {
      id: 14,
      label: "Average user - High monetized plays for this artist",
      cardLabel: "High number of monetized plays for this artist",
    },
  },
  deviceIds: { id: 15, label: "High number of device IDs per stream" },
};

const LOW_DIVERSITY_REASONS = {
  user: { id: 16, label: "Low user diversity" },
  song: {
    id: 17,
    label: "Average user - Low song diversity",
    cardLabel: "Low song diversity",
  },
  artist: {
    id: 18,
    label: "Average user - Low artist diversity",
    cardLabel: "Low artist diversity",
  },
  label: {
    id: 19,
    label: "Low label diversity across users",
  },
  distributor: {
    id: 20,
    label: "Low distributor diversity across users",
  },
  event: { id: 21, label: "Low event diversity across users" },
  device: { id: 22, label: "Low diversity in device types across users" },
  deviceThisArtist: {
    id: 23,
    label: "Low diversity in device types for this artist",
  },
};

const AVG_USER_REASONS = {
  deviceIds: { id: 24, label: "Average user - High number of device IDs" },
  failedEvents: {
    id: 25,
    label: "Average user - High number of failed events",
  },
  flags: { id: 26, label: "Average user - High number of flags" },
  overlappingPlays: {
    id: 27,
    label: "Average user - High percentage of overlapping plays",
  },
  label: {
    id: 28,
    label: "Average user - Low label diversity",
    cardLabel: "Low label diversity",
  },
  distributor: {
    id: 29,
    label: "Average user - Low distributor diversity",
    cardLabel: "Low distributor diversity",
  },
};

export const CARD_FRAUD_REASONS = {
  artistTotalPlays: {
    reasons: [HIGH_PERCENTAGE_REASONS.suspiciousPlays],
  },
  artistPlayCount: {
    reasons: [UNEXPLAINED_SPIKES_REASON],
  },
  artistTopSongs: {
    reasons: [HIGH_PERCENTAGE_REASONS.suspiciousTrackPlays],
  },
  artistUsers: {
    reasons: [LOW_DIVERSITY_REASONS.user],
  },
  artistSuspiciousUsers: {
    reasons: [
      HIGH_PERCENTAGE_REASONS.suspiciousUsers,
      SIMILAR_USER_BEHAVIOUR_REASON,
    ],

    allowTextAreas: false,
  },
  artistAmplitudeEvents: {
    reasons: [HIGH_PROPORTION_REASONS.noAmplitude],
  },
  artistPlayDistribution: {
    reasons: [
      HIGH_NUMBER_REASONS.plays.total,
      HIGH_NUMBER_REASONS.plays.monetized,
      HIGH_NUMBER_REASONS.plays.artist,
      HIGH_NUMBER_REASONS.plays.monetizedArtist,
    ],

    title: "For the average user:",
    row: true,
  },
  percentileMetrics: {
    streams: {
      reasons: [UNIFORM_STREAM_DURATIONS, HIGH_NUMBER_REASONS.deviceIds],

      row: true,
    },
    avgUser: {
      reasons: [
        LOW_DIVERSITY_REASONS.song,
        LOW_DIVERSITY_REASONS.artist,
        AVG_USER_REASONS.label,
        AVG_USER_REASONS.distributor,
      ],

      title: "For the average user:",
      row: true,
    },
  },
  artistMonthlyListeners: {
    reasons: [PLATFORM_DISCREPANCY_REASON],
  },
  userComparisonModal: {
    reasons: [SIMILAR_USER_BEHAVIOUR_REASON],
  },
  metricCards: {
    avg_user_unique_device_id_count: {
      reasons: [AVG_USER_REASONS.deviceIds],
    },
    avg_user_failed_track_rate: {
      reasons: [AVG_USER_REASONS.failedEvents],
    },
    avg_user_total_flags: {
      reasons: [AVG_USER_REASONS.flags],
    },
    avg_user_overlapping_plays_ratio: {
      reasons: [AVG_USER_REASONS.overlappingPlays],
    },
  },
  pieCharts: {
    streamLength: {
      reasons: [HIGH_PROPORTION_REASONS.shortMonetizedPlays],
    },
    accountType: {
      reasons: [HIGH_PROPORTION_REASONS.trialAccounts],
    },
    deviceType: {
      reasons: [LOW_DIVERSITY_REASONS.device],
    },
    artistDeviceType: {
      reasons: [LOW_DIVERSITY_REASONS.deviceThisArtist],
    },
    eventDiversity: {
      reasons: [LOW_DIVERSITY_REASONS.event],
    },
    labelDiversity: {
      reasons: [LOW_DIVERSITY_REASONS.label],
    },
    distributorDiversity: {
      reasons: [LOW_DIVERSITY_REASONS.distributor],
    },
    artistTopLabels: {
      // None as of yet
    },
    artistTopDistributors: {
      // None as of yet
    },
  },
};

export const FORM_REASONS = {
  specific: [
    { reason: HIGH_PERCENTAGE_REASONS.suspiciousPlays },
    { reason: UNEXPLAINED_SPIKES_REASON },
    { reason: HIGH_PERCENTAGE_REASONS.suspiciousTrackPlays, addTextArea: true },
    { reason: HIGH_PROPORTION_REASONS.shortMonetizedPlays },
    { reason: UNIFORM_STREAM_DURATIONS },

    { reason: HIGH_NUMBER_REASONS.deviceIds },
    { reason: LOW_DIVERSITY_REASONS.user },
    { reason: HIGH_PROPORTION_REASONS.trialAccounts },
    { reason: HIGH_PERCENTAGE_REASONS.suspiciousUsers },
    SIMILAR_USER_BEHAVIOUR_REASON,
    { reason: LOW_DIVERSITY_REASONS.device },
    { reason: LOW_DIVERSITY_REASONS.deviceThisArtist },
    { reason: HIGH_PROPORTION_REASONS.noAmplitude },

    { reason: LOW_DIVERSITY_REASONS.event },
    { reason: LOW_DIVERSITY_REASONS.label },
    { reason: LOW_DIVERSITY_REASONS.distributor },

    { reason: HIGH_NUMBER_REASONS.plays.total },
    { reason: HIGH_NUMBER_REASONS.plays.monetized },
    { reason: HIGH_NUMBER_REASONS.plays.artist },
    { reason: HIGH_NUMBER_REASONS.plays.monetizedArtist },

    { reason: LOW_DIVERSITY_REASONS.song },
    { reason: LOW_DIVERSITY_REASONS.artist },
    { reason: AVG_USER_REASONS.label },
    { reason: AVG_USER_REASONS.distributor },
    { reason: AVG_USER_REASONS.deviceIds },
    { reason: AVG_USER_REASONS.failedEvents },
    { reason: AVG_USER_REASONS.flags },
    { reason: AVG_USER_REASONS.overlappingPlays },
    PLATFORM_DISCREPANCY_REASON,
  ],

  shared: SUBMIT_FORM_REASONS.shared,
  preSubmit: SUBMIT_FORM_REASONS.preSubmit,
};
