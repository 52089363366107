import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  Radar,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import "./user-radar-chart.scss";

const DEFAULT_USER_COLORS = [
  "#ff0606",
  "#63c4f8",
  "#0062e5",
  "#2c17ab",
  "#af2290",
  "#ed2784",
];
const MODAL_BACKGROUND_COLOR = "#232323";

const RADAR_CHART_OUTER_RADIUS = 150;
const MULTIPLE_RADIUS_LINES_PLACEMENTS = [RADAR_CHART_OUTER_RADIUS, 90, 35];
const RADAR_CHART_COMPONENT_HEIGHT = 365;
const RADAR_CHART_BASE_STROKE = "#4E4A54"; // $color-neutral-grey-3 in 'constants'

const CustomRadarTooltip = (props) => {
  if (
    props.active &&
    props.payload &&
    props.payload.length > 0 &&
    props.userOrder &&
    props.userOrder.length > 0
  ) {
    return (
      <div className={"user-radar-chart-tooltip"}>
        <div className={"user-radar-chart-title"}>{props.label}</div>
        {/* sort users based on original rendered users order */}
        {/* so they display in the correct order on re-selection */}
        {props.payload
          .slice()
          .sort(
            (a, b) =>
              props.userOrder.indexOf(a.name) - props.userOrder.indexOf(b.name)
          )
          .map((entry) => {
            const adjustedIndex = props.initialUser
              ? props.userOrder.indexOf(entry.name)
              : props.userOrder.indexOf(entry.name) + 1;

            return (
              <div className={"tooltip-entry"} key={entry.name}>
                <div
                  className={"user-radar-chart-tooltip-label"}
                  style={{ backgroundColor: entry.color }}
                >
                  {adjustedIndex === 0
                    ? "Initial User"
                    : `User ${adjustedIndex}`}
                </div>
                <div className={"user-radar-chart-tooltip-value"}>
                  {props.data[props.label][entry.name] ?? 0}
                </div>
              </div>
            );
          })}
      </div>
    );
  }

  return null;
};

const UserRadarChart = (props) => {
  let userColors = {};

  const [totalUsers, setTotalUsers] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartTableData, setChartTableData] = useState([]);
  const [repeatedSelectedUsers, setRepeatedSelectedUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(
    totalUsers.slice().reverse()
  );

  totalUsers.forEach((user, i) => {
    userColors[user] = DEFAULT_USER_COLORS[props.initialUser ? i : i + 1];
  });

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      const newChartTableData = {};
      const newChartData = props.chartDataLabels.map((metric) => {
        newChartTableData[metric.label] = {};
        let labelDataSet = {
          title: metric.label,
        };

        // create a data set for each metric label with each users data
        props.data.forEach((userData) => {
          if (metric.title in userData) {
            // use normalized value for chart
            const normalizedValue =
              userData[metric.title] * RADAR_CHART_OUTER_RADIUS;
            labelDataSet[userData.user_id] = normalizedValue;

            // assume that unless the metric name starts with "similiar_", the actual value
            // is stored in a metric with the same name without "_score" appended at the end
            const actualScoreMetric = metric.title.startsWith("similar_")
              ? metric.title
              : metric.title.replace(/_score/g, "");

            // track actual value for chart tool tip
            newChartTableData[metric.label][userData.user_id] =
              userData[actualScoreMetric];
          }
        });

        return labelDataSet;
      });

      setTotalUsers(props.data.map((user) => user.user_id));
      setChartTableData(newChartTableData);
      setChartData(newChartData);
    }

    return () => {
      abort = true;
    };
  }, [props.data, props.chartDataLabels]);

  useEffect(() => {
    let abort = false;

    // Avoid updating state for an unmounted component
    if (!abort) {
      setSelectedUsers(totalUsers.slice().reverse());
    }

    return () => {
      abort = true;
    };
  }, [totalUsers]);

  const CustomPolarAngleAxis = ({ payload, x, y, cx, cy, ...rest }) => {
    if (!payload.value) {
      return null;
    }

    // check for new lines to parse
    const texts = payload.value.split("\n");

    if (texts.length > 0) {
      return texts.map((text, i) => {
        // manually draw svg newlines
        const yDisplacement = i * 20;

        return (
          <text
            {...rest}
            // custom x y position render to move them farther away from the chart
            y={y + yDisplacement + (y - cy) / 10}
            x={x + (x - cx) / 10}
            key={text ?? i}
          >
            {text}
          </text>
        );
      });
    }
  };

  const handleSelectedUserToggle = (selectedUser) => {
    if (selectedUsers) {
      if (!selectedUsers.includes(selectedUser)) {
        setSelectedUsers([...selectedUsers, selectedUser]);

        if (!repeatedSelectedUsers.includes(selectedUser)) {
          setRepeatedSelectedUsers([...repeatedSelectedUsers, selectedUser]);
        }
      } else {
        setSelectedUsers(selectedUsers.filter((user) => user !== selectedUser));
      }
    }
  };

  return (
    <Grid className={"user-radar-chart-container"} container>
      <Grid className={"user-radar-chart-legend"} item xs={2}>
        {totalUsers.map((user, i) => {
          const adjustedIndex = props.initialUser ? i : i + 1;
          return (
            <div
              className={"chart-legend-option"}
              style={{
                backgroundColor: selectedUsers.includes(user)
                  ? DEFAULT_USER_COLORS[adjustedIndex]
                  : MODAL_BACKGROUND_COLOR,
                border: selectedUsers.includes(user)
                  ? `${MODAL_BACKGROUND_COLOR} solid 2px`
                  : `${DEFAULT_USER_COLORS[adjustedIndex]} solid 2px`,
              }}
              key={user ?? i}
            >
              <span className={"chart-legend-title"}>
                {props.initialUser && i === 0
                  ? "Initial User"
                  : `User ${adjustedIndex}`}
              </span>
              <CloseIcon
                className={
                  selectedUsers.includes(user)
                    ? repeatedSelectedUsers.includes(user)
                      ? "toggle-chart-legend-option active"
                      : "toggle-chart-legend-option"
                    : "toggle-chart-legend-option inactive"
                }
                onClick={(e) => handleSelectedUserToggle(user)}
              />
            </div>
          );
        })}
      </Grid>
      <Grid className={"user-radar-chart"} item xs={10}>
        <ResponsiveContainer
          className={"user-radar-chart-responsive-container"}
          width={"100%"}
          height={RADAR_CHART_COMPONENT_HEIGHT}
        >
          <RadarChart
            outerRadius={RADAR_CHART_OUTER_RADIUS}
            width={"100%"}
            height={RADAR_CHART_COMPONENT_HEIGHT}
            data={chartData}
          >
            <Tooltip
              data={chartTableData}
              userOrder={totalUsers}
              content={<CustomRadarTooltip initialUser={props.initialUser} />}
            />
            <PolarGrid
              polarRadius={MULTIPLE_RADIUS_LINES_PLACEMENTS}
              stroke={RADAR_CHART_BASE_STROKE}
            />
            <PolarAngleAxis dataKey={"title"} tick={<CustomPolarAngleAxis />} />
            {selectedUsers.map((user, index) => {
              // create polygons in reverse order so initial user renders on top
              return (
                <Radar
                  name={user}
                  dataKey={user}
                  stroke={userColors[user]}
                  fill={
                    // assume initial user is first in list of total users
                    props.initialUser && user === totalUsers[0]
                      ? "transparent"
                      : userColors[user]
                  }
                  fillOpacity={0.4}
                  key={user ?? index}
                />
              );
            })}
          </RadarChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};

export default UserRadarChart;
