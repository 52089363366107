import React, { useState, useCallback, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import axios from "../../config/axios";
import Format from "../../services/format";
import usePrevious from "../UsePrevious/UsePrevious";
import SelectionSlider from "../SelectionSlider/SelectionSlider";
import SelectionCheckboxList from "../SelectionCheckboxList/SelectionCheckboxList";
import {
  NO_REASONS_ERROR_TEXT,
  NO_FRAUD_TYPE_REASONS_ERROR_TEXT,
  NO_GENRES_ERROR_TEXT,
  REQUIRED_TEXTBOX_ERROR,
  FRAUD_TYPE_ERROR,
  REQUIRED_TEXTBOX_PLACEHOLDER,
  SELECTION_SLIDER_PROPS,
  SELECTION_GENRE_OPTIONS,
  SUBMISSION_FAILURE_TEXT,
} from "../../config/decision-box-shared-props";
import "./use-decision-box.scss";

/**
 * Hook used to track many shared state variables for the decision boxes
 * As well as shared functions
 */
const UseDecisionBox = ({
  selectedFraudReasons,
  editSelectedFraudReasons,
  selectionBoxesProps,
  provider,
  dsp,
  entityId,
  entityType,
  formReasons,
  tableDate,
  linkedUsers,
  handleFormSubmission,
  defaultFraudPercentage,
}) => {
  const history = useHistory();

  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reviewRequest, setReviewRequest] = useState([]);
  const [selectedUnderstanding, setSelectedUnderstanding] = useState(
    SELECTION_SLIDER_PROPS.understandingLevel.defaultOption
  );
  const [understandingText, setUnderstandingText] = useState({});

  const [selectedPercent, setSelectedPercent] = useState(
    SELECTION_SLIDER_PROPS.fraudPercentage.defaultOption
  );

  const [selectedFraudType, setSelectedFraudType] = useState(undefined);
  const [fraudTypeIndex, setFraudTypeIndex] = useState(undefined);
  const [fraudTypeText, setFraudTypeText] = useState({});

  const [selectedFraudTypeReasons, setSelectedFraudTypeReasons] = useState({});
  const prevSelectedFraudType = usePrevious(selectedFraudType);

  // Used only by the Artist and Song Decision Boxes
  const [selectedGenreTypes, setSelectedGenreTypes] = useState([]);

  /**
   * Returns a section label for a Decision Box.
   * @method
   * @param {Object} labelInfo object with the 'label' string, 'required' boolean, and 'bonusText' string
   * @param {String} labelVariable optional string to be used as the label
   * @returns {HTMLElement} label HTML element
   */
  const renderSectionLabel = (labelInfo, labelVariable) => {
    const { label, required, bonusText } = labelInfo;
    return (
      <label className={"decision-box-section-label"}>
        {typeof label === "string" ? label : label(labelVariable)}
        {required && <span className={"required"}>*</span>}
        {bonusText && <span className={"bonus-text"}>{bonusText}</span>}
      </label>
    );
  };

  /**
   * Returns a line break for a Decision Box.
   * @method
   * @returns {HTMLElement} div HTML element
   */
  const renderLineBreak = () => {
    return <div className={"decision-box-line-break"} />;
  };

  /**
   * Returns the SelectionSlider component built with the provided props for a Decision Box.
   * @method
   * @param {Object} optionInfo object with the 'options' string array, optional 'colors' string array, 'renderTextbox' boolean, and 'textboxFields' string array
   * @param {String} selection the currently selected option tracked in state
   * @param {Function} setSelection setState callback function to update the selection
   * @param {String} textboxContent the currently selected option's textbox content, tracked in state
   * @param {Function} setTextboxContent setState callback function to update the textboxContent
   * @param {Integer} maxSliderOptionWidth optional integer to set the max width of the slider options, defaults to fit container width
   * @returns {React.Component} SelectionSlider component
   */
  //
  const renderSelectionSlider = (
    { options, colors, renderTextbox, textboxFields },
    selection,
    setSelection,
    textboxContent,
    setTextboxContent,
    maxSliderOptionWidth
  ) => {
    if (!renderTextbox) {
      return (
        <SelectionSlider
          options={options}
          selectedOption={selection}
          colors={colors}
          onChange={(option) => setSelection(option)}
          maxOptionWidth={maxSliderOptionWidth}
        />
      );
    } else {
      const lowerCaseSelection = selection.toLowerCase();
      // Return a slider with textbox functionality
      return (
        <SelectionSlider
          options={options}
          selectedOption={selection}
          colors={colors}
          onChange={(option) => setSelection(option)}
          maxOptionWidth={maxSliderOptionWidth}
          renderTextbox={renderTextbox}
          visibleTextbox={Object.keys(textboxFields).includes(
            lowerCaseSelection
          )}
          placeholderText={textboxFields[lowerCaseSelection]}
          textboxContent={textboxContent[selection]}
          setTextboxContent={(newText) => {
            setTextboxContent((prevTextObj) => {
              return {
                ...prevTextObj,
                [selection]: newText,
              };
            });
          }}
        />
      );
    }
  };

  /**
   * Renders fraud reason checkbox list for current entity.
   * @param {Array<Integer>} fraudReasonsPerColumn array of integers representing the number of fraud reasons per column
   * @returns {React.Component}
   */
  const renderFraudReasons = (fraudReasonsPerColumn) => {
    const compatibleReasonsArr = [
      ...formReasons.specific,
      ...formReasons.shared,
    ].map((reasonObj) => {
      const reasonId = reasonObj?.reason?.id;
      // If reason has associated textbox, add textbox logic to reason object
      const textBox = reasonObj?.addTextArea
        ? {
            textContent:
              selectedFraudReasons?.[
                selectedFraudReasons.findIndex(
                  (selectedOption) => selectedOption.reason === reasonId
                )
              ]?.text ?? "",
            setTextContent: (newValue) =>
              editSelectedFraudReasons(reasonId, newValue),
          }
        : null;
      return {
        tooltip: reasonObj?.tooltip,
        text: reasonObj?.reason?.label,
        id: reasonId,
        textBox,
      };
    });

    // Render the columns of checkboxes
    return fraudReasonsPerColumn.map((numOfReasons, index) => {
      const reasonsInColumn = compatibleReasonsArr.splice(0, numOfReasons);
      return (
        <div
          className={"fraud-reasons-container"}
          key={`reason-column-${index}`}
        >
          {
            <SelectionCheckboxList
              customToggleLogicOnly={true}
              options={reasonsInColumn}
              row={false}
              selectedOptions={selectedFraudReasons.map(
                (reason) => reason.reason
              )}
              setSelectedOptions={editSelectedFraudReasons}
              tooltipWidth={294}
            />
          }
        </div>
      );
    });
  };

  /**
   * Checks if fraud reasons still need to be selected on submission attempt.
   * @param {Integer} fraudPercentIndex state tracked index number to represent suspected fraud percentage
   * @returns {Boolean}
   */
  const enoughReasonsSelected = (fraudPercentIndex) => {
    // Check if fraud was suspected but no reasons given/selected
    if (fraudPercentIndex > 0 && selectedFraudReasons.length === 0) {
      setError(NO_REASONS_ERROR_TEXT);
      return false;
    }

    return true;
  };

  /**
   * Checks if the labeller has properly filled in the artist and song genre selection.
   * @returns {Boolean}
   */
  const genreSelectionCompleted = () => {
    if (
      SELECTION_GENRE_OPTIONS.entityTypes.includes(entityType) &&
      selectedGenreTypes.length === 0
    ) {
      setError(NO_GENRES_ERROR_TEXT);
      return false;
    }
    return true;
  };

  /**
   * Checks if the labeller has properly filled in the understanding slider.
   * @returns {Boolean}
   */
  const understandingSliderCompleted = () => {
    if (
      !understandingText[selectedUnderstanding] &&
      SELECTION_SLIDER_PROPS.understandingLevel.textboxFields[
        selectedUnderstanding.toLowerCase()
      ] === REQUIRED_TEXTBOX_PLACEHOLDER
    ) {
      setError(REQUIRED_TEXTBOX_ERROR);
      return false;
    }
    return true;
  };

  /**
   * Checks if the labeller has properly filled in the fraud type section.
   * @returns {Boolean}
   */
  const fraudTypeCompleted = () => {
    const fraudTypeTextContent = fraudTypeText[selectedFraudType];
    const textboxRequired =
      !selectionBoxesProps?.options?.includes(selectedFraudType);
    const pillListItems = selectedFraudTypeReasons[selectedFraudType];

    // Check if fraud type was selected, text box filled if required, and pills picked if required
    if (selectedFraudType === undefined) {
      setError(FRAUD_TYPE_ERROR);
      return false;
    } else if (textboxRequired && !fraudTypeTextContent) {
      setError(REQUIRED_TEXTBOX_ERROR);
      return false;
    } else if (!textboxRequired && !(pillListItems?.length > 0)) {
      setError(NO_FRAUD_TYPE_REASONS_ERROR_TEXT);
      return false;
    }
    return true;
  };

  /**
   * Convert the id reasons to their text/label counterparts
   * In preparation for making a submission to the API.
   * Simultaneously, checks if the labeller has properly filled required fraud reason text boxes.
   * @returns {Object} object with either the 'reason' string if things go well, or 'textSelectedReasons' array and 'emptyTextError' boolean if there are empty required text boxes
   */
  const changeIdsToText = () => {
    let emptyTextError = false;
    // Consolidate into an array all fraud reasons in the submit form
    const combinedFraudReasons = formReasons.specific.concat(
      formReasons.shared
    );

    const textSelectedReasons = selectedFraudReasons.map((reasonObj) => {
      if (emptyTextError) return null;

      // Find the info (id, label, addTextArea, etc) for the fraud reason
      const matchingReasonWithText =
        combinedFraudReasons[
          combinedFraudReasons.findIndex(
            (reasonInfo) => reasonInfo.reason.id === reasonObj.reason
          )
        ];

      // Check if there's meant to be associated text
      // And, if so, that said text exists
      if (matchingReasonWithText?.addTextArea) {
        if (reasonObj.text) {
          return {
            reason: matchingReasonWithText.reason.label,
            text: reasonObj.text,
          };
        } else {
          emptyTextError = true;
          return null;
        }
      } else {
        return { reason: matchingReasonWithText.reason.label };
      }
    });

    return { textSelectedReasons, emptyTextError };
  };

  const generateFraudReasonTextArr = useCallback(() => {
    let textSelectedReasons = [];

    const combinedFraudReasons = formReasons.specific.concat(
      formReasons.shared
    );

    // Find the text labels for the selected fraud reasons
    // Search through the full reason list to get them in the same order
    for (const fraudReason of combinedFraudReasons) {
      if (
        selectedFraudReasons.findIndex(
          (reasonInfo) => reasonInfo.reason === fraudReason.reason.id
        ) !== -1
      ) {
        textSelectedReasons.push(fraudReason.reason.label);
      }
    }

    return textSelectedReasons;
  }, [selectedFraudReasons, formReasons.shared, formReasons.specific]);

  useEffect(() => {
    /**
     * Returns the default selection value for the fraud percentage slider.
     * @method
     * @returns {String} selection value string
     */
    const generateDefaultPercentageSelection = () => {
      if (
        defaultFraudPercentage === null ||
        defaultFraudPercentage === undefined
      ) {
        return SELECTION_SLIDER_PROPS.fraudPercentage.defaultOption;
      } else {
        // Find where the default percentage falls in the slider option ranges
        const sliderOptionsCleaned = { values: [], ranges: [] };
        for (const option of SELECTION_SLIDER_PROPS.fraudPercentage.options) {
          const cleanNumbers = option
            .replaceAll("%", "")
            .split("-")
            .map((num) => parseInt(num));
          if (cleanNumbers.length === 1) {
            sliderOptionsCleaned.values.push(cleanNumbers[0]);
          } else {
            sliderOptionsCleaned.ranges.push({
              low: cleanNumbers[0],
              high: cleanNumbers[1],
            });
          }
        }

        // Prioritize exact the values (e.g. "0%") over the ranges (e.g. "0%-20%")
        for (const value of sliderOptionsCleaned.values) {
          if (defaultFraudPercentage === value) {
            return `${defaultFraudPercentage}%`;
          }
        }

        // If the default percentage is not an exact value, find the range it falls in
        for (const optionRange of sliderOptionsCleaned.ranges) {
          if (
            defaultFraudPercentage >= optionRange.low &&
            defaultFraudPercentage <= optionRange.high
          ) {
            return `${optionRange.low}-${optionRange.high}%`;
          }
        }

        // If all else somehow fails and the defaultFraudPercentage is incompatible, just return the default
        return SELECTION_SLIDER_PROPS.fraudPercentage.defaultOption;
      }
    };

    setSelectedPercent(generateDefaultPercentageSelection());
  }, [defaultFraudPercentage]);

  useEffect(() => {
    clearForm();
  }, [entityId]);

  useEffect(() => {
    if (
      selectedFraudType &&
      prevSelectedFraudType !== selectedFraudType &&
      selectedFraudTypeReasons[selectedFraudType]
    ) {
      // If the labeller previously selected a fraud type and some fraud type reasons,
      // then selected a different fraud type,
      // We retain the previously selected pill options linked to their fraud type.
      // If the selectedFraudType then reverts back to that previous selection
      // And selectedFraudReasons has changed, check the previously saved selectedFraudTypeReasons
      // And ensure it doesn't contain any fraud reasons that are no longer checkmarked
      const textVersionOfReasonArray = generateFraudReasonTextArr();
      const newFraudTypeReasons = selectedFraudTypeReasons[
        selectedFraudType
      ].filter((selectedOption) =>
        textVersionOfReasonArray.includes(selectedOption)
      );

      setSelectedFraudTypeReasons((prevFraudTypeReasons) => {
        return {
          ...prevFraudTypeReasons,
          [selectedFraudType]: newFraudTypeReasons,
        };
      });
    }
  }, [
    selectedFraudType,
    prevSelectedFraudType,
    selectedFraudReasons,
    selectedFraudTypeReasons,
    generateFraudReasonTextArr,
  ]);

  /**
   * Sends axios post request to /create-new-classification endpoint
   * @param {Integer} fraudPercentIndex selected fraud percentage of plays index
   * @param {Array<String>} textSelectedReasons array of string versions of the selected fraud reasons
   * @param {Object} entityUniqueFields key-value pair(s) of entity type's unique field(s), optional
   * @returns {Boolean} Whether or not the submission was successful
   */
  const createNewClassification = async (
    fraudPercentIndex,
    textSelectedReasons,
    entityUniqueFields
  ) => {
    setLoading(true);
    const uuid = uuidv4();
    const classificationObject = {
      uuid,
      provider,
      dsp,
      entityType,
      entityID: entityId,
      fraudulentPlaysRate: fraudPercentIndex,
      understandingScore: {
        value: SELECTION_SLIDER_PROPS.understandingLevel.options.indexOf(
          selectedUnderstanding
        ),
        explanation: understandingText[selectedUnderstanding],
      },
      fraudType: {
        value: fraudTypeIndex,
        reasons: selectedFraudTypeReasons[selectedFraudType],
        explanation: fraudTypeText[selectedFraudType],
      },
      reason: textSelectedReasons,
      timestamp: new Date().toISOString(),
      tableDate,
      linkedUsers: linkedUsers ?? [],
      secondReview: reviewRequest?.length > 0,
      buildVersion: process.env.REACT_APP_BUILD_VERSION,
    };

    // If entity type is Artist or Song, add the entityGenre field to the classification object
    if (SELECTION_GENRE_OPTIONS.entityTypes.includes(entityType)) {
      classificationObject.entityGenre = selectedGenreTypes;

      // If entity type has unique field(s), add it to the classification object
      if (entityUniqueFields) {
        for (const key in entityUniqueFields) {
          classificationObject[key] = entityUniqueFields[key];
        }
      }
    }

    try {
      await axios.post("/create-new-classification", classificationObject, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("tk")}`,
        },
      });
      // remove local storage ID so new value can be generated and reset form
      const partnerNum = Format.getNumberByProvider(provider);
      localStorage.removeItem(`${partnerNum}${entityType}Id`);
      localStorage.removeItem(`${partnerNum}${entityType}TableDate`);

      setSuccess(true);
      setLoading(false);
      await handleFormSubmission();
      return true;
    } catch (error) {
      // If unauthenticated, redirect to login page
      if (error.response?.status === 401) {
        history.push("/login");
      }
      setError(SUBMISSION_FAILURE_TEXT);
      setLoading(false);
      return false;
    }
  };

  /**
   * Resets form state.
   */
  const clearForm = () => {
    setReviewRequest([]);
    setSelectedUnderstanding(
      SELECTION_SLIDER_PROPS.understandingLevel.defaultOption
    );
    setUnderstandingText({});
    setSelectedPercent(SELECTION_SLIDER_PROPS.fraudPercentage.defaultOption);
    setSelectedFraudType(undefined);
    setFraudTypeIndex(undefined);
    setFraudTypeText({});
    setSelectedFraudTypeReasons({});
    setLoading(false);
    setSuccess(false);
    setSelectedGenreTypes([]);
  };

  /**
   * Runs validation on form fields, sends validated data to
   * createNewClassification function, clears successfully completed form.
   * @param {Object} e event object
   * @param {Object} entityUniqueFields key-value pair(s) of entity type's unique field(s), optional
   * @returns {Boolean} Whether or not the submission was successful
   */
  const handleSubmit = async (e, entityUniqueFields) => {
    e.preventDefault();
    setError("");

    // Check various required fields are filled in
    const fraudPercentIndex =
      SELECTION_SLIDER_PROPS.fraudPercentage.options.indexOf(selectedPercent);

    if (
      !enoughReasonsSelected(fraudPercentIndex, selectedFraudReasons) ||
      !genreSelectionCompleted() ||
      !understandingSliderCompleted() ||
      (fraudPercentIndex !== 0 && !fraudTypeCompleted())
    ) {
      return false;
    }

    const { textSelectedReasons, emptyTextError } = changeIdsToText(
      selectedFraudReasons,
      formReasons
    );

    if (emptyTextError) {
      setError(REQUIRED_TEXTBOX_ERROR);
      return false;
    }

    // If the form is filled in, create the new classification
    return createNewClassification(
      fraudPercentIndex,
      textSelectedReasons,
      entityUniqueFields
    );
  };

  return {
    error,
    setError,
    success,
    loading,
    reviewRequest,
    setReviewRequest,
    selectedUnderstanding,
    setSelectedUnderstanding,
    understandingText,
    setUnderstandingText,
    selectedPercent,
    setSelectedPercent,
    selectedFraudType,
    setSelectedFraudType,
    setFraudTypeIndex,
    selectedGenreTypes,
    setSelectedGenreTypes,
    fraudTypeText,
    setFraudTypeText,
    selectedFraudTypeReasons,
    setSelectedFraudTypeReasons,
    generateFraudReasonTextArr,
    renderSectionLabel,
    renderLineBreak,
    renderSelectionSlider,
    renderFraudReasons,
    handleSubmit,
  };
};

export default UseDecisionBox;
